"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.to-fixed.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {}, [_c("div", {
    staticClass: "source"
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24,
      md: 9,
      lg: 10
    }
  }, [_c("br"), _c("el-card", {
    attrs: {
      shadow: ""
    }
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left",
      "label-width": "35%"
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.productData.name))]), _c("br"), _c("el-form-item", {
    attrs: {
      label: "Enter Bid Price",
      prop: "bidPrice",
      "label-width": "35%"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "bidPrice",
      placeholder: "Amount",
      min: _vm.bidAllowed,
      max: _vm.maximumBidPrice
    },
    model: {
      value: _vm.postForm.bidPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.postForm, "bidPrice", $$v);
      },
      expression: "postForm.bidPrice"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Enter Bid Quantity",
      prop: "bidQuantity"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "bidQuantity",
      required: "",
      placeholder: "Quantity",
      min: _vm.productData.multiplier,
      step: _vm.productData.multiplier,
      "step-strictly": ""
    },
    model: {
      value: _vm.postForm.bidQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.postForm, "bidQuantity", $$v);
      },
      expression: "postForm.bidQuantity"
    }
  })], 1), _c("p", [_c("small", [_vm._v("Total Bid Amount: "), _c("inr", {
    attrs: {
      number: +(_vm.postForm.bidPrice * _vm.postForm.bidQuantity).toFixed(2)
    }
  })], 1)]), _c("br"), _c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Go Back ")]), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      size: "small",
      type: "primary",
      disabled: _vm.postForm.bidQuantity <= 0 || _vm.postForm.bidPrice <= 0
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" Place Bid ")])], 1)], 1)], 1)], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;