"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'almond': {
    width: 16,
    height: 16,
    viewBox: '0 0 50 50',
    data: '<path pid="0" d="M13.897 33.925c1.235 8.609 5.575 13.977 11.426 14.119a9.234 9.234 0 004.08.956c5.553 0 11.221-4.778 15.55-13.11 5.824-11.222 2.5-28.292 2.355-29.014-.114-.572-.812-.993-1.423-.7-.412.183-6.23 2.805-12.113 7.28-2.081-4.536-4.76-8.828-7.432-12.072l-.004-.004a.993.993 0 00-.778-.38.992.992 0 00-.78.383l-.004.004c-1.737 2.122-4.083 5.691-6.1 9.485C11.944 6.48 3.836 4.625 2.916 4.426c-.03-.01-.062-.009-.092-.016-.1.027-1.17-.123-1.2.94 0 .013-.007.025-.007.039.006.614.218 15.159 6.937 23.358 1.743 2.13 3.539 3.86 5.343 5.178zm-3.796-6.444C6.495 23.078 4.896 16.48 4.185 11.633c2.035 4.352 5.24 10.33 9.367 15.246a31.554 31.554 0 00.026 4.243c-1.167-1.023-2.33-2.241-3.477-3.643zm3.76-3.407C9.56 18.468 6.368 11.75 4.749 7.978c2.81 1.931 7.104 5.123 11.088 9.05-.829 2.164-1.599 4.777-1.976 7.046zm8.053 10.957c-1.161-9.214.93-20.647 3.606-29.597l.038-.13.015.049c3.387 11.296 6.914 30.558-.014 40.097-.08-.172-2.758-3.32-3.645-10.42zm21.263-.062c-3.831 7.374-8.636 11.715-13.262 12.014 2.825-1.494 4.742-4.344 5.891-7.36.336-.882 6.267-7.836 10.33-26.166.48 5.934.455 14.935-2.959 21.512zm-6.882-15.001c3.167-4.218 6.35-7.72 8.5-9.95-.952 4.89-3.23 14.707-7.44 23.005.6-5.11.179-8.947-1.06-13.055zm4.962-9.1a110.665 110.665 0 00-5.732 6.814 46.906 46.906 0 00-.921-2.347c2.312-1.79 4.652-3.293 6.653-4.466zm-5.64 17.354c0 6.482-1.642 15.516-7.805 17.484 6.265-9.834 3.454-27.01.426-38.247 3.57 5.653 7.379 14.052 7.379 20.763zM23.32 45.72c-7.755-2.436-9.342-16.91-6.24-26.217 1.36-4.095 3.45-8.15 5.745-11.846-3.007 11.239-5.743 28.267.495 38.063zm-6.665-30.687c-2.846-2.735-5.793-5.09-8.253-6.905 2.842 1.013 6.279 2.492 9.348 4.52-.05.102-.622 1.3-1.095 2.385z"/>'
  }
});