"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'youtube': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_youtube_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="2" clip-path="url(#svgicon_youtube_a)"><path pid="1" data-name="Subtraction 2" d="M50.91 90.777A40.6 40.6 0 0122.2 21.473a40.6 40.6 0 0157.41 57.41 40.332 40.332 0 01-28.7 11.894zm.389-55.376c-1.563 0-15.372.029-19.028 1.017a6.134 6.134 0 00-4.241 4.241 65.853 65.853 0 000 23.547 6.142 6.142 0 004.241 4.244c3.753 1.008 18.94 1.02 19.093 1.02s15.3-.012 19.093-1.02a6.15 6.15 0 004.244-4.244 63.752 63.752 0 001.009-11.749 63.781 63.781 0 00-1.017-11.8 6.142 6.142 0 00-4.244-4.241c-3.752-1.003-18.939-1.014-19.089-1.014zm-4.817 24.355V45.138l12.728 7.319-12.722 7.3z" _fill="#3c9900"/></g>'
  }
});