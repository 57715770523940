"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.string.trim.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    attrs: {
      "label-position": "top"
    }
  }, [_c("el-row", [_c("el-col", [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "fullName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "formData.fullName"
    }
  })], 1)], 1), _c("el-col", [_c("el-form-item", {
    attrs: {
      label: "Email"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.emailAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "emailAddress", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "formData.emailAddress"
    }
  })], 1)], 1), _c("el-col", [_c("el-form-item", {
    attrs: {
      label: "Avatar",
      prop: "formData.avatar"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/users/upload",
      field: "avatar",
      accept: ".jpg, .jpeg, .png",
      "list-type": "picture",
      "file-list": _vm.avatar
    },
    on: {
      change: _vm.fileUploaded
    }
  })], 1)], 1), _c("el-col", [_c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" Update ")])], 1)], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;