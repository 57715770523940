"use strict";

var _interopRequireWildcard = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bus = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
require("C:\\Innovura2\\tbsellerpanel\\tradebridge-seller\\tradebridge-seller\\node_modules\\core-js\\modules\\es.array.iterator.js");
require("C:\\Innovura2\\tbsellerpanel\\tradebridge-seller\\tradebridge-seller\\node_modules\\core-js\\modules\\es.promise.js");
require("C:\\Innovura2\\tbsellerpanel\\tradebridge-seller\\tradebridge-seller\\node_modules\\core-js\\modules\\es.object.assign.js");
require("C:\\Innovura2\\tbsellerpanel\\tradebridge-seller\\tradebridge-seller\\node_modules\\core-js\\modules\\es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _elementUi = _interopRequireDefault(require("element-ui"));
require("normalize.css");
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
require("@/styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("@/App.vue"));
var directives = _interopRequireWildcard(require("@/directives"));
var filters = _interopRequireWildcard(require("@/filters"));
require("@/icons/components");
var _lang = _interopRequireDefault(require("@/lang"));
require("@/permission");
var _router = _interopRequireDefault(require("@/router"));
var _store = _interopRequireDefault(require("@/store"));
var _app = require("@/store/modules/app");
require("@/utils/error-log");
var _vueQrcode = _interopRequireDefault(require("@chenfengyuan/vue-qrcode"));
require("element-ui/lib/theme-chalk/display.css");
var _vueBrowserGeolocation = _interopRequireDefault(require("vue-browser-geolocation"));
var _vueCtkDateTimePicker = _interopRequireDefault(require("vue-ctk-date-time-picker"));
require("vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css");
var _vueGtag = _interopRequireDefault(require("vue-gtag"));
var _vueMoment = _interopRequireDefault(require("vue-moment"));
var _vuePluginLoadScript = _interopRequireDefault(require("vue-plugin-load-script"));
var _vueSnotify = _interopRequireWildcard(require("vue-snotify"));
_vue.default.component("VueCtkDateTimePicker", _vueCtkDateTimePicker.default);
_vue.default.use(_elementUi.default, {
  size: _app.AppModule.size,
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
_vue.default.use(_vueMoment.default);
_vue.default.use(_vuePluginLoadScript.default);
_vue.default.component(_vueQrcode.default.name, _vueQrcode.default);
_vue.default.use(_vueSvgicon.default, {
  tagName: "svg-icon",
  defaultWidth: "1em",
  defaultHeight: "1em"
});
_vue.default.filter("truncate", function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});
// Aligning Position for the toaster
var options = {
  toast: {
    position: _vueSnotify.SnotifyPosition.rightTop
  }
};
// plugins
_vue.default.use(_vueSnotify.default, options);
// google ads
_vue.default.use(_vueGtag.default, {
  config: {
    id: "AW-10825774436"
  }
});
// Register global directives
Object.keys(directives).forEach(function (key) {
  _vue.default.directive(key, directives[key]);
});
// Register global filter functions
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
_vue.default.use(_vueBrowserGeolocation.default);
var bus = new _vue.default();
exports.bus = bus;
new _vue.default({
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount("#app");