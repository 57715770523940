"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'OpenOrder': {
    width: 250,
    height: 250,
    viewBox: '0 0 250 250',
    data: '<defs><clipPath id="svgicon_OpenOrder_a"><path pid="0" d="M0 0h250v250H0z"/></clipPath></defs><g data-name="Open Order" clip-path="url(#svgicon_OpenOrder_a)"><ellipse pid="1" data-name="Ellipse 250" cx="104" cy="101" rx="104" ry="101" transform="translate(21 24)" _fill="#f8f8f8"/><g data-name="Group 44" transform="translate(79.878 80.257)" _fill="#b5aeae"><ellipse pid="2" data-name="Ellipse 248" cx="10.967" cy="10.727" rx="10.967" ry="10.727" transform="translate(24.732 69.287)"/><path pid="3" data-name="Path 1399" d="M67.704 69.29a10.73 10.73 0 1010.967 10.727A10.849 10.849 0 0067.704 69.29z"/><path pid="4" data-name="Path 1400" d="M86.393 58.342H29.208l-1.121-6.348h47a3.357 3.357 0 003.133-2.08L89.414 22.33a3.261 3.261 0 00-1.892-4.259 3.421 3.421 0 00-1.241-.229H22.27L19.587 2.737a3.338 3.338 0 00-3.36-2.736H3.357A3.321 3.321 0 000 3.285a3.321 3.321 0 003.357 3.283h10.072l9.624 55.6a3.338 3.338 0 003.357 2.736h59.983a3.285 3.285 0 100-6.568z"/></g></g>'
  }
});