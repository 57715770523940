// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.countdown {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.countdown__block {\n  padding: 0px 3px;\n  position: relative;\n}\n.countdown__block:first-child {\n  padding-left: 0;\n}\n.countdown__block:first-child .countdown__digit:before {\n  display: none;\n}\n.countdown__block:last-child {\n  padding-right: 0;\n}\n.countdown__text {\n  display: inline-block;\n  text-transform: uppercase;\n  margin-bottom: 1px;\n}\n.countdown__digit {\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 1;\n  margin-bottom: 5px;\n}\n.countdown__digit:before {\n  content: \":\";\n  position: absolute;\n  left: -5px;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#F2F2F2",
	"menuText": "black",
	"menuActiveText": "#409eff"
};
module.exports = exports;
