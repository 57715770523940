"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePincode = exports.getPincodes = exports.getPincodeById = exports.fileTransferHeaders = exports.deletePincode = exports.defaultPincodeData = exports.createPincode = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultPincodeData = {
  id: 0,
  enabled: true,
  countryId: undefined,
  country: {
    id: null
  },
  stateId: undefined,
  state: {
    id: null
  },
  cityId: undefined,
  city: {
    id: null
  },
  pincode: '',
  pincodeName: ''
};
exports.defaultPincodeData = defaultPincodeData;
var getPincodes = function getPincodes(params) {
  return (0, _request.default)({
    url: '/pincodes',
    method: 'get',
    params: params
  });
};
exports.getPincodes = getPincodes;
var getPincodeById = function getPincodeById(id) {
  return (0, _request.default)({
    url: "/pincodes/".concat(id),
    method: 'get'
  });
};
exports.getPincodeById = getPincodeById;
var updatePincode = function updatePincode(id, data) {
  return (0, _request.default)({
    url: "/pincodes/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updatePincode = updatePincode;
var deletePincode = function deletePincode(id) {
  return (0, _request.default)({
    url: "/pincodes/".concat(id),
    method: 'delete'
  });
};
exports.deletePincode = deletePincode;
var createPincode = function createPincode(data) {
  return (0, _request.default)({
    url: '/pincodes/',
    method: 'post',
    data: data
  });
};
exports.createPincode = createPincode;