"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "address-block"
  }, [_c("el-row", [_c("b", [_c("small", [_vm._v("Delivery Details")])]), _c("br"), _c("br"), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 8,
      lg: 8
    }
  }, [_c("small", [_c("b", [_vm._v("Billing Address")]), _c("p", [_vm._v(" " + _vm._s(_vm.data.address.name) + "," + _vm._s(_vm.data.address.line1) + "," + _vm._s(_vm.data.address.line2)), _c("br"), _vm._v(" " + _vm._s(_vm.data.address.city.name) + " " + _vm._s(_vm.data.address.state.name) + " " + _vm._s(_vm.data.address.country.name) + "-" + _vm._s(_vm.data.address.pincode.pincode) + " ")])])]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 8,
      lg: 8
    }
  }, [_c("small", [_c("b", [_vm._v("Shipping Address")]), _c("p", [_vm._v(" " + _vm._s(_vm.data.shippingAddress.name) + "," + _vm._s(_vm.data.shippingAddress.line1) + ", "), _c("br"), _vm._v(" " + _vm._s(_vm.data.shippingAddress.line2) + _vm._s(_vm.data.shippingAddress.city.name) + " " + _vm._s(_vm.data.shippingAddress.state.name) + " " + _vm._s(_vm.data.shippingAddress.country.name) + "-" + _vm._s(_vm.data.shippingAddress.pincode.pincode) + " ")])]), _vm.data.deliveryOption ? _c("p", [_c("small", [_c("b", [_vm._v("Delivery Option:")]), _vm._v(" " + _vm._s(_vm.data.deliveryOption))]), _vm._v("         ")]) : _vm._e()]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 8,
      lg: 8
    }
  }, [_c("small", [_c("b", [_vm._v("Contact Details")]), _c("p", [_vm._v(_vm._s(_vm.data.user.fullName))]), _c("p", [_vm._v("Email: " + _vm._s(_vm.data.user.emailAddress))]), _vm._v(" Phone: +91" + _vm._s(_vm.data.user.mobileNumber))])])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;