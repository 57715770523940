"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSellerProduct = exports.productSellerCount = exports.getSellerProducts = exports.getSellerProductById = exports.disableSellerProduct = exports.deleteSellerProduct = exports.defaultSellerProductData = exports.createSellerProduct = exports.checkProductPrice = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultSellerProductData = {
  id: 0,
  enabled: true,
  name: '',
  stockQuantity: 1,
  unitPrice: 1,
  sellerQuantity: 1,
  tbQuantity: 1,
  tax: {
    id: undefined
  },
  measurementUnit: {
    id: undefined
  },
  category: [],
  attribute: [],
  productsImage: [],
  sellerId: undefined,
  seller: {
    id: undefined
  },
  productId: 1,
  product: {
    id: null
  },
  productsVariationId: undefined,
  productsVariation: {
    id: undefined
  },
  pincodeId: undefined,
  pincode: {
    id: undefined
  }
};
exports.defaultSellerProductData = defaultSellerProductData;
var getSellerProducts = function getSellerProducts(params) {
  return (0, _request.default)({
    url: '/sellers-products-variations',
    method: 'get',
    params: params
  });
};
exports.getSellerProducts = getSellerProducts;
var getSellerProductById = function getSellerProductById(id) {
  return (0, _request.default)({
    url: "/sellers-products-variations/".concat(id),
    method: 'get'
  });
};
exports.getSellerProductById = getSellerProductById;
var updateSellerProduct = function updateSellerProduct(id, data) {
  return (0, _request.default)({
    url: "/sellers-products-variations/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateSellerProduct = updateSellerProduct;
var deleteSellerProduct = function deleteSellerProduct(id) {
  return (0, _request.default)({
    url: "/sellers-products-variations/".concat(id),
    method: 'delete'
  });
};
exports.deleteSellerProduct = deleteSellerProduct;
var createSellerProduct = function createSellerProduct(data) {
  return (0, _request.default)({
    url: '/sellers-products-variations/',
    method: 'post',
    data: data
  });
};
exports.createSellerProduct = createSellerProduct;
var disableSellerProduct = function disableSellerProduct(data) {
  return (0, _request.default)({
    url: '/sellers-products-variations/disable',
    method: 'post',
    data: data
  });
};
exports.disableSellerProduct = disableSellerProduct;
var productSellerCount = function productSellerCount(params) {
  return (0, _request.default)({
    url: '/sellers-products-variations/count',
    method: 'get',
    params: params
  });
};
exports.productSellerCount = productSellerCount;
var checkProductPrice = function checkProductPrice(data) {
  return (0, _request.default)({
    url: '/sellers-products-variations/variations',
    method: 'post',
    data: data
  });
};
exports.checkProductPrice = checkProductPrice;