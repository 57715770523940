"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidURL = exports.isValidPAN = exports.isValidGST = exports.isValidFullName = exports.isValidEmail = exports.isValidAddress = exports.isExternal = exports.isArray = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.object.to-string.js");
var isValidEmail = function isValidEmail(str) {
  var validateEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validateEmail.test(str);
};
exports.isValidEmail = isValidEmail;
var isValidFullName = function isValidFullName(str) {
  var validateFullName = /^[a-zA-Z][a-zA-Z ]+$/;
  return validateFullName.test(str);
};
exports.isValidFullName = isValidFullName;
var isValidAddress = function isValidAddress(str) {
  var validateAddress = /^[0-9]*$/;
  return validateAddress.test(str);
};
exports.isValidAddress = isValidAddress;
var isValidGST = function isValidGST(str) {
  var validateGST = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})$/;
  return validateGST.test(str);
};
exports.isValidGST = isValidGST;
var isValidPAN = function isValidPAN(str) {
  var validPAN = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  return validPAN.test(str);
};
exports.isValidPAN = isValidPAN;
var isExternal = function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
};
exports.isExternal = isExternal;
var isArray = function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
};
exports.isArray = isArray;
var isValidURL = function isValidURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
};
exports.isValidURL = isValidURL;