"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrdersTicket = exports.ordersTicketsCount = exports.getOrdersTickets = exports.getOrdersTicketById = exports.fileTransferHeaders = exports.deleteOrdersTicket = exports.defaultBuyerOrdersTicketData = exports.createOrdersTicket = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultBuyerOrdersTicketData = {
  id: 0,
  enabled: true,
  userId: 0,
  orderId: '',
  ordersSellerId: 0,
  status: 'Open',
  closureComments: '',
  closureAttachment: '',
  comments: '',
  attachment1: '',
  attachment2: '',
  attachment3: '',
  user: {
    id: null
  },
  order: {
    id: null
  }
};
exports.defaultBuyerOrdersTicketData = defaultBuyerOrdersTicketData;
var getOrdersTickets = function getOrdersTickets(params) {
  return (0, _request.default)({
    url: '/orders-tickets',
    method: 'get',
    params: params
  });
};
exports.getOrdersTickets = getOrdersTickets;
var getOrdersTicketById = function getOrdersTicketById(id) {
  return (0, _request.default)({
    url: "/orders-tickets/".concat(id),
    method: 'get'
  });
};
exports.getOrdersTicketById = getOrdersTicketById;
var updateOrdersTicket = function updateOrdersTicket(id, data) {
  return (0, _request.default)({
    url: "/orders-tickets/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateOrdersTicket = updateOrdersTicket;
var deleteOrdersTicket = function deleteOrdersTicket(id) {
  return (0, _request.default)({
    url: "/orders-tickets/".concat(id),
    method: 'delete'
  });
};
exports.deleteOrdersTicket = deleteOrdersTicket;
var createOrdersTicket = function createOrdersTicket(data) {
  return (0, _request.default)({
    url: '/orders-tickets/',
    method: 'post',
    data: data
  });
};
exports.createOrdersTicket = createOrdersTicket;
var ordersTicketsCount = function ordersTicketsCount(params) {
  return (0, _request.default)({
    url: '/orders-tickets/count',
    method: 'get',
    params: params
  });
};
exports.ordersTicketsCount = ordersTicketsCount;