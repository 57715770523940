"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_vm.classObj.mobile && _vm.sidebar.opened ? _c("div", {
    staticClass: "drawer-bg",
    on: {
      click: _vm.handleClickOutside
    }
  }) : _vm._e(), _c("sidebar", {
    staticClass: "sidebar-container",
    staticStyle: {
      background: "rgb(242, 242, 242)"
    }
  }), _c("div", {
    staticClass: "main-container",
    class: {
      hasTagsView: _vm.showTagsView
    }
  }, [_c("div", {
    class: {
      "fixed-header": _vm.fixedHeader
    }
  }, [_c("navbar")], 1), _c("header-message"), _c("app-main")], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;