"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateRole = exports.getRoutes = exports.getRoles = exports.getRoleById = exports.deleteRole = exports.defaultAdminRoleData = exports.createRole = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultAdminRoleData = {
  id: 0,
  enabled: true,
  name: ''
};
exports.defaultAdminRoleData = defaultAdminRoleData;
var getRoles = function getRoles(params) {
  return (0, _request.default)({
    url: '/admins-roles',
    method: 'get',
    params: params
  });
};
exports.getRoles = getRoles;
var getRoleById = function getRoleById(id) {
  return (0, _request.default)({
    url: "/admins-roles/".concat(id),
    method: 'get'
  });
};
exports.getRoleById = getRoleById;
var updateRole = function updateRole(id, data) {
  return (0, _request.default)({
    url: "/admins-roles/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateRole = updateRole;
var deleteRole = function deleteRole(id) {
  return (0, _request.default)({
    url: "/admins-roles/".concat(id),
    method: 'delete'
  });
};
exports.deleteRole = deleteRole;
var createRole = function createRole(data) {
  return (0, _request.default)({
    url: '/admins-roles/',
    method: 'post',
    data: data
  });
};
exports.createRole = createRole;
var getRoutes = function getRoutes(params) {
  return (0, _request.default)({
    url: '/routes',
    method: 'get',
    params: params
  });
};
exports.getRoutes = getRoutes;