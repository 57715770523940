"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAddresses = exports.getAddressesById = exports.getAddresses = exports.fileTransferHeaders = exports.deleteAddresses = exports.defaultAddressData = exports.createAddresses = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultAddressData = {
  id: 0,
  enabled: true,
  name: '',
  contactId: 0,
  contact: {
    id: null
  },
  countryId: 0,
  country: {
    id: null
  },
  stateId: 0,
  state: {
    id: null
  },
  cityId: 0,
  city: {
    id: null
  },
  pincodeId: 0,
  pincode: {
    id: null
  },
  line1: '',
  line2: ''
};
exports.defaultAddressData = defaultAddressData;
var getAddresses = function getAddresses(params) {
  return (0, _request.default)({
    url: '/addresses',
    method: 'get',
    params: params
  });
};
exports.getAddresses = getAddresses;
var getAddressesById = function getAddressesById(id) {
  return (0, _request.default)({
    url: "/addresses/".concat(id),
    method: 'get'
  });
};
exports.getAddressesById = getAddressesById;
var updateAddresses = function updateAddresses(id, data) {
  return (0, _request.default)({
    url: "/addresses/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateAddresses = updateAddresses;
var deleteAddresses = function deleteAddresses(id) {
  return (0, _request.default)({
    url: "/addresses/".concat(id),
    method: 'delete'
  });
};
exports.deleteAddresses = deleteAddresses;
var createAddresses = function createAddresses(data) {
  return (0, _request.default)({
    url: '/addresses/',
    method: 'post',
    data: data
  });
};
exports.createAddresses = createAddresses;