"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'whatsapp': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_whatsapp_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="whatsapp 2" clip-path="url(#svgicon_whatsapp_a)"><path pid="1" data-name="Icon awesome-whatsapp" d="M73.01 26.7a32.567 32.567 0 00-51.24 39.285L17.15 82.85l17.261-4.532a32.458 32.458 0 0015.56 3.96h.015c17.936 0 32.865-14.607 32.865-32.557A32.685 32.685 0 0073.01 26.7zM49.986 76.8A27.012 27.012 0 0136.2 73.024l-.983-.587-10.236 2.685 2.728-9.987-.645-1.027A27.113 27.113 0 1177.35 49.721a27.362 27.362 0 01-27.365 27.072zm14.841-20.274c-.807-.411-4.81-2.376-5.558-2.64s-1.291-.411-1.833.411-2.1 2.64-2.581 3.2-.953.616-1.76.205c-4.781-2.39-7.919-4.268-11.072-9.679-.836-1.437.836-1.335 2.39-4.444a1.508 1.508 0 00-.073-1.423c-.205-.411-1.833-4.414-2.508-6.042-.66-1.584-1.335-1.364-1.833-1.393-.469-.029-1.012-.029-1.555-.029a3.013 3.013 0 00-2.17 1.012 9.141 9.141 0 00-2.845 6.79c0 4 2.918 7.875 3.314 8.418S42.477 59.664 50.65 63.2c5.162 2.229 7.186 2.42 9.767 2.038 1.569-.235 4.81-1.965 5.485-3.872a6.8 6.8 0 00.469-3.872c-.195-.367-.738-.572-1.544-.968z" _fill="#3c9900"/></g>'
  }
});