"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'OrderCancelled': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_OrderCancelled_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="Order Cancelled"><g data-name="Group 9661" _fill="#b5aeae" clip-path="url(#svgicon_OrderCancelled_a)"><path pid="1" data-name="Path 12860" d="M14.3 43.725V14.3h23.149c-.007.116-.02.232-.02.348v20.009c0 .181.022.361.036.58h16.718V14.3h23.149c0 .3-.012.6-.012.906V53.14c-7.463-2.3-14.117-.951-19.616 4.55s-6.857 12.152-4.561 19.625H27.678q-6.69 0-13.379.014V64.64c.116.009.231.025.347.025h28.439c.18 0 .36-.027.577-.044V43.757c-.168-.016-.3-.041-.436-.041q-14.463.003-28.926.009z"/><path pid="2" data-name="Path 12861" d="M69.384 85.7c-1.028-.253-2.08-.433-3.08-.77a14.646 14.646 0 01-9.9-15.638 14.638 14.638 0 0112.89-12.888 14.646 14.646 0 0116.28 12.669 1.365 1.365 0 00.127.311v3.207a1.531 1.531 0 00-.126.313c-1.043 6.33-4.662 10.409-10.768 12.3-.72.223-1.476.334-2.215.5zm-1.953-8.392l3.743-3.837 3.592 3.693 2.986-3-3.654-3.639 3.607-3.592-2.982-3-3.677 3.695-3.656-3.688-3.037 3.045 3.735 3.692-3.645 3.626z"/><path pid="3" data-name="Path 12862" d="M14.3 47.908h25.179v12.54c-.2.019-.4.055-.6.056h-24.1c-.161 0-.321-.029-.482-.045zm6.337 8.338H33.12v-4.1H20.637z"/><path pid="4" data-name="Path 12863" d="M50 14.3v16.776h-8.34a5.034 5.034 0 01-.058-.538V14.715c0-.139.021-.277.032-.416z"/></g></g>'
  }
});