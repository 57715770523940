"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "pagination-container",
    class: {
      hidden: _vm.hidden
    }
  }, [_c("el-pagination", _vm._b({
    attrs: {
      background: _vm.background,
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      layout: _vm.layout,
      "page-sizes": _vm.pageSizes,
      total: _vm.total
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:pageSize": function updatePageSize($event) {
        _vm.pageSize = $event;
      },
      "update:page-size": function updatePageSize($event) {
        _vm.pageSize = $event;
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  }, "el-pagination", _vm.$attrs, false))], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;