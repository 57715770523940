"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "center",
      align: "center"
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 12
    }
  }, [_c("h3", [_vm._v("Terms & Conditions")]), _c("a", {
    staticClass: "el-button el-button--primary is-round",
    attrs: {
      href: _vm.pdflink
    }
  }, [_vm._v("View Terms & Conditions")])])], 1), _c("div", {
    staticClass: "pdfvuer"
  }, _vm._l(_vm.numPages, function (i) {
    return _c("pdf", {
      key: i,
      staticStyle: {
        width: "100%",
        margin: "-90px"
      },
      attrs: {
        id: i,
        src: _vm.pdfdata,
        page: i,
        scale: _vm.scale,
        annotation: true,
        resize: true
      },
      on: {
        "update:scale": function updateScale($event) {
          _vm.scale = $event;
        },
        "link-clicked": _vm.handlePdfLink
      }
    }, [_c("template", {
      slot: "loading"
    }, [_vm._v(" loading content here... ")])], 2);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;