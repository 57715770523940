"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container",
    staticStyle: {
      padding: "20px 0"
    }
  }, [_c("el-tabs", {
    attrs: {
      type: "border-card"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Info"
    }
  }, [_c("order-info-tab", {
    attrs: {
      data: _vm.orderInfoData
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Product"
    }
  }, [_c("order-product-tab", {
    attrs: {
      data: _vm.orderInfoData
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Users"
    }
  }, [_c("order-user-tab", {
    attrs: {
      data: _vm.orderInfoData
    }
  })], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;