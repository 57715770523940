"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatPrice = exports.articleStatusFilter = void 0;
Object.defineProperty(exports, "parseTime", {
  enumerable: true,
  get: function get() {
    return _utils.parseTime;
  }
});
exports.uppercaseFirstChar = void 0;
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _admin = require("@/store/modules/admin");
var _utils = require("@/utils");
// Set utils function parseTime to filter

// Filter for article status
var articleStatusFilter = function articleStatusFilter(status) {
  var statusMap = {
    published: 'success',
    draft: 'info',
    deleted: 'danger'
  };
  return statusMap[status];
};
// Filter to uppercase the first character
exports.articleStatusFilter = articleStatusFilter;
var uppercaseFirstChar = function uppercaseFirstChar(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
exports.uppercaseFirstChar = uppercaseFirstChar;
var formatPrice = function formatPrice(price) {
  var isDocumentUploaded = _admin.AdminModule.isDocumentUploaded;
  var isKYCVerified = _admin.AdminModule.isKYCVerified;
  var hasActiveSubscription = _admin.AdminModule.activeSubscriptionPlanId !== 0;
  var subscriptionRequired = _admin.AdminModule.subscriptionRequired;
  if (price) {
    // check if subscription is active or subscription is not required
    if (isDocumentUploaded && isKYCVerified && (hasActiveSubscription || !subscriptionRequired)) {
      return price;
    }
    // mask price 500.00 as 5XX.XX
    // remove first digit from price
    var firstDigit = price.toString().substring(0, 1);
    // get price without first digit
    var priceWithoutFirstDigit = price.toString().substring(1);
    // replace all digits with X
    var maskedPrice = priceWithoutFirstDigit.replace(/\d/g, 'X');
    // return masked price
    return firstDigit + maskedPrice;
  }
  return price;
};
exports.formatPrice = formatPrice;