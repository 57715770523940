"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var orderRouter = {
  path: '/order',
  component: _index.default,
  redirect: 'noredirect',
  name: 'orderManagement.title',
  meta: {
    title: 'orderManagement.title',
    icon: 'order'
  },
  children: [{
    path: '/order/list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/list.vue'));
      });
    },
    name: 'Orders',
    meta: {
      title: 'orderManagement.orders.title',
      icon: 'order',
      noCache: true
    }
  }, {
    path: '/order/edit/:id(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/manage.vue'));
      });
    },
    name: 'orderManagement.orders.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'orderManagement.orders.edit',
      // roles: ['superadmin'],
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  },
  // {
  //   path: '/order/:id(.*)',
  //   component: () => import('@/views/order/components/OrderInfoDetails.vue'),
  //   name: 'orderManagement.orders.info',
  //   props: {
  //     isEdit: true
  //   },
  //   meta: {
  //     title: 'orderManagement.orders.info',
  //     noCache: true,
  //     activeMenu: '/order/list',
  //     hidden: true
  //   }
  // },
  {
    path: '/order/add',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/manage.vue'));
      });
    },
    name: 'orderManagement.orders.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'orderManagement.orders.add',
      // roles: ['superadmin'],
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: '/transaction/list/',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/transaction/list.vue'));
      });
    },
    name: 'Transaction',
    meta: {
      title: 'orderManagement.transaction.title',
      // roles: ['superadmin'],
      noCache: true,
      icon: 'inr',
      hidden: true
    }
  }, {
    path: '/transaction/list/:id(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/transaction/list.vue'));
      });
    },
    name: 'Transaction',
    meta: {
      title: 'orderManagement.transaction.title',
      roles: ['superadmin'],
      noCache: true,
      hidden: true
    }
  }, {
    path: '/transaction/add/:id(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/transaction/manage.vue'));
      });
    },
    name: 'orderManagement.transaction.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'orderManagement.transaction.add',
      noCache: true,
      activeMenu: '/transaction/list/:id(.*)',
      hidden: true
    }
  }, {
    path: '/transaction/edit/:id(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/transaction/manage.vue'));
      });
    },
    name: 'orderManagement.transaction.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'orderManagement.transaction.edit',
      noCache: true,
      activeMenu: '/transaction/list/:id(.*)',
      hidden: true
    }
  }
  // ...bidsSubMenu,
  // ...ordersTicketsSubMenu,
  // ...couponsSubMenu
  ]
};
var _default = orderRouter;
exports.default = _default;