"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMeasurementUnits = exports.getMeasurementUnitsById = exports.getMeasurementUnits = exports.fileTransferHeaders = exports.deleteMeasurementUnits = exports.defaultMeasurementUnitsData = exports.createMeasurementUnits = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultMeasurementUnitsData = {
  id: 0,
  enabled: true,
  name: '',
  code: ''
};
exports.defaultMeasurementUnitsData = defaultMeasurementUnitsData;
var getMeasurementUnits = function getMeasurementUnits(params) {
  return (0, _request.default)({
    url: '/measurement-units',
    method: 'get',
    params: params
  });
};
exports.getMeasurementUnits = getMeasurementUnits;
var getMeasurementUnitsById = function getMeasurementUnitsById(id) {
  return (0, _request.default)({
    url: "/measurement-units/".concat(id),
    method: 'get'
  });
};
exports.getMeasurementUnitsById = getMeasurementUnitsById;
var updateMeasurementUnits = function updateMeasurementUnits(id, data) {
  return (0, _request.default)({
    url: "/measurement-units/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateMeasurementUnits = updateMeasurementUnits;
var deleteMeasurementUnits = function deleteMeasurementUnits(id) {
  return (0, _request.default)({
    url: "/measurement-units/".concat(id),
    method: 'delete'
  });
};
exports.deleteMeasurementUnits = deleteMeasurementUnits;
var createMeasurementUnits = function createMeasurementUnits(data) {
  return (0, _request.default)({
    url: '/measurement-units/',
    method: 'post',
    data: data
  });
};
exports.createMeasurementUnits = createMeasurementUnits;