"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'email': {
    width: 128,
    height: 96,
    viewBox: '0 0 128 96',
    data: '<path pid="0" d="M64.1 57l56-56a12.5 12.5 0 00-4.6-1h-103C10.9 0 9.4.3 8 .8L64 57z"/><path pid="1" d="M64.1 68.3L1.8 6A12.4 12.4 0 000 12.5v71C0 90.4 5.6 96 12.5 96h103c6.9 0 12.5-5.6 12.5-12.5v-71a12.5 12.5 0 00-1.7-6.3L64 68.2z"/>'
  }
});