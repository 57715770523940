"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendRecoveryMail = exports.fileTransferHeaders = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var sendRecoveryMail = function sendRecoveryMail(data) {
  return (0, _request.default)({
    url: '/queue/email',
    method: 'post',
    data: data
  });
};
exports.sendRecoveryMail = sendRecoveryMail;