"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    class: {
      "has-logo": _vm.showLogo
    }
  }, [_vm.showLogo ? _c("sidebar-logo", {
    attrs: {
      collapse: _vm.isCollapse
    }
  }) : _vm._e(), !_vm.isCollapse ? _c("el-row", {
    staticStyle: {
      padding: "7px"
    },
    attrs: {
      type: "flex",
      align: "middle",
      justify: "space-between",
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm.roles[0] === "Buyer" ? _c("el-avatar", {
    staticClass: "avatar-buyer",
    attrs: {
      size: 60,
      shape: "square"
    }
  }, [_c("img", {
    attrs: {
      src: _vm.avatar + "-md"
    }
  })]) : _c("el-avatar", {
    staticClass: "avatar-seller",
    attrs: {
      size: 60,
      shape: "square"
    }
  }, [_c("img", {
    attrs: {
      src: _vm.avatar + "-md"
    }
  })])], 1), _c("el-col", {
    staticStyle: {
      "font-size": "14px"
    },
    attrs: {
      span: 17
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.name))]), _c("br")])], 1) : _vm._e(), _c("el-scrollbar", {
    attrs: {
      "wrap-class": "scrollbar-wrapper"
    }
  }, [_c("el-menu", {
    attrs: {
      "default-active": _vm.activeMenu,
      collapse: _vm.isCollapse,
      "background-color": _vm.variables.menuBg,
      "text-color": _vm.variables.menuText,
      "active-text-color": _vm.menuActiveTextColor,
      "unique-opened": false,
      "collapse-transition": false,
      mode: "vertical"
    }
  }, _vm._l(_vm.routes, function (route) {
    return _c("sidebar-item", {
      key: route.path,
      attrs: {
        item: route,
        "base-path": route.path,
        "is-collapse": _vm.isCollapse
      }
    });
  }), 1)], 1), _c("el-row", {
    attrs: {
      type: !_vm.isCollapse ? "flex" : "",
      justify: "center"
    }
  }, [_c("a", {
    attrs: {
      href: "https://twitter.com/Tradebridge2",
      target: "_blank"
    }
  }, [_c("img", {
    attrs: {
      src: "/img/social/1.svg",
      width: "25"
    }
  })]), _c("a", {
    attrs: {
      href: "https://www.youtube.com/channel/UCrBs01ultY5EojVNRRKNzzg",
      target: "_blank"
    }
  }, [_c("img", {
    attrs: {
      src: "/img/social/2.svg",
      width: "25"
    }
  })]), _c("a", {
    attrs: {
      href: "https://www.instagram.com/tradebridge_b2b",
      target: "_blank"
    }
  }, [_c("img", {
    attrs: {
      src: "/img/social/3.svg",
      width: "25"
    }
  })]), _c("a", {
    attrs: {
      href: "https://www.linkedin.com/company/tradebridgeb2b/",
      target: "_blank"
    }
  }, [_c("img", {
    attrs: {
      src: "/img/social/4.svg",
      width: "25"
    }
  })])])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;