"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'removeFav': {
    width: 16,
    height: 16,
    viewBox: '0 0 495 495',
    data: '<path pid="0" d="M362.96 14.24c-49.72 0-92.95 27.53-115.46 68.13v398.39C284.67 452.84 495 288.55 495 146.28c0-72.92-59.12-132.04-132.04-132.04z" _fill="#c70024"/><path pid="1" d="M132.04 14.24C59.12 14.24 0 73.36 0 146.28c0 142.26 210.33 306.55 247.5 334.48V82.37c-22.51-40.6-65.74-68.13-115.46-68.13z" _fill="#ff0c38"/>'
  }
});