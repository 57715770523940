"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadProductImages = exports.updateProductVariations = exports.updateProductInventories = exports.updateProductImages = exports.updateProductCategories = exports.updateProductAttributes = exports.updateProduct = exports.productCount = exports.getProducts = exports.getProductById = exports.deleteProductVariation = exports.deleteProductImages = exports.deleteProduct = exports.defaultSellerMyProductData = exports.defaultProductData = exports.createProduct = exports.allProduct = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultProductData = {
  id: 0,
  enabled: true,
  name: null,
  sku: '',
  productType: 'simple',
  priority: null,
  measurementUnitId: null,
  multiplier: 1,
  featured: false,
  isfreightAllowed: true,
  suppliedBy: '',
  tags: null,
  shortDescription: null,
  description: null,
  isTaxable: true,
  taxId: null,
  hsnCode: null,
  manageStock: false,
  stockStatus: 'In Stock',
  tax: {
    id: undefined
  },
  measurementUnit: {
    id: undefined
  },
  unitOfMeasurementId: null,
  category: [],
  attribute: [],
  productsImage: [],
  productsVariation: []
};
exports.defaultProductData = defaultProductData;
var defaultSellerMyProductData = {
  id: 0,
  sellerId: 0,
  addedByType: null,
  enabled: true,
  name: null,
  sku: '',
  productType: 'simple',
  priority: null,
  measurementUnitId: null,
  multiplier: 1,
  featured: false,
  isfreightAllowed: true,
  suppliedBy: '',
  tags: null,
  shortDescription: null,
  description: null,
  isTaxable: true,
  taxId: null,
  hsnCode: null,
  manageStock: true,
  stockStatus: 'In Stock',
  tax: {
    id: undefined
  },
  measurementUnit: {
    id: undefined
  },
  unitOfMeasurementId: null,
  category: [],
  attribute: [],
  productsImage: [],
  productsVariation: [],
  pincodeId: 0,
  stateId: 0,
  cityId: 0
};
exports.defaultSellerMyProductData = defaultSellerMyProductData;
var getProducts = function getProducts(data) {
  return (0, _request.default)({
    url: '/products/list',
    method: 'post',
    data: data
  });
};
exports.getProducts = getProducts;
var getProductById = function getProductById(id) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: 'get'
  });
};
exports.getProductById = getProductById;
var updateProduct = function updateProduct(id, data) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateProduct = updateProduct;
var deleteProduct = function deleteProduct(id) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: 'delete'
  });
};
exports.deleteProduct = deleteProduct;
var createProduct = function createProduct(data) {
  return (0, _request.default)({
    url: '/products/add',
    method: 'post',
    data: data
  });
};
exports.createProduct = createProduct;
var allProduct = function allProduct(data) {
  return (0, _request.default)({
    url: '/products/allproducts',
    method: 'post',
    data: data
  });
};
exports.allProduct = allProduct;
var productCount = function productCount(params) {
  return (0, _request.default)({
    url: '/products/count',
    method: 'get',
    params: params
  });
};
exports.productCount = productCount;
var updateProductCategories = function updateProductCategories(id, data) {
  return (0, _request.default)({
    url: "/products/categories/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateProductCategories = updateProductCategories;
var updateProductAttributes = function updateProductAttributes(id, data) {
  return (0, _request.default)({
    url: "/products/attributes/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateProductAttributes = updateProductAttributes;
var updateProductVariations = function updateProductVariations(id, data) {
  return (0, _request.default)({
    url: '/products-variations/manage',
    method: 'post',
    data: data
  });
};
exports.updateProductVariations = updateProductVariations;
var deleteProductVariation = function deleteProductVariation(id) {
  return (0, _request.default)({
    url: "/products-variations/".concat(id),
    method: 'delete'
  });
};
exports.deleteProductVariation = deleteProductVariation;
var updateProductImages = function updateProductImages(id, data) {
  return (0, _request.default)({
    url: "/products/images/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateProductImages = updateProductImages;
var deleteProductImages = function deleteProductImages(id) {
  return (0, _request.default)({
    url: "/products-images/".concat(id),
    method: 'delete'
  });
};
exports.deleteProductImages = deleteProductImages;
var updateProductInventories = function updateProductInventories(id, data) {
  return (0, _request.default)({
    url: "/products/inventories/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateProductInventories = updateProductInventories;
var uploadProductImages = function uploadProductImages(data) {
  return (0, _request.default)({
    url: '/products/images',
    method: 'post',
    data: data
  });
};
exports.uploadProductImages = uploadProductImages;