"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrdersSellers = exports.updateOrderForVrlIssue = exports.updateOrder = exports.sellerOrderCount = exports.recentProduct = exports.orderTotalPurchase = exports.orderExport = exports.orderCount = exports.getSellersOrderById = exports.getRecentOrder = exports.getRazorPaykeys = exports.getPreviousOrders = exports.getOutstandingInfo = exports.getOrders = exports.getOrderInfo = exports.getOrderCartInfo = exports.getOrderById = exports.getNotionalProfit = exports.getInCartProductInfo = exports.getCartCount = exports.getBuyersOrders = exports.getBuyersOrderById = exports.generateOrder = exports.fileTransferHeaders = exports.fetchTransaction = exports.deleteOrdersProduct = exports.deleteOrder = exports.defaultBuyerOrderData = exports.createOrder = exports.confirmOrder = exports.checkStatusAfterPayment = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultBuyerOrderData = {
  id: 0,
  orderReference: "",
  customerIP: "",
  status: "",
  orderedBy: "",
  comments: "",
  total: 0,
  deliveryOption: "Door to door",
  vrlDeliveryLocationId: 0,
  vrlId: 0,
  vrlOnly: false,
  nominatedTransportDetails: "",
  freightCharges: 0,
  shippingCharges: 0,
  shippingId: 0,
  taxedAmount: 0,
  discountAmount: 0,
  couponId: 0,
  grandTotal: 0,
  totalProducts: 0,
  totalQuantity: 0,
  orderTimestamp: "",
  lastLocation: "",
  platform: "",
  version: "",
  uuid: "",
  model: "",
  deviceId: "",
  device: "",
  OSVersion: "",
  uniqueID: "",
  manufacturer: "",
  appVersion: "",
  userId: 0,
  user: {
    id: null
  },
  orderId: 0,
  order: {
    id: null
  },
  countryId: 0,
  country: {
    id: null
  },
  stateId: 0,
  state: {
    id: null
  },
  cityId: 0,
  city: {
    id: null
  },
  pincodeId: 0,
  pincode: {
    id: null
  },
  coupon: {
    id: null
  }
};
exports.defaultBuyerOrderData = defaultBuyerOrderData;
var getOrders = function getOrders(params) {
  return (0, _request.default)({
    url: "/orders",
    method: "get",
    params: params
  });
};
exports.getOrders = getOrders;
var getPreviousOrders = function getPreviousOrders(data) {
  return (0, _request.default)({
    url: "/orders/old-list",
    method: "post",
    data: data
  });
};
exports.getPreviousOrders = getPreviousOrders;
var getOrderById = function getOrderById(id) {
  return (0, _request.default)({
    url: "/orders/info/".concat(id),
    method: "get"
  });
};
exports.getOrderById = getOrderById;
var getSellersOrderById = function getSellersOrderById(id) {
  return (0, _request.default)({
    url: "/orders-sellers/".concat(id),
    method: "get"
  });
};
exports.getSellersOrderById = getSellersOrderById;
var getCartCount = function getCartCount() {
  return (0, _request.default)({
    url: "/orders/getCartCount",
    method: "get"
  });
};
exports.getCartCount = getCartCount;
var getRecentOrder = function getRecentOrder() {
  return (0, _request.default)({
    url: "/orders-sellers/recent-order",
    method: "get"
  });
};
exports.getRecentOrder = getRecentOrder;
var updateOrder = function updateOrder(id, data) {
  return (0, _request.default)({
    url: "/orders/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateOrder = updateOrder;
var updateOrderForVrlIssue = function updateOrderForVrlIssue(id, data) {
  return (0, _request.default)({
    url: "/orders/update/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateOrderForVrlIssue = updateOrderForVrlIssue;
var updateOrdersSellers = function updateOrdersSellers(id, data) {
  return (0, _request.default)({
    url: "/orders-sellers/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateOrdersSellers = updateOrdersSellers;
var deleteOrder = function deleteOrder(id) {
  return (0, _request.default)({
    url: "/orders/".concat(id),
    method: "delete"
  });
};
exports.deleteOrder = deleteOrder;
var deleteOrdersProduct = function deleteOrdersProduct(id) {
  return (0, _request.default)({
    url: "/orders-products/".concat(id),
    method: "delete"
  });
};
exports.deleteOrdersProduct = deleteOrdersProduct;
var getBuyersOrders = function getBuyersOrders(params) {
  return (0, _request.default)({
    url: "/orders-sellers",
    method: "get",
    params: params
  });
};
exports.getBuyersOrders = getBuyersOrders;
var getBuyersOrderById = function getBuyersOrderById(id) {
  return (0, _request.default)({
    url: "/orders-sellers/".concat(id),
    method: "get"
  });
};
// export const createOrder = (data: any) =>
//   request({
//     url: '/orders/',
//     method: 'post',
//     data
//   })
exports.getBuyersOrderById = getBuyersOrderById;
var createOrder = function createOrder(data) {
  return (0, _request.default)({
    url: "/orders-products/add",
    method: "post",
    data: data
  });
};
exports.createOrder = createOrder;
var getNotionalProfit = function getNotionalProfit(data) {
  return (0, _request.default)({
    url: "/orders-products/notional-profit",
    method: "post",
    data: data
  });
};
exports.getNotionalProfit = getNotionalProfit;
var recentProduct = function recentProduct(data) {
  return (0, _request.default)({
    url: "/orders-products/recent-product",
    method: "post",
    data: data
  });
};
// export const orderCount = (params: any) =>
//   request({
//     url: '/orders/count',
//     method: 'get',
//     params
//   })
exports.recentProduct = recentProduct;
var orderCount = function orderCount(data) {
  return (0, _request.default)({
    url: "/orders/count",
    method: "post",
    data: data
  });
};
exports.orderCount = orderCount;
var sellerOrderCount = function sellerOrderCount(data) {
  return (0, _request.default)({
    url: "/orders-sellers/count",
    method: "post",
    data: data
  });
};
exports.sellerOrderCount = sellerOrderCount;
var orderTotalPurchase = function orderTotalPurchase(data) {
  return (0, _request.default)({
    url: "/orders/total-purchase",
    method: "post",
    data: data
  });
};
exports.orderTotalPurchase = orderTotalPurchase;
var orderExport = function orderExport(data) {
  return (0, _request.default)({
    url: "/orders/export",
    method: "post",
    data: data
  });
};
exports.orderExport = orderExport;
var confirmOrder = function confirmOrder() {
  var orderId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return (0, _request.default)({
    url: "/transactions/update-order/" + orderId,
    method: "get"
  });
};
exports.confirmOrder = confirmOrder;
var checkStatusAfterPayment = function checkStatusAfterPayment() {
  var orderId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var params = arguments.length > 1 ? arguments[1] : undefined;
  return (0, _request.default)({
    url: "/orders/" + orderId,
    method: "get",
    params: params
  });
};
exports.checkStatusAfterPayment = checkStatusAfterPayment;
var getRazorPaykeys = function getRazorPaykeys() {
  return (0, _request.default)({
    url: "/common/razor-pay-keys",
    method: "get"
  });
};
exports.getRazorPaykeys = getRazorPaykeys;
var generateOrder = function generateOrder() {
  var orderId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var params = arguments.length > 1 ? arguments[1] : undefined;
  return (0, _request.default)({
    url: "/transactions/generate-order/" + orderId,
    method: "get",
    params: params
  });
};
exports.generateOrder = generateOrder;
var fetchTransaction = function fetchTransaction(id) {
  return (0, _request.default)({
    url: "/transactions/fetch/".concat(id),
    method: "get"
  });
};
exports.fetchTransaction = fetchTransaction;
var getOrderInfo = function getOrderInfo(id) {
  return (0, _request.default)({
    url: "/orders/info/".concat(id),
    method: "get"
  });
};
exports.getOrderInfo = getOrderInfo;
var getOrderCartInfo = function getOrderCartInfo() {
  return (0, _request.default)({
    url: "/orders/cart-info",
    method: "get"
  });
};
exports.getOrderCartInfo = getOrderCartInfo;
var getInCartProductInfo = function getInCartProductInfo(id) {
  return (0, _request.default)({
    url: "/orders-products/variations/".concat(id),
    method: "get"
  });
};
exports.getInCartProductInfo = getInCartProductInfo;
var getOutstandingInfo = function getOutstandingInfo(data) {
  return (0, _request.default)({
    url: "/orders/outstanding",
    method: "post",
    data: data
  });
};
exports.getOutstandingInfo = getOutstandingInfo;