"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var subscriptionRouter = {
  path: '/subscription',
  component: _index.default,
  redirect: 'noredirect',
  name: 'Subscription',
  meta: {
    title: 'Subscription',
    // roles: ['superadmin'],
    icon: 'subscription'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/subscription/list.vue'));
      });
    },
    name: 'Wallet',
    meta: {
      title: 'subscription.title',
      icon: 'subscription',
      noCache: true
    }
  }]
};
var _default = subscriptionRouter;
exports.default = _default;