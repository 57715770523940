"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'linkedin': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_linkedin_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="4" clip-path="url(#svgicon_linkedin_a)"><path pid="1" d="M50.903 9.588A40.6 40.6 0 0010.31 50.181c0 20.577 15.319 37.583 35.94 40.329a37.754 37.754 0 004.653.264 40.593 40.593 0 100-81.186zM39.11 70.953h-9.889V41.21h9.889zm-4.943-33.8H34.1a5.118 5.118 0 01-5.463-5.138c0-2.919 2.211-5.139 5.593-5.139a5.138 5.138 0 015.528 5.139c0 2.854-2.146 5.138-5.593 5.138zm40.59 33.8H64.87V55.041c0-4-1.431-6.726-5.008-6.726a5.412 5.412 0 00-5.072 3.615 6.77 6.77 0 00-.325 2.413v16.61h-9.887s.129-26.953 0-29.743h9.886v4.211a9.815 9.815 0 018.909-4.909c6.5 0 11.381 4.251 11.381 13.386zm0 0" _fill="#3c9900"/></g>'
  }
});