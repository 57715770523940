"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "footerV1-wrap section-gap",
    staticStyle: {
      "padding-bottom": "0px"
    }
  }, [_c("div", {
    staticClass: "footer-top",
    attrs: {
      "grid-list-xl": "",
      "py-0": "",
      "pb-12": ""
    }
  }, [_c("el-row", {
    staticStyle: {
      "flex-wrap": "wrap"
    },
    attrs: {
      type: "flex",
      justify: "center",
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      xs: 10,
      sm: 9,
      md: 5,
      lg: 5,
      xl: 5
    }
  }, [_c("div", {
    staticClass: "about-wrap"
  }, [_c("h4", {}, [_vm._v("Corporate Office")]), _c("span", {}, [_vm._v("TradeBridge")]), _c("p", {
    staticClass: "white--text"
  }, [_vm._v(" Arihant Aura, Floor No. 18, Plot No 13/1, TTC Industrial Area, Thane Belapur Road, Navi Mumbai – 400706, Maharashtra, India ")])]), _c("div", {
    staticClass: "my-3"
  }, [_c("h6", {
    staticClass: "white--text mb-6"
  }, [_vm._v("Follow Us")]), _c("div", {
    staticClass: "socials-follow"
  }, _vm._l(_vm.socialFollow, function (list, key) {
    return _c("a", {
      key: key,
      staticStyle: {
        display: "flex",
        "align-items": "center",
        gap: "5px"
      },
      attrs: {
        href: list.path,
        target: "_blank"
      }
    }, [_c("img", {
      staticClass: "white--text",
      attrs: {
        src: list.menuicon,
        width: "20"
      }
    })]);
  }), 0)])]), _c("el-col", {
    attrs: {
      xs: 10,
      sm: 9,
      md: 5,
      lg: 5,
      xl: 5
    }
  }, [_c("h4", {
    staticClass: "white--text mb-4"
  }, [_vm._v("Information")]), _c("div", {
    staticClass: "abt-menu"
  }, [_c("ul", {
    staticClass: "el-menu"
  }, _vm._l(_vm.about, function (list, key) {
    return _c("li", {
      key: key
    }, [_c("a", {
      attrs: {
        href: list.path,
        target: "_blank"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(list.menuItem)) + " ")])]);
  }), 0)])]), _c("el-col", {
    attrs: {
      xs: 10,
      sm: 9,
      md: 5,
      lg: 5,
      xl: 5
    }
  }, [_c("h4", {
    staticClass: "white--text mb-4"
  }, [_vm._v("Customer")]), _c("div", {
    staticClass: "features-menu"
  }, [_c("ul", {
    staticClass: "el-menu"
  }, _vm._l(this.session, function (list, key) {
    return _c("li", {
      key: key
    }, [_c("a", {
      attrs: {
        href: list.path
      }
    }, [_vm._v(_vm._s(_vm.$t(list.menuItem)))])]);
  }), 0)])]), _c("el-col", {
    attrs: {
      xs: 10,
      sm: 9,
      md: 5,
      lg: 5,
      xl: 5
    }
  }, [_c("div", {
    staticClass: "policy-menu"
  }, [_c("h4", {
    staticClass: "white--text mb-4"
  }, [_vm._v("Social")]), _c("ul", {
    staticClass: "el-menu"
  }, _vm._l(_vm.socialLinks, function (list, key) {
    return _c("li", {
      key: key
    }, [_c("a", {
      staticStyle: {
        display: "flex",
        "align-items": "center",
        gap: "5px"
      },
      attrs: {
        href: list.path,
        target: "_blank"
      }
    }, [_c("img", {
      staticClass: "white--text",
      attrs: {
        src: list.menuicon,
        width: "20"
      }
    }), _vm._v(" " + _vm._s(_vm.$t(list.menuItem)))])]);
  }), 0)])])], 1)], 1), _c("div", {
    staticClass: "py-2",
    staticStyle: {
      background: "#3c9900"
    }
  }, [_c("div", {
    staticClass: "footer-bottom"
  }, [_c("div", {
    staticClass: "footer-bottom-content px-3"
  }, [_c("el-row", {
    attrs: {
      justify: "center",
      align: "center"
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      cols: "12"
    }
  }, [_c("small", {
    staticClass: "white--text"
  }, [_vm._v("Orchestrade Technologies Pvt Ltd. 2024. All Rights Reversed. Powered by "), _c("a", {
    staticClass: "white--text",
    staticStyle: {
      "font-size": "inherit"
    },
    attrs: {
      href: "https://innovuratech.com/",
      target: "_blank"
    }
  }, [_vm._v("Innovura Technologies")])])])], 1)], 1)])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;