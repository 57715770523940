"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'TotalSaleKg': {
    width: 250,
    height: 250,
    viewBox: '0 0 250 250',
    data: '<defs><clipPath id="svgicon_TotalSaleKg_a"><path pid="0" d="M0 0h250v250H0z"/></clipPath></defs><g data-name="Total Sale Kg"><g data-name="Layer 2" clip-path="url(#svgicon_TotalSaleKg_a)"><path pid="1" data-name="Path 1532" d="M172.817 86.76a47.8 47.8 0 0047.8-47.8 9.56 9.56 0 00-9.56-9.559H38.978a9.56 9.56 0 00-9.56 9.56 47.8 47.8 0 0047.8 47.8h9.56v19.12h-28.68a9.56 9.56 0 00-9.56 7.648l-19.12 95.6a9.648 9.648 0 009.56 11.472h172.079a9.916 9.916 0 009.56-11.472l-19.12-95.6a9.56 9.56 0 00-9.56-7.648h-28.68V86.76zm-66.919 0h38.24v19.12h-38.24zm19.119 105.159a28.68 28.68 0 1128.68-28.68 28.68 28.68 0 01-28.68 28.68z" _fill="#b5aeae"/></g></g>'
  }
});