"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'product': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_product_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="Products – 2"><g data-name="Group 3133" _fill="#565656" clip-path="url(#svgicon_product_a)"><path pid="1" data-name="Path 1419" d="M44.752 16.237h10.143v12.229H44.752z"/><path pid="2" data-name="Path 12357" d="M44.929 33.173h10.143v9.293H44.929z"/><path pid="3" data-name="Path 1421" d="M18.432 33.173v22.449a7.115 7.115 0 013.919 2.18l7.77 8.434.061.063v-1.716a6.553 6.553 0 016.546-6.546h10.881c1.351.153 4.928-1.894 6.162-2.385a18.645 18.645 0 0116.354.264l3.132 1.59a6.753 6.753 0 016.365-4.862h1.86V33.173h-21.88V41.8a2.353 2.353 0 01-2.353 2.353H42.398a2.353 2.353 0 01-2.353-2.353v-8.627z"/><path pid="4" data-name="Path 1422" d="M59.601 28.466H81.48v-8.965a3.027 3.027 0 00-2.713-3.264H59.601z"/><path pid="5" data-name="Path 1423" d="M79.625 57.353a2.2 2.2 0 00-1.992 2.35v21.038a2.2 2.2 0 001.992 2.35h3.718a2.2 2.2 0 001.992-2.35V59.703a2.2 2.2 0 00-1.992-2.35z"/><path pid="6" data-name="Path 1424" d="M40.045 16.237H20.686a2.516 2.516 0 00-2.254 2.713v9.516h21.613z"/><path pid="7" data-name="Path 1425" d="M18.89 60.99c-2.079-2.079-5.619.639-3.641 3.057l10.6 12.851a18.815 18.815 0 0014.562 6.865h11.99a57.214 57.214 0 0013.237-1.554l7.29-1.743V62.614l-4.929-2.5a13.962 13.962 0 00-12.243-.195l-4.189 1.95a9.443 9.443 0 01-3.954.874H36.729a1.841 1.841 0 00-1.839 1.839v3.565h21.136a2.353 2.353 0 110 4.707H34.483a10.825 10.825 0 01-7.823-3.43z"/></g></g>'
  }
});