"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {}, [_c("div", {
    staticClass: "source"
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24,
      md: 12
    }
  }, [_c("br"), _c("el-card", {
    attrs: {
      shadow: ""
    }
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Pincode",
      prop: "pincode"
    }
  }, [_c("el-input", {
    attrs: {
      name: "pincode",
      disabled: "",
      required: "",
      placeholder: "Pincode"
    },
    model: {
      value: _vm.pincode,
      callback: function callback($$v) {
        _vm.pincode = $$v;
      },
      expression: "pincode"
    }
  })], 1), _c("el-radio-group", {
    model: {
      value: _vm.formData.deliveryOption,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "deliveryOption", $$v);
      },
      expression: "formData.deliveryOption"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "deliverytype-card",
    attrs: {
      span: 24,
      md: 12
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Nominated transport",
      value: "Nominated transport",
      disabled: _vm.formData.vrlOnly
    }
  }, [_vm._v(" Nominated Transport ")])], 1)], 1), _c("el-col", {
    staticClass: "deliverytype-card",
    attrs: {
      span: 24,
      md: 12
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "VRL",
      value: "VRL"
    }
  }, [_vm._v(" Warehouse Delivery ")])], 1)], 1), _c("el-col", {
    staticClass: "deliverytype-card",
    attrs: {
      span: 24,
      md: 12
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Door to door",
      value: "Door to door",
      disabled: _vm.formData.vrlOnly
    }
  }, [_vm._v(" Door to Door delivery ")])], 1)], 1), _c("el-col", {
    staticClass: "deliverytype-card",
    attrs: {
      span: 24,
      md: 12
    }
  }, [_c("el-card", {
    attrs: {
      shadow: "hover"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Any other",
      value: "Any Other",
      disabled: _vm.formData.vrlOnly
    }
  }, [_vm._v(" Any other / Self ")])], 1)], 1)], 1)], 1), _c("br"), _c("br"), _vm.formData.deliveryOption === "VRL" ? _c("el-form-item", {
    attrs: {
      label: "Visibility",
      prop: "deliveryOption"
    }
  }, [_c("el-select", {
    staticClass: "filter-item",
    attrs: {
      placeholder: "Select Warehouse Delivery",
      filterable: "",
      clearable: ""
    },
    model: {
      value: _vm.formData.vrlId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "vrlId", $$v);
      },
      expression: "formData.vrlId"
    }
  }, _vm._l(_vm.vrlLocations, function (item) {
    return _c("el-tooltip", {
      key: item.id,
      attrs: {
        effect: "dark",
        content: item.name + " - " + item.address,
        placement: "bottom-end"
      }
    }, [_c("el-option", {
      attrs: {
        label: (item.name + " - " + item.address).substring(0, 27) + "...",
        value: item.id
      }
    })], 1);
  }), 1)], 1) : _vm._e(), _vm.formData.deliveryOption === "Nominated transport" ? _c("el-form-item", [_c("el-input", {
    attrs: {
      autosize: {
        minRows: 2,
        maxRows: 4
      },
      type: "textarea",
      placeholder: "Please input transport details"
    },
    model: {
      value: _vm.formData.nominatedTransportDetails,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "nominatedTransportDetails", $$v);
      },
      expression: "formData.nominatedTransportDetails"
    }
  })], 1) : _vm._e(), _c("el-form-item", [_c("el-button", {
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Go Back ")]), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1)], 1)], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;