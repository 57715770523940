// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.el-upload {\n  text-align: left;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.el-button {\n  border-radius: 4px;\n  margin-right: auto;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#F2F2F2",
	"menuText": "black",
	"menuActiveText": "#409eff"
};
module.exports = exports;
