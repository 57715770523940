"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var privacyPolicyRouter = {
  path: '/privacy-policy',
  component: _index.default,
  // redirect: 'noredirect',
  name: 'privacyPolicyManagement.title',
  meta: {
    title: 'privacyPolicyManagement.title',
    icon: 'tnc'
  },
  children: [{
    path: '/privacy-policy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/privacy-policy/list.vue'));
      });
    },
    name: 'Privacy Policy',
    meta: {
      title: 'privacyPolicyManagement.title',
      noCache: true,
      icon: 'tnc'
    }
  }]
  // path: '/privacy-policy',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'https://ashapura.s3.ap-south-1.amazonaws.com/api/common/e11198df-9364-4cae-b231-3756eaae1e16.pdf',
  //       meta: { title: 'privacyPolicyManagement.title', icon: 'tnc' }
  //     }
  //   ]
};
var _default = privacyPolicyRouter;
exports.default = _default;