"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 24,
      md: 16
    }
  }, [_c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 20
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-col", {
      key: status.status,
      staticClass: "card-panel-col",
      attrs: {
        xs: 8,
        sm: 8,
        lg: 8
      }
    }, [_c("router-link", {
      attrs: {
        to: "/order/list?status=" + status.status
      }
    }, [_c("div", {
      staticClass: "card-panel"
    }, [status.status === "Order Placed" ? _c("div", {
      staticClass: "card-panel-icon-wrapper icon-shopping"
    }, [_c("svg-icon", {
      staticClass: "card-panel-icon",
      attrs: {
        name: "shopping"
      }
    })], 1) : _vm._e(), status.status === "Order Dispatched" ? _c("div", {
      staticClass: "card-panel-icon-wrapper icon-DispatchedOrder"
    }, [_c("svg-icon", {
      staticClass: "card-panel-icon",
      attrs: {
        name: "DispatchedOrder"
      }
    })], 1) : _vm._e(), status.status === "Order Delivered" ? _c("div", {
      staticClass: "card-panel-icon-wrapper icon-OutforDelivery"
    }, [_c("svg-icon", {
      staticClass: "card-panel-icon",
      attrs: {
        name: "OutforDelivery"
      }
    })], 1) : _vm._e(), _c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "card-panel-text"
    }, [_vm._v(" " + _vm._s(status.status) + " ")]), _c("count-to", {
      staticClass: "card-panel-num",
      attrs: {
        "start-val": 0,
        "end-val": status.count,
        duration: 2600
      }
    })], 1)])])], 1);
  }), 1)], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;