"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateVRL = exports.getVrlLocationByStateId = exports.getVrlLocation = exports.getVRLs = exports.getVRLById = exports.deleteVRL = exports.defaultVRLData = exports.createVRL = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultVRLData = {
  id: 0,
  enabled: true,
  cityId: undefined,
  name: '',
  city: {
    id: undefined
  }
};
exports.defaultVRLData = defaultVRLData;
var getVRLs = function getVRLs(params) {
  return (0, _request.default)({
    url: '/vrl-delivery-locations',
    method: 'get',
    params: params
  });
};
exports.getVRLs = getVRLs;
var getVRLById = function getVRLById(id) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'get'
  });
};
exports.getVRLById = getVRLById;
var getVrlLocation = function getVrlLocation(id) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations/listByState/".concat(id),
    method: 'get'
  });
};
exports.getVrlLocation = getVrlLocation;
var getVrlLocationByStateId = function getVrlLocationByStateId(id) {
  return (0, _request.default)({
    url: "/vrls/listByState/".concat(id),
    method: 'get'
  });
};
exports.getVrlLocationByStateId = getVrlLocationByStateId;
var updateVRL = function updateVRL(id, data) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateVRL = updateVRL;
var deleteVRL = function deleteVRL(id) {
  return (0, _request.default)({
    url: "/vrl-delivery-locations/".concat(id),
    method: 'delete'
  });
};
exports.deleteVRL = deleteVRL;
var createVRL = function createVRL(data) {
  return (0, _request.default)({
    url: '/vrl-delivery-locations/',
    method: 'post',
    data: data
  });
};
exports.createVRL = createVRL;