"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-card", {
    staticClass: "box-card",
    staticStyle: {
      margin: "0"
    },
    attrs: {
      "body-style": "{\n        padding:0;\n        padding-right:0;\n        padding-top:20px;\n        padding-left:0;\n        padding-bottom:20px;\n    }"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "0 10px"
    }
  }, [_c("el-row", {
    attrs: {
      align: "center"
    }
  }, [_c("el-col", {
    attrs: {
      xs: 8,
      sm: 4,
      lg: 16
    }
  }, [_c("b", [_vm._v("Items Ordered")])]), _c("el-col", {
    attrs: {
      xs: 8,
      sm: 4,
      lg: 4
    }
  }, [_c("b", [_vm._v("Quantity")])]), _c("el-col", {
    attrs: {
      xs: 8,
      sm: 4,
      lg: 4
    }
  }, [_c("b", [_vm._v("Price")])])], 1), _vm._l(_vm.sellerOrder.ordersProduct, function (elem) {
    return _c("el-row", {
      key: elem.id,
      attrs: {
        offset: _vm.index > 0 ? 5 : 0
      }
    }, [_c("el-col", {
      attrs: {
        xs: 8,
        sm: 4,
        lg: 16
      }
    }, [_c("small", [_vm._v(_vm._s(elem.product.name))])]), _c("el-col", {
      attrs: {
        xs: 8,
        sm: 4,
        lg: 4
      }
    }, [_c("small", [_vm._v(" " + _vm._s(elem.quantity))])]), _c("el-col", {
      attrs: {
        xs: 8,
        sm: 4,
        lg: 4
      }
    }, [_c("small", [_vm._v(" " + _vm._s(elem.salePrice > 0 ? elem.salePrice : elem.unitPrice))])])], 1);
  })], 2), _c("div", {
    staticClass: "bg-light"
  }, [_c("div", {
    staticStyle: {
      padding: "15px"
    }
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      sm: 4,
      lg: 4
    }
  }, [_c("small", [_vm._v("Sub Total")])]), _c("el-col", {
    attrs: {
      span: 12,
      sm: 4,
      lg: 4
    }
  }, [_c("small", [_vm._v(_vm._s(_vm.sellerOrder.total))])])], 1), _c("el-row", {
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      sm: 4,
      lg: 4
    }
  }, [_c("small", [_vm._v("Freight Charges")])]), _c("el-col", {
    attrs: {
      span: 12,
      sm: 4,
      lg: 4
    }
  }, [_c("small", [_vm._v(_vm._s(_vm.sellerOrder.freightCharges))])])], 1), _c("el-row", {
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      sm: 4,
      lg: 4
    }
  }, [_c("small", [_vm._v("Tax(GST)")])]), _c("el-col", {
    attrs: {
      span: 12,
      sm: 4,
      lg: 4
    }
  }, [_c("small", [_vm._v(_vm._s(_vm.sellerOrder.taxedAmount))])])], 1), _c("el-row", {
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      sm: 4,
      lg: 4
    }
  }, [_c("b", [_vm._v("Grand Total")])]), _c("el-col", {
    attrs: {
      span: 12,
      sm: 4,
      lg: 4
    }
  }, [_c("b", [_vm._v(_vm._s(_vm.sellerOrder.grandTotal))])])], 1)], 1)]), _c("div", {
    staticStyle: {
      padding: "0 10px"
    }
  }, [_c("order-addressl-info-tab", {
    attrs: {
      data: _vm.orderInfoData
    }
  }), _vm.sellerOrder.status !== "Order Placed" ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("small", [_c("b", [_vm._v("Documents")])]), _c("p", [_vm.sellerOrder.lrNumber ? _c("small", [_vm._v("LR Number: " + _vm._s(_vm.sellerOrder.lrNumber))]) : _vm._e(), _vm._v("         ")])]), _c("el-col", {
    attrs: {
      xs: 4,
      sm: 4,
      lg: 4
    }
  }, [_vm.sellerOrder.lrReceipt ? _c("a", {
    attrs: {
      href: _vm.sellerOrder.lrReceipt,
      target: "_blank"
    }
  }, [_c("img", {
    attrs: {
      src: "/img/social/attachment.svg",
      width: "30"
    }
  }), _c("small", [_vm._v("LR")])]) : _vm._e()]), _c("el-col", {
    attrs: {
      xs: 4,
      sm: 4,
      lg: 4
    }
  }, [_vm.sellerOrder.invoiceAttachment ? _c("a", {
    attrs: {
      href: _vm.sellerOrder.invoiceAttachment,
      target: "_blank"
    }
  }, [_c("img", {
    attrs: {
      src: "/img/social/attachment.svg",
      width: "30"
    }
  }), _c("small", [_vm._v("Invoice")])]) : _vm._e()]), _c("el-col", {
    attrs: {
      xs: 4,
      sm: 4,
      lg: 4
    }
  }, [_vm.sellerOrder.qualityCoa ? _c("a", {
    attrs: {
      href: _vm.sellerOrder.qualityCoa,
      target: "_blank"
    }
  }, [_c("img", {
    attrs: {
      src: "/img/social/attachment.svg",
      width: "30"
    }
  }), _c("small", [_vm._v("Quality Coa")])]) : _vm._e()])], 1) : _vm._e()], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;