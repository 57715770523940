"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verifyForgotPaswordOtp = exports.validate = exports.userCount = exports.updateUserOrganization = exports.updateUserDocument = exports.updateUser = exports.updateSelleruser = exports.surveyformStatus = exports.submitSurveyFormData = exports.resetForgotPasword = exports.registerUser = exports.register = exports.logout = exports.login = exports.getUsersWallets = exports.getUsers = exports.getUserSurveyFormStatus = exports.getUserSurveyFormData = exports.getUserRoles = exports.getUserDocumentsVerifyKyc = exports.getUserDocumentsNames = exports.getUserCredit = exports.getUserCards = exports.getUserById = exports.getUserByEmail = exports.getSellersDocumentsNames = exports.getRequestOtp = exports.getNotificationData = exports.getContacts = exports.forgotPasword = exports.deleteUser = exports.defaultUserWalletData = exports.defaultUserData = exports.addFundsToUserWaller = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultUserData = {
  id: 0,
  enabled: true,
  fullName: "",
  emailAddress: "",
  mobileNumber: "",
  avatar: "",
  organizationId: undefined,
  broker: {
    id: null
  },
  organization: {
    id: null,
    name: ""
  },
  usersRole: {
    id: null,
    name: ""
  },
  brokerName: "",
  // fssaiNumber: '',
  // gstNumber: '',
  // panNumber: '',
  usersRoleId: undefined,
  brokerId: undefined,
  // countryCode: '',
  // preferredLanguage: '',
  // isEmailVerified: undefined,
  // isMobileVerified: undefined,
  // gender: '',
  password: "",
  // referralCode: '',
  // canUseReferralCode: undefined,
  // lastLocation: '',
  // pushId: '',
  // platform: '',
  // version: '',
  // uuid: '',
  // model: '',
  // deviceId: '',
  // device: '',
  // OSVersion: '',
  // uniqueID: '',
  // manufacturer: '',
  // appVersion: '',
  referalCode: ""
};
exports.defaultUserData = defaultUserData;
var defaultUserWalletData = {
  id: 0,
  enabled: false,
  amount: 0.0,
  action: "credit",
  userId: null,
  orderId: null,
  user: {
    id: null
  },
  order: {
    id: null
  }
};
exports.defaultUserWalletData = defaultUserWalletData;
var getUsers = function getUsers(params) {
  return (0, _request.default)({
    url: "/users",
    method: "get",
    params: params
  });
};
exports.getUsers = getUsers;
var getUserById = function getUserById(id) {
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: "get"
  });
};
exports.getUserById = getUserById;
var getContacts = function getContacts(params) {
  return (0, _request.default)({
    url: "/contacts",
    method: "get",
    params: params
  });
};
exports.getContacts = getContacts;
var getUserCredit = function getUserCredit() {
  return (0, _request.default)({
    url: "/users/check-credit",
    method: "get"
  });
};
exports.getUserCredit = getUserCredit;
var getUserDocumentsVerifyKyc = function getUserDocumentsVerifyKyc() {
  return (0, _request.default)({
    url: "/users-documents/verify-kyc",
    method: "get"
  });
};
exports.getUserDocumentsVerifyKyc = getUserDocumentsVerifyKyc;
var getUserDocumentsNames = function getUserDocumentsNames() {
  return (0, _request.default)({
    url: "/users-documents/list-names",
    method: "get"
  });
};
exports.getUserDocumentsNames = getUserDocumentsNames;
var getSellersDocumentsNames = function getSellersDocumentsNames() {
  return (0, _request.default)({
    url: "/sellers-documents/list-names",
    method: "get"
  });
};
exports.getSellersDocumentsNames = getSellersDocumentsNames;
var getUserByEmail = function getUserByEmail(adminemail) {
  return (0, _request.default)({
    url: "/users/".concat(adminemail),
    method: "get"
  });
};
exports.getUserByEmail = getUserByEmail;
var getUserSurveyFormStatus = function getUserSurveyFormStatus() {
  return (0, _request.default)({
    url: "/surveyform/surveyformStatus",
    method: "get"
  });
};
exports.getUserSurveyFormStatus = getUserSurveyFormStatus;
var getUserSurveyFormData = function getUserSurveyFormData() {
  return (0, _request.default)({
    url: "/surveyform/fetch",
    method: "get"
  });
};
exports.getUserSurveyFormData = getUserSurveyFormData;
var surveyformStatus = function surveyformStatus() {
  return (0, _request.default)({
    url: "/surveyform/surveyformStatus",
    method: "get"
  });
};
exports.surveyformStatus = surveyformStatus;
var submitSurveyFormData = function submitSurveyFormData(data) {
  return (0, _request.default)({
    url: "/users-questions-answers/add",
    method: "post",
    data: data
  });
};
exports.submitSurveyFormData = submitSurveyFormData;
var getNotificationData = function getNotificationData() {
  return (0, _request.default)({
    url: "/notification-user-mapping/getNotifications",
    method: "get"
  });
};
exports.getNotificationData = getNotificationData;
var updateUserDocument = function updateUserDocument(data) {
  return (0, _request.default)({
    url: "/users-documents",
    method: "post",
    data: data
  });
};
exports.updateUserDocument = updateUserDocument;
var updateUser = function updateUser(id, data) {
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateUser = updateUser;
var updateUserOrganization = function updateUserOrganization(data) {
  return (0, _request.default)({
    url: "/users/manage-organization",
    method: "post",
    data: data
  });
};
exports.updateUserOrganization = updateUserOrganization;
var updateSelleruser = function updateSelleruser(data) {
  return (0, _request.default)({
    url: "/users/manage",
    method: "post",
    data: data
  });
};
exports.updateSelleruser = updateSelleruser;
var getRequestOtp = function getRequestOtp(data) {
  return (0, _request.default)({
    url: "/users/requestOtp",
    method: "post",
    data: data
  });
};
exports.getRequestOtp = getRequestOtp;
var forgotPasword = function forgotPasword(data) {
  return (0, _request.default)({
    url: "/users/forgot-password",
    method: "post",
    data: data
  });
};
exports.forgotPasword = forgotPasword;
var verifyForgotPaswordOtp = function verifyForgotPaswordOtp(data) {
  return (0, _request.default)({
    url: "/users/verifyForgotPasswordOtp",
    method: "post",
    data: data
  });
};
exports.verifyForgotPaswordOtp = verifyForgotPaswordOtp;
var resetForgotPasword = function resetForgotPasword(data) {
  return (0, _request.default)({
    url: "/users/reset-password",
    method: "post",
    data: data
  });
};
exports.resetForgotPasword = resetForgotPasword;
var registerUser = function registerUser(data) {
  return (0, _request.default)({
    url: "/users/verifyOtp",
    method: "post",
    data: data
  });
};
exports.registerUser = registerUser;
var deleteUser = function deleteUser(adminname) {
  return (0, _request.default)({
    url: "/users/".concat(adminname),
    method: "delete"
  });
};
exports.deleteUser = deleteUser;
var login = function login(data) {
  return (0, _request.default)({
    url: "/users/login",
    method: "post",
    data: data
  });
};
exports.login = login;
var logout = function logout() {
  return (0, _request.default)({
    url: "/users/logout",
    method: "post"
  });
};
exports.logout = logout;
var register = function register(data) {
  return (0, _request.default)({
    url: "/users",
    method: "post",
    data: data
  });
};
exports.register = register;
var validate = function validate(data) {
  return (0, _request.default)({
    url: "/users/validate",
    method: "post",
    data: data
  });
};
exports.validate = validate;
var userCount = function userCount(params) {
  return (0, _request.default)({
    url: "/users/count",
    method: "get",
    params: params
  });
};
exports.userCount = userCount;
var getUserCards = function getUserCards() {
  return (0, _request.default)({
    url: "/users-cards",
    method: "get"
  });
};
exports.getUserCards = getUserCards;
var getUserRoles = function getUserRoles(params) {
  return (0, _request.default)({
    url: "/users-roles",
    method: "get",
    params: params
  });
};
exports.getUserRoles = getUserRoles;
var getUsersWallets = function getUsersWallets(params) {
  return (0, _request.default)({
    url: "/wallet-transactions",
    method: "get",
    params: params
  });
};
exports.getUsersWallets = getUsersWallets;
var addFundsToUserWaller = function addFundsToUserWaller(data) {
  return (0, _request.default)({
    url: "/wallet-transactions",
    method: "post",
    data: data
  });
};
exports.addFundsToUserWaller = addFundsToUserWaller;