"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSellersOrder = exports.updateOrder = exports.topProducts = exports.sellerOrderCount = exports.orderExport = exports.orderCount = exports.getSellersOrders = exports.getSellersOrderInfo = exports.getSellerOrderStatsById = exports.getSellerOrderInvoiceById = exports.getSellerOrderById = exports.getOrders = exports.getOrderInfo = exports.getOrderById = exports.fileTransferHeaders = exports.deleteOrder = exports.defaultOrderData = exports.createSellersOrder = exports.createOrder = exports.confirmOrder = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultOrderData = {
  id: 0,
  orderReference: '',
  customerIP: '',
  status: '',
  orderedBy: '',
  comments: '',
  total: 0,
  shippingCharges: 0,
  shippingId: 0,
  taxedAmount: 0,
  discountAmount: 0,
  couponId: 0,
  grandTotal: 0,
  totalProducts: 0,
  totalQuantity: 0,
  orderTimestamp: '',
  lastLocation: '',
  platform: '',
  version: '',
  uuid: '',
  model: '',
  deviceId: '',
  device: '',
  OSVersion: '',
  uniqueID: '',
  manufacturer: '',
  appVersion: '',
  userId: 0,
  user: {
    id: null
  },
  orderId: 0,
  order: {
    id: null
  },
  countryId: 0,
  country: {
    id: null
  },
  stateId: 0,
  state: {
    id: null
  },
  cityId: 0,
  city: {
    id: null
  },
  pincodeId: 0,
  pincode: {
    id: null
  }
};
exports.defaultOrderData = defaultOrderData;
var getOrders = function getOrders(params) {
  return (0, _request.default)({
    url: '/orders',
    method: 'get',
    params: params
  });
};
exports.getOrders = getOrders;
var getSellersOrders = function getSellersOrders(params) {
  return (0, _request.default)({
    url: '/orders-sellers',
    method: 'get',
    params: params
  });
};
exports.getSellersOrders = getSellersOrders;
var getOrderById = function getOrderById(id) {
  return (0, _request.default)({
    url: "/orders/info/".concat(id),
    method: 'get'
  });
};
exports.getOrderById = getOrderById;
var getSellerOrderById = function getSellerOrderById(id) {
  return (0, _request.default)({
    url: "/orders-sellers/".concat(id),
    method: 'get'
  });
};
exports.getSellerOrderById = getSellerOrderById;
var updateOrder = function updateOrder(id, data) {
  return (0, _request.default)({
    url: "/orders/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateOrder = updateOrder;
var updateSellersOrder = function updateSellersOrder(id, data) {
  return (0, _request.default)({
    url: "/orders-sellers/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateSellersOrder = updateSellersOrder;
var deleteOrder = function deleteOrder(id) {
  return (0, _request.default)({
    url: "/orders/".concat(id),
    method: 'delete'
  });
};
exports.deleteOrder = deleteOrder;
var getSellerOrderInvoiceById = function getSellerOrderInvoiceById(id) {
  return (0, _request.default)({
    url: "/orders-sellers/pdf/".concat(id, "/1"),
    method: 'get'
  });
};
exports.getSellerOrderInvoiceById = getSellerOrderInvoiceById;
var createOrder = function createOrder(data) {
  return (0, _request.default)({
    url: '/orders/',
    method: 'post',
    data: data
  });
};
exports.createOrder = createOrder;
var createSellersOrder = function createSellersOrder(data) {
  return (0, _request.default)({
    url: '/orders-sellers/',
    method: 'post',
    data: data
  });
};
exports.createSellersOrder = createSellersOrder;
var orderCount = function orderCount(params) {
  return (0, _request.default)({
    url: '/orders/count',
    method: 'get',
    params: params
  });
};
exports.orderCount = orderCount;
var orderExport = function orderExport(data) {
  return (0, _request.default)({
    url: '/orders/export',
    method: 'post',
    data: data
  });
};
exports.orderExport = orderExport;
var confirmOrder = function confirmOrder() {
  var orderId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return (0, _request.default)({
    url: '/transactions/update-order/' + orderId,
    method: 'get'
  });
};
exports.confirmOrder = confirmOrder;
var getOrderInfo = function getOrderInfo(id) {
  return (0, _request.default)({
    url: "/orders/info/".concat(id),
    method: 'get'
  });
};
exports.getOrderInfo = getOrderInfo;
var getSellersOrderInfo = function getSellersOrderInfo(id) {
  return (0, _request.default)({
    url: "/orders-sellers/".concat(id),
    method: 'get'
  });
};
exports.getSellersOrderInfo = getSellersOrderInfo;
var getSellerOrderStatsById = function getSellerOrderStatsById(id, params) {
  return (0, _request.default)({
    url: "/orders-sellers/stats/".concat(id),
    method: 'get',
    params: params
  });
};
exports.getSellerOrderStatsById = getSellerOrderStatsById;
var sellerOrderCount = function sellerOrderCount(data) {
  return (0, _request.default)({
    url: '/orders-sellers/count',
    method: 'post',
    data: data
  });
};
exports.sellerOrderCount = sellerOrderCount;
var topProducts = function topProducts(params) {
  return (0, _request.default)({
    url: '/orders-products/top-product',
    method: 'get',
    params: params
  });
};
exports.topProducts = topProducts;