"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-row", {
    staticClass: "flex-column",
    attrs: {
      gutter: 24,
      type: "flex",
      align: "center"
    }
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_vm.invoice ? _c("a", {
    attrs: {
      href: _vm.invoice,
      target: "_blank"
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    staticStyle: {
      "margin-left": "80%"
    },
    attrs: {
      type: "primary"
    }
  }, [_vm._v(" Download Invoice ")])], 1) : _vm._e()])], 1), _c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-row", {
    staticClass: "flex-column",
    attrs: {
      gutter: 20,
      type: "flex",
      align: "center"
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Status",
      required: ""
    }
  }, [_c("el-select", {
    attrs: {
      name: "status",
      placeholder: "Select"
    },
    model: {
      value: _vm.formData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  }, _vm._l(_vm.orderOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        required: "",
        label: item.label,
        value: item.key
      }
    });
  }), 1)], 1), _vm.formData.status === "Order Dispatched" ? [_c("el-form-item", {
    attrs: {
      label: "LR Receipt",
      prop: "lrReceipt"
    }
  }, [_c("file-upload", {
    attrs: {
      "file-list": _vm.lrReceipt,
      url: "/orders/upload"
    },
    on: {
      change: function change($event) {
        _vm.formData.lrReceipt = $event.response;
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Invoice Attachment",
      prop: "invoiceAttachment"
    }
  }, [_c("file-upload", {
    attrs: {
      "file-list": _vm.invoiceAttachment,
      url: "/orders/upload"
    },
    on: {
      change: function change($event) {
        _vm.formData.invoiceAttachment = $event.response;
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Quality Coa",
      prop: "qualityCoa"
    }
  }, [_c("file-upload", {
    attrs: {
      "file-list": _vm.qualityCoa,
      url: "/orders/upload"
    },
    on: {
      change: function change($event) {
        _vm.formData.qualityCoa = $event.response;
      }
    }
  })], 1)] : _vm._e(), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 2)], 1)], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;