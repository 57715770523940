"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {}, [_c("div", {
    staticClass: "source"
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 12,
      xs: 24
    }
  }, [_c("br"), _c("el-card", {
    attrs: {
      shadow: ""
    }
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("p", [_c("b", [_vm._v("Raise New Ticket")])]), _c("br"), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      xs: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "alternateEmailAddress"
    }
  }, [_c("el-input", {
    attrs: {
      name: "alternateEmailAddress",
      placeholder: "Alternate Email ID"
    },
    model: {
      value: _vm.formData.alternateEmailAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "alternateEmailAddress", $$v);
      },
      expression: "formData.alternateEmailAddress"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      xs: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "alternateMobileNumber"
    }
  }, [_c("el-input", {
    attrs: {
      name: "alternateMobileNumber",
      placeholder: "Alternate Phone Number"
    },
    model: {
      value: _vm.formData.alternateMobileNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "alternateMobileNumber", $$v);
      },
      expression: "formData.alternateMobileNumber"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "subject"
    }
  }, [_c("el-input", {
    attrs: {
      name: "subject",
      placeholder: "Subject"
    },
    model: {
      value: _vm.formData.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "subject", $$v);
      },
      expression: "formData.subject"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "comments"
    }
  }, [_c("editor", {
    attrs: {
      placeholder: "Some text.....",
      "api-key": "b3w2lncq9mhqbft4zaemvx1andivk7bodsffkzneoaol8tpq",
      init: {
        plugins: ["lists link image paste help wordcount table code"],
        toolbar: "undo redo | formatselect | bold italic | table | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help"
      }
    },
    model: {
      value: _vm.formData.comments,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "comments", $$v);
      },
      expression: "formData.comments"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "attachment1"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/orders-tickets/upload",
      drag: true,
      "file-list": _vm.attachment1
    },
    on: {
      change: _vm.imageUploaded
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Go Back ")]), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary",
      size: "large",
      icon: "el-icon-position"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" Raise Ticket ")])], 1)], 1)], 1)], 1)], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;