"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-card", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("About me")])]), _c("div", {
    staticClass: "user-profile"
  }, [_c("div", {
    staticClass: "box-center",
    on: {
      click: _vm.openImagePreview
    }
  }, [_c("img", {
    staticStyle: {
      height: "100px",
      width: "100px",
      "border-radius": "50%",
      cursor: "pointer"
    },
    attrs: {
      src: _vm.userData.avatar,
      alt: "User Avatar"
    }
  })]), _c("div", {
    staticClass: "box-center"
  }, [_c("div", {
    staticClass: "user-name text-center"
  }, [_vm._v(" " + _vm._s(_vm.userData.fullName) + " ")]), _vm.userData.usersRole ? _c("div", {
    staticClass: "user-role text-center text-muted"
  }, [_vm._v(" " + _vm._s(_vm._f("uppercaseFirstChar")(_vm.userData.usersRole.name)) + " ")]) : _vm._e()])]), _c("el-dialog", {
    attrs: {
      visible: _vm.showImagePreview,
      size: "small"
    },
    on: {
      close: function close($event) {
        _vm.showImagePreview = false;
      }
    }
  }, [_c("img", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: _vm.userData.avatar,
      alt: "User Avatar"
    }
  })])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;