"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var global = {
  WEB_VERSION: '1.7.0'
};
var _default = global;
exports.default = _default;