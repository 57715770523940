"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "formData",
    staticClass: "demo-form add-product-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Product Name",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      name: "name",
      required: "",
      placeholder: "Name",
      maxlength: "50",
      minlength: "3",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  }), _c("help-text", {
    attrs: {
      content: "Please mention Brand Name(Brand, Product Type)"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Unit of Measurement",
      prop: "unitOfMeasurementId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "unitOfMeasurementId",
      required: ""
    },
    model: {
      value: _vm.formData.unitOfMeasurementId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "unitOfMeasurementId", $$v);
      },
      expression: "formData.unitOfMeasurementId"
    }
  }, _vm._l(_vm.unitOfMeasurements, function (unit) {
    return _c("el-option", {
      key: unit.id,
      attrs: {
        label: unit.name + " (" + unit.code + ")",
        value: unit.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Multiplier",
      prop: "multiplier"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "multiplier",
      placeholder: "Multiplier",
      "controls-position": "right",
      precision: 2,
      step: 1
    },
    model: {
      value: _vm.formData.multiplier,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "multiplier", $$v);
      },
      expression: "formData.multiplier"
    }
  }), _c("help-text", {
    attrs: {
      content: "Add the single Pack size/ Minimum order quantity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Short Description",
      prop: "shortDescription"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 4,
        maxRows: 10
      },
      name: "shortDescription",
      placeholder: "Description",
      maxlength: "100",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.shortDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "shortDescription", $$v);
      },
      expression: "formData.shortDescription"
    }
  }), _c("help-text", {
    attrs: {
      content: "Commonly used name in market"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Long Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 5,
        maxRows: 10
      },
      name: "description",
      placeholder: "Description",
      maxlength: "500",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  }), _c("help-text", {
    attrs: {
      content: "Legal Description"
    }
  })], 1), _c("el-form-item", {
    staticClass: "half-width",
    attrs: {
      label: "Product Price",
      prop: "productsVariation[0].unitPrice",
      required: ""
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "unitPrice",
      placeholder: "Regular rice",
      size: "medium",
      min: 0,
      precision: 2
    },
    model: {
      value: _vm.formData.productsVariation[0].unitPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.productsVariation[0], "unitPrice", $$v);
      },
      expression: "formData.productsVariation[0].unitPrice"
    }
  }), _c("help-text", {
    attrs: {
      content: "Please enter rate at which you want to sell"
    }
  })], 1), _c("el-form-item", {
    staticClass: "half-width",
    attrs: {
      label: "Stock Quantity",
      prop: "productsVariation[0].stockQuantity",
      required: ""
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "Quantity",
      placeholder: "stockQuantity",
      type: "number",
      min: 0,
      "controls-position": "right",
      precision: 0
    },
    model: {
      value: _vm.formData.productsVariation[0].stockQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.productsVariation[0], "stockQuantity", $$v);
      },
      expression: "formData.productsVariation[0].stockQuantity"
    }
  }), _c("help-text", {
    attrs: {
      content: "Please enter maximum quantity you wish to sell"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tax",
      prop: "taxId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      name: "taxId",
      required: ""
    },
    model: {
      value: _vm.formData.taxId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "taxId", $$v);
      },
      expression: "formData.taxId"
    }
  }, _vm._l(_vm.taxes, function (tax) {
    return _c("el-option", {
      key: tax.id,
      attrs: {
        label: tax.name,
        value: tax.id
      }
    });
  }), 1), _c("help-text", {
    attrs: {
      content: "Enter GST levied on item as per GST act"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Pincode",
      prop: "pincode"
    }
  }, [_c("el-input", {
    attrs: {
      name: "pincode",
      placeholder: "Enter Pincode",
      maxlength: 6
    },
    on: {
      blur: _vm.getPostCodeList
    },
    model: {
      value: _vm.pincode,
      callback: function callback($$v) {
        _vm.pincode = $$v;
      },
      expression: "pincode"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tags",
      prop: "tags"
    }
  }, [_vm._l(_vm.formData.tags ? _vm.formData.tags.split(",") : [], function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        closable: "",
        "disable-transitions": false
      },
      on: {
        close: function close($event) {
          return _vm.handleTagClose(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), _vm.inputTagVisible ? _c("el-input", {
    ref: "saveTagInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      blur: _vm.handleTagInputConfirm
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleTagInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inputTagValue,
      callback: function callback($$v) {
        _vm.inputTagValue = $$v;
      },
      expression: "inputTagValue"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.showTagInput
    }
  }, [_vm._v(" + New Tag ")])], 2), _c("el-form-item", {
    staticClass: "half-width",
    attrs: {
      label: "Hsn Code",
      prop: "hsnCode"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      name: "hsnCode",
      placeholder: "Hsn Code"
    },
    model: {
      value: _vm.formData.hsnCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "hsnCode", $$v);
      },
      expression: "formData.hsnCode"
    }
  }), _c("help-text", {
    attrs: {
      content: "Please enter HSN code of the product"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1), _c("br"), _c("br"), _c("br")], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;