"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'agent': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<path pid="0" style="text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Bitstream Vera Sans" d="M25 2a3 3 0 00-3 3v4h6V5a3 3 0 00-3-3zm0 3a1 1 0 110 2 1 1 0 010-2zM13 6c-1.654 0-3 1.346-3 3v32c0 1.654 1.346 3 3 3h17.844c1.55 3.52 5.072 6 9.156 6 5.511 0 10-4.489 10-10s-4.489-10-10-10V9c0-1.654-1.346-3-3-3h-7v4a1 1 0 01-1 1h-8a1 1 0 01-1-1V6h-7zm11.969 10c.98 0 1.736.263 2.031.781 2.863.398 1.977 4.255 1.563 5.5.154.093.405.38.343.907-.118.98-.495 1.229-.75 1.25-.098.915-.676 1.986-1 2.156 0 .653-.031 1.16.032 1.875.652 1.75 4.93 1.582 5.687 4.562-.3.307-.585.626-.844.969H17c0-.155.017-.295.031-.438l-.031-.03c.386-3.5 5.095-3.221 5.781-5.063a.255.255 0 01.063-.125c.05-.652.031-1.134.031-1.75-.325-.17-.934-1.242-1.031-2.157-.254-.02-.664-.27-.782-1.25-.063-.526.191-.813.344-.906-.86-3.31-.361-6.209 3.563-6.281zM40 32c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8zm3.406 3.188l-4.343 6.718-2.626-2.406-1.343 1.5 3.5 3.156.875.813.656-1 4.969-7.719-1.688-1.063z" overflow="visible" font-family="Bitstream Vera Sans"/>'
  }
});