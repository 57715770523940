"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'orderConfirmation': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_orderConfirmation_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="order Confirmation" clip-path="url(#svgicon_orderConfirmation_a)" _fill="#b5aeae"><path pid="1" data-name="Path 12864" d="M36.587 80.932a13.828 13.828 0 01-1.512-.328 31.654 31.654 0 01-3.04-1.114 12.908 12.908 0 00-5.35-1.169c-.911 0-1.822.011-2.733 0a1.9 1.9 0 01-1.936-1.941q-.014-6.519 0-13.038a1.932 1.932 0 011.98-1.968c1.729-.066 3.47-.064 5.182-.28a10.533 10.533 0 002.943-.964 14.726 14.726 0 016.287-1.371c4.967.035 9.934 0 14.9.015a4.611 4.611 0 014.48 3.8 4.549 4.549 0 01-3.106 5.1 5.1 5.1 0 01-1.4.215c-1.273.029-2.548.01-4.017.01.316.184.465.285.626.362 1.093.524 2.182 1.056 3.284 1.56a7.093 7.093 0 007.5-.666c3.309-2.344 6.589-4.73 9.9-7.071a4.592 4.592 0 016.146.544 4.658 4.658 0 01-.688 6.983c-2.088 1.625-4.194 3.228-6.295 4.838-1.325 1.016-2.629 2.063-3.988 3.033a18.264 18.264 0 01-8.7 3.335 2.5 2.5 0 00-.425.125z"/><path pid="2" data-name="Exclusion 1" d="M65 51.068H35a2 2 0 01-2-2v-30a2 2 0 012-2h30a2 2 0 012 2v30a2 2 0 01-2 2zM38.9 34.5l-2.1 2.1 8.4 8.4 18-18-2.1-2.1-15.9 15.9-6.3-6.3z"/></g>'
  }
});