import { render, staticRenderFns } from "./BuyerAddressDetails.vue?vue&type=template&id=21b615d2&scoped=true&"
import script from "./BuyerAddressDetails.vue?vue&type=script&lang=ts&"
export * from "./BuyerAddressDetails.vue?vue&type=script&lang=ts&"
import style0 from "./BuyerAddressDetails.vue?vue&type=style&index=0&id=21b615d2&lang=scss&"
import style1 from "./BuyerAddressDetails.vue?vue&type=style&index=1&id=21b615d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b615d2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Innovura2\\tbsellerpanel\\tradebridge-seller\\tradebridge-seller\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21b615d2')) {
      api.createRecord('21b615d2', component.options)
    } else {
      api.reload('21b615d2', component.options)
    }
    module.hot.accept("./BuyerAddressDetails.vue?vue&type=template&id=21b615d2&scoped=true&", function () {
      api.rerender('21b615d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/buyer-address/components/BuyerAddressDetails.vue"
export default component.exports