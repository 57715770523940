"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'right-arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 216.524 216.524',
    data: '<path pid="0" d="M216.524 108.262L146.5 43.267v25.034H0v79.923h146.5v25.034z"/>'
  }
});