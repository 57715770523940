"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "address-block"
  }, [_c("el-row", [_c("b", [_c("small", [_vm._v("Delivery Details")])]), _c("br"), _c("br"), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_c("small", [_vm.data.contact.organization ? _c("p", [_c("b", [_vm._v("Organisation Details")])]) : _vm._e(), _c("p", [_vm._v(" Name: " + _vm._s(_vm.data.contact.organization.name) + " " + _vm._s(_vm.data.contact.organization.gstNumber !== null ? "GST: " + _vm.data.contact.organization.gstNumber : null)), _c("br"), _vm._v(" " + _vm._s(_vm.data.contact.organization.panNumber !== null ? "PAN: " + _vm.data.contact.organization.panNumber : null) + " ")])])]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_c("small", [_c("b", [_vm._v("Billing Address")]), _c("p", [_vm._v(" " + _vm._s(_vm.data.address.name)), _c("br"), _vm._v(" " + _vm._s(_vm.data.address.line1) + ","), _c("br"), _vm.data.address.line2 ? _c("span", [_vm._v(" " + _vm._s(_vm.data.address.line2)), _c("br")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.data.address.city.name) + " " + _vm._s(_vm.data.address.state.name)), _c("br"), _vm._v(" " + _vm._s(_vm.data.address.country.name) + "-" + _vm._s(_vm.data.address.pincode.pincode) + " ")])])]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_c("small", [_c("b", [_vm._v("Shipping Address")]), _c("p", [_vm._v(" " + _vm._s(_vm.data.shippingAddress.name)), _c("br"), _vm._v(" " + _vm._s(_vm.data.shippingAddress.line1) + ", "), _c("br"), _vm.data.shippingAddress.line2 ? _c("span", [_vm._v(" " + _vm._s(_vm.data.shippingAddress.line2)), _c("br")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.data.shippingAddress.city.name) + " " + _vm._s(_vm.data.shippingAddress.state.name)), _c("br"), _vm._v(" " + _vm._s(_vm.data.shippingAddress.country.name) + "-" + _vm._s(_vm.data.shippingAddress.pincode.pincode) + " ")])]), _vm.data.deliveryOption ? _c("p", [_c("small", [_c("b", [_vm._v("Delivery Option:")]), _vm._v(" " + _vm._s(_vm.data.deliveryOption))]), _c("br"), _vm.data.vrl ? _c("small", [_c("b", [_vm._v("Warehouse:")]), _vm._v(" " + _vm._s(_vm.data.vrl.name))]) : _vm._e(), _vm._v("         ")]) : _vm._e()]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_c("small", [_c("b", [_vm._v("User Details")]), _c("p", [_vm._v("Fullname: " + _vm._s(_vm.data.user.fullName))])])])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;