"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "drawer-container"
  }, [_c("div", [_c("h3", {
    staticClass: "drawer-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("settings.title")) + " ")]), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("settings.theme")))]), _c("theme-picker", {
    staticStyle: {
      float: "right",
      height: "26px",
      margin: "-3px 8px 0 0"
    },
    on: {
      change: _vm.themeChange
    }
  })], 1), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("settings.showTagsView")))]), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.showTagsView,
      callback: function callback($$v) {
        _vm.showTagsView = $$v;
      },
      expression: "showTagsView"
    }
  })], 1), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("settings.showSidebarLogo")))]), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.showSidebarLogo,
      callback: function callback($$v) {
        _vm.showSidebarLogo = $$v;
      },
      expression: "showSidebarLogo"
    }
  })], 1), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("settings.fixedHeader")))]), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.fixedHeader,
      callback: function callback($$v) {
        _vm.fixedHeader = $$v;
      },
      expression: "fixedHeader"
    }
  })], 1), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("settings.sidebarTextTheme")))]), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.sidebarTextTheme,
      callback: function callback($$v) {
        _vm.sidebarTextTheme = $$v;
      },
      expression: "sidebarTextTheme"
    }
  })], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;