"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'reply': {
    width: 16,
    height: 16,
    viewBox: '0 0 512.001 512.001',
    data: '<path pid="0" d="M509.532 34.999a8.166 8.166 0 00-8.658-1.764L5.213 225.734a8.17 8.17 0 00-1.054 14.734l102.719 57.875 35.651 174.259a8.162 8.162 0 001.633 3.615c1.256 1.571 3.037 2.668 5.113 3a8.292 8.292 0 001.306.104 8.119 8.119 0 004.295-1.228 8.02 8.02 0 001.525-1.221l77.323-77.52a8.171 8.171 0 00-11.57-11.54l-60.739 60.894 13.124-112.394 185.495 101.814a8.157 8.157 0 006.435.614 8.15 8.15 0 004.72-3.961c.212-.404.382-.8.517-1.202L511.521 43.608a8.17 8.17 0 00-1.989-8.609zm-482.3 199.713L432.364 77.371l-318.521 206.14-86.611-48.799zm135.488 82.224a8.173 8.173 0 00-2.143 2.621 8.143 8.143 0 00-.879 2.986L148.365 419.6l-25.107-122.718L398.363 118.84 162.72 316.936zm196.787 102.259l-177.284-97.307L485.928 66.574 359.507 419.195z"/>'
  }
});