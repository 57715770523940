"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBid = exports.getBidsList = exports.getBidsByUser = exports.getBidsByProduct = exports.getBids = exports.getBidById = exports.fileTransferHeaders = exports.deleteBid = exports.defaultBuyerBidData = exports.createBid = exports.bidsCount = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultBuyerBidData = {
  id: 0,
  enabled: true,
  userId: undefined,
  sellerId: undefined,
  productId: undefined,
  orderId: undefined,
  productsVariationId: undefined,
  sellersProductsVariationId: undefined,
  status: 'Pending',
  bidQuantity: 0,
  bidPrice: undefined,
  quotedPrice: 0,
  productPrice: 0,
  totalBidPrice: 0,
  rejectReason: '',
  seller: {
    id: null
  },
  user: {
    id: null
  },
  product: {
    id: null
  },
  sellersProductsVariation: {
    id: null
  },
  productsVariation: {
    id: null,
    unitPrice: undefined,
    salePrice: undefined
  }
};
exports.defaultBuyerBidData = defaultBuyerBidData;
var getBids = function getBids(params) {
  return (0, _request.default)({
    url: '/bids',
    method: 'get',
    params: params
  });
};
exports.getBids = getBids;
var getBidsList = function getBidsList(data) {
  return (0, _request.default)({
    url: '/bids/list',
    method: 'post',
    data: data
  });
};
exports.getBidsList = getBidsList;
var getBidsByUser = function getBidsByUser(user) {
  return (0, _request.default)({
    url: "/bids?filter=user.fullName||$contL||".concat(user),
    method: 'get'
  });
};
exports.getBidsByUser = getBidsByUser;
var getBidsByProduct = function getBidsByProduct(product) {
  return (0, _request.default)({
    url: "/bids?filter=product.name||$contL||".concat(product),
    method: 'get'
  });
};
exports.getBidsByProduct = getBidsByProduct;
var getBidById = function getBidById(id) {
  return (0, _request.default)({
    url: "/bids/".concat(id),
    method: 'get'
  });
};
exports.getBidById = getBidById;
var updateBid = function updateBid(id, data) {
  return (0, _request.default)({
    url: "/bids/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateBid = updateBid;
var deleteBid = function deleteBid(id) {
  return (0, _request.default)({
    url: "/bids/".concat(id),
    method: 'delete'
  });
};
exports.deleteBid = deleteBid;
var createBid = function createBid(data) {
  return (0, _request.default)({
    url: '/bids/',
    method: 'post',
    data: data
  });
};
exports.createBid = createBid;
var bidsCount = function bidsCount(params) {
  return (0, _request.default)({
    url: '/bids/count',
    method: 'get',
    params: params
  });
};
exports.bidsCount = bidsCount;