"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.isExternal(_vm.to) ? _c("a", {
    attrs: {
      href: _vm.to,
      target: "_blank",
      rel: "noopener"
    }
  }, [_vm._t("default")], 2) : _c("router-link", {
    attrs: {
      to: _vm.to
    }
  }, [_vm._t("default")], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;