"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'subscription': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_subscription_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g clip-path="url(#svgicon_subscription_a)"><path pid="1" d="M86.819 13.141a10.248 10.248 0 00-14.163 0L61.371 24.426a11.447 11.447 0 01-16.021.235l-4.621 4.756 6.707 6.707-30.473 30.473a11.444 11.444 0 00-1.964 13.675l-4.743 4.745 4.684 4.684 4.743-4.743a11.444 11.444 0 0013.673-1.965L63.832 52.52l6.707 6.707 4.75-4.614a11.444 11.444 0 01.235-16.021l11.285-11.285a10.013 10.013 0 000-14.16zM28.672 78.31a5.088 5.088 0 01-7.025 0 4.968 4.968 0 010-7.025L52.12 40.811l7.025 7.025zm53.46-55.692L70.847 33.902a18.092 18.092 0 00-5.253 11L55.056 34.363a18.092 18.092 0 0011-5.253l11.287-11.286a3.461 3.461 0 014.79 0 3.385 3.385 0 010 4.793z" _fill="#fff"/></g>'
  }
});