"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-card", {
    staticClass: "box-card",
    staticStyle: {
      margin: "0"
    },
    attrs: {
      "body-style": "{\n      padding:0;\n        padding-right:0;\n        padding-top:20px;\n        padding-left:0;\n        padding-bottom:20px;\n  }"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "0 10px 10px"
    }
  }, [_c("p", [_c("b", [_vm._v("Order #" + _vm._s(_vm.list.orderReference))])]), _c("el-row", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      type: "flex",
      align: "middle"
    }
  }, [_c("el-col", {
    attrs: {
      xs: 12,
      sm: 12,
      lg: 16
    }
  }, [_c("b", [_vm._v("Selected Products")])]), _c("el-col", {
    attrs: {
      xs: 6,
      sm: 6,
      lg: 4
    }
  }, [_c("b", [_vm._v("Quantity")])]), _c("el-col", {
    attrs: {
      xs: 6,
      sm: 6,
      lg: 4
    }
  }, [_c("b", [_vm._v("Price")])])], 1), _vm._l(_vm.list.ordersProduct, function (elem) {
    return _c("el-row", {
      key: elem.id,
      attrs: {
        type: "flex",
        align: "middle"
      }
    }, [_c("el-col", {
      attrs: {
        xs: 6,
        sm: 12,
        lg: 4
      }
    }, [elem.product.productsImage.length > 0 ? _c("el-image", {
      staticStyle: {
        width: "70px",
        height: "70px",
        "border-radius": "50%",
        border: "1px solid black"
      },
      attrs: {
        src: elem.product.productsImage[0].image.concat("-lg")
      }
    }) : _vm._e()], 1), _c("el-col", {
      attrs: {
        xs: 6,
        sm: 4,
        lg: 12
      }
    }, [_c("b", {
      staticStyle: {
        "font-size": "14px",
        "word-break": "break-all"
      }
    }, [_vm._v(_vm._s(elem.product.name))])]), _c("el-col", {
      attrs: {
        xs: 6,
        sm: 6,
        lg: 4
      }
    }, [_c("b", [_vm._v(_vm._s(elem.quantity) + "KG Bag ")]), _c("br"), _vm._v(" (₹ " + _vm._s(elem.salePrice > 0 ? elem.salePrice : elem.unitPrice) + " per " + _vm._s(elem.product.measurementUnit.code) + ") ")]), _c("el-col", {
      attrs: {
        xs: 6,
        sm: 6,
        lg: 4
      }
    }, [_c("b", [_vm._v("₹ " + _vm._s(elem.subTotal))])])], 1);
  })], 2), _c("div", {
    staticClass: "bg-light",
    staticStyle: {
      background: "#f2f2f2"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "15px"
    }
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      xs: 24
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 12,
      xs: 18
    }
  }, [_c("small", [_vm._v("SubTotal")])]), _c("el-col", {
    attrs: {
      span: 12,
      xs: 6
    }
  }, [_c("small", [_vm._v(" ₹ " + _vm._s(_vm.list.total))])])], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12,
      xs: 18
    }
  }, [_c("small", [_vm._v("Tax(GST)")])]), _c("el-col", {
    attrs: {
      span: 12,
      xs: 6
    }
  }, [_c("small", [_vm._v(" ₹ " + _vm._s(_vm.list.taxedAmount))])])], 1), _vm.list.coupon ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 12,
      xs: 18
    }
  }, [_c("small", [_vm._v("Discount")])]), _c("el-col", {
    attrs: {
      span: 12,
      xs: 6
    }
  }, [_c("small", [_vm._v(" ₹ " + _vm._s(_vm.list.coupon.discountAmount))])])], 1) : _vm._e(), +_vm.list.freightCharges ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 12,
      xs: 18
    }
  }, [_c("small", [_vm._v("Freight Charges")])]), _c("el-col", {
    attrs: {
      span: 12,
      xs: 6
    }
  }, [_c("small", [_vm._v(" ₹ " + _vm._s(_vm.list.freightCharges))])])], 1) : _vm._e(), _vm.walletBalance > 0 ? _c("el-row", {
    staticStyle: {
      margin: "10px 0"
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      xs: 18
    }
  }, [_c("el-checkbox", {
    attrs: {
      disabled: parseInt(_vm.list.grandTotal) < parseInt(_vm.settingMinimumOrderAmt),
      label: "Apply Wallet"
    },
    on: {
      change: _vm.WalletCalculations
    },
    model: {
      value: _vm.isWallet,
      callback: function callback($$v) {
        _vm.isWallet = $$v;
      },
      expression: "isWallet"
    }
  }), _c("p", [_c("small", [_vm._v(" " + _vm._s(_vm.msg ? _vm.msg : "Order grand total should be " + _vm.settingMinimumOrderAmt + " to apply the wallet balance"))])])], 1), _c("el-col", {
    attrs: {
      span: 12,
      xs: 6
    }
  }, [_c("small", [_vm._v("₹ " + _vm._s(_vm.usedWallet ? _vm.usedWallet : "0"))])])], 1) : _vm._e(), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12,
      xs: 18
    }
  }, [_c("b", [_vm._v("Total")])]), _c("el-col", {
    attrs: {
      span: 12,
      xs: 6
    }
  }, [_c("b", [_vm._v("₹ " + _vm._s(_vm.grandTotal))])])], 1)], 1)], 1)], 1)]), _c("div", {
    staticStyle: {
      padding: "10px"
    }
  }, [_c("small", [_c("b", [_vm._v("Delivery Details")])]), _c("br"), _c("br"), _c("el-row", [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_vm.list.contact.organization ? _c("p", [_c("b", [_vm._v("Organisation Details")])]) : _vm._e(), _c("p", [_vm._v(" Name: " + _vm._s(_vm.list.contact.organization.name)), _c("br"), _vm._v(" " + _vm._s(_vm.list.contact.organization.gstNumber !== null ? "GST: " + _vm.list.contact.organization.gstNumber : null)), _c("br"), _vm._v(" " + _vm._s(_vm.list.contact.organization.panNumber !== null ? "PAN: " + _vm.list.contact.organization.panNumber : null) + " ")])]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_vm.list.address ? _c("p", [_c("b", [_vm._v("Billing Address")])]) : _vm._e(), _c("p"), _c("p", [_vm._v(" " + _vm._s(_vm.list.address.name)), _c("br"), _vm._v(" " + _vm._s(_vm.list.address.line1) + ","), _c("br"), _vm._v(_vm._s(_vm.list.address.line2)), _c("br"), _vm._v(" " + _vm._s(_vm.list.address.city.name) + " " + _vm._s(_vm.list.address.state.name)), _c("br"), _vm._v(" " + _vm._s(_vm.list.address.country.name) + "-" + _vm._s(_vm.list.address.pincode.pincode) + " ")])]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_vm.list.shippingAddress ? _c("p", [_c("b", [_vm._v("Shipping Address")])]) : _vm._e(), _c("p", [_vm._v(" " + _vm._s(_vm.list.shippingAddress.name)), _c("br"), _vm._v(_vm._s(_vm.list.shippingAddress.line1) + ","), _c("br"), _vm._v(" " + _vm._s(_vm.list.shippingAddress.line2)), _c("br"), _vm._v(" " + _vm._s(_vm.list.shippingAddress.city.name) + " " + _vm._s(_vm.list.shippingAddress.state.name)), _c("br"), _vm._v(" " + _vm._s(_vm.list.shippingAddress.country.name) + "-" + _vm._s(_vm.list.shippingAddress.pincode.pincode) + " ")]), _vm.list.deliveryOption !== "VRL" ? _c("p", [_c("b", [_vm._v("Delivery Option:")]), _vm._v(" " + _vm._s(_vm.list.deliveryOption) + " ")]) : _c("p", [_c("b", [_vm._v("Delivery Option:")]), _vm._v(" Nearest Warehouse - " + _vm._s(_vm.list.vrl.name) + " ")])]), _vm.list.user ? _c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_c("p", [_c("b", [_vm._v("Contact Details")])]), _c("p", [_vm._v(" " + _vm._s(_vm.list.user.fullName)), _c("br"), _vm._v(" Email: " + _vm._s(_vm.list.user.emailAddress)), _c("br"), _vm._v(" Phone: +91" + _vm._s(_vm.list.user.mobileNumber) + " ")])]) : _vm._e()], 1), _c("br"), _c("el-row", [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-right": "5px"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Go Back ")]), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.selectpayment
    }
  }, [_vm._v(" Proceed ")])], 1)], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.dialog,
      title: "Select Payment Method",
      center: "",
      width: "300px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "center",
      gutter: 20
    }
  }, _vm._l(_vm.paymentMethods, function (item) {
    return _c("el-button", {
      key: item,
      attrs: {
        type: "primary"
      },
      on: {
        click: function click($event) {
          return _vm.checkPaymentType(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1)], 1)], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;