"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var tncRouter = {
  path: '/blogs',
  component: _index.default,
  // redirect: 'noredirect',
  name: 'tncManagement.title',
  meta: {
    title: 'tncManagement.title',
    icon: 'tnc'
  },
  children: [{
    path: '/tnc',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/tnc/list.vue'));
      });
    },
    name: 'Terms and Conditions',
    meta: {
      title: 'tncManagement.title',
      noCache: true,
      icon: 'tnc'
    }
  }]
  // path: '/tnc',
  // component: Layout,
  // children: [
  //   {
  //     path: 'https://ashapura.s3.ap-south-1.amazonaws.com/api/common/5a3d2911-4e4f-4022-9e06-f35c4b771be8.pdf',
  //     meta: { title: 'tncManagement.title', icon: 'tnc' }
  //   }
  // ]
};
var _default = tncRouter;
exports.default = _default;