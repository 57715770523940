"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var buyerBidRouter = {
  path: '/buyer-bid',
  component: _index.default,
  redirect: 'noredirect',
  name: 'Bid Management',
  meta: {
    title: 'Bid Management',
    // roles: ['superadmin'],
    icon: 'bids'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/buyer-bid/list.vue'));
      });
    },
    name: 'Bid',
    meta: {
      title: 'orderManagement.buyerBid.title',
      icon: 'bids',
      noCache: true
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/buyer-bid/manage.vue'));
      });
    },
    name: 'orderManagement.bid.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'orderManagement.bid.manage',
      noCache: true,
      activeMenu: '/buyer-bid/list',
      hidden: true
    }
  }, {
    path: 'add/:productId(\\d+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/buyer-bid/manage.vue'));
      });
    },
    name: 'orderManagement.bid.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'orderManagement.bid.manage',
      noCache: true,
      activeMenu: '/buyer-bid/list',
      hidden: true
    }
  }]
};
var _default = buyerBidRouter;
exports.default = _default;