// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.deliverytype-card {\n  margin-bottom: 15px;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#F2F2F2",
	"menuText": "black",
	"menuActiveText": "#409eff"
};
module.exports = exports;
