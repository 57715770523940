"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'TotalOrder': {
    width: 250,
    height: 250,
    viewBox: '0 0 250 250',
    data: '<defs><clipPath id="svgicon_TotalOrder_a"><path pid="0" d="M0 0h250v250H0z"/></clipPath></defs><g data-name="Total Order"><g data-name="iconfinder_4639316_business_finance_list_task_to do_icon" _fill="#b5aeae" clip-path="url(#svgicon_TotalOrder_a)"><path pid="1" data-name="Rectangle 930" d="M34 34.309h182v36.4H34z"/><path pid="2" data-name="Path 1533" d="M34.674 79.615v136.019h139.607v-38.222h41.045V79.615zm70.518 74.623l-25.525 26.03a5.645 5.645 0 01-4.03 1.693h-.055a5.633 5.633 0 01-4.047-1.77l-13.8-14.607a5.643 5.643 0 118.2-7.751l9.775 10.343 21.421-21.844a5.645 5.645 0 118.06 7.905zm0-45.463l-25.526 26.028a5.645 5.645 0 01-4.03 1.693h-.055a5.633 5.633 0 01-4.047-1.77l-13.8-14.607a5.643 5.643 0 118.2-7.751l9.775 10.343 21.422-21.842a5.645 5.645 0 118.06 7.906zm46.3 62.2h-36.675a5.645 5.645 0 010-11.291h36.673a5.645 5.645 0 010 11.291zm36.671-45.472h-73.346a5.645 5.645 0 110-11.291h73.346a5.645 5.645 0 010 11.291z"/><path pid="3" data-name="Path 12133" d="M179.595 215.692v-31.2h36.4z"/></g></g>'
  }
});