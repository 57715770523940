"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "source"
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "middle"
    }
  }, [_c("el-col", {
    attrs: {
      span: 24,
      md: 13
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/tradebridge.fd772ad3.png"),
      width: "100%"
    }
  })]), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24,
      md: 7,
      offset: 2,
      pull: 2
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/sidelogo.png"),
      width: "320px"
    }
  }), !_vm.value ? _c("div", {
    staticClass: "grid-content bg-purple login-container"
  }, [_c("el-form", {
    ref: "recoveryForm",
    staticClass: "login-form text-left",
    attrs: {
      model: _vm.recoveryForm,
      rules: _vm.recoveryRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("h3", [_vm._v("Reset Password")]), _c("el-form-item", {
    attrs: {
      prop: "mobileNumber"
    }
  }, [_c("el-input", {
    ref: "mobileNumber",
    attrs: {
      placeholder: "Mobile Number",
      name: "mobileNumber",
      type: "number"
    },
    model: {
      value: _vm.recoveryForm.mobileNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.recoveryForm, "mobileNumber", $$v);
      },
      expression: "recoveryForm.mobileNumber"
    }
  }), _c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "user"
    }
  })], 1), _vm.recoveryForm.mobileNumber !== "" && _vm.recoveryForm.mobileNumber.length === 10 ? _c("el-button", {
    attrs: {
      color: "warning"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.getOtp();
      }
    }
  }, [_vm._v(" Get OTP ")]) : _vm._e(), _vm.successmessage ? _c("p", [_vm._v(" " + _vm._s(_vm.successmessage) + " ")]) : _vm._e()], 1), _c("el-form-item", {
    attrs: {
      prop: "otp"
    }
  }, [_c("el-input", {
    ref: "otp",
    attrs: {
      placeholder: "Enter OTP",
      name: "otp",
      type: "number"
    },
    model: {
      value: _vm.recoveryForm.otp,
      callback: function callback($$v) {
        _vm.$set(_vm.recoveryForm, "otp", $$v);
      },
      expression: "recoveryForm.otp"
    }
  }), _c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "message"
    }
  })], 1)], 1), _c("div", {
    staticClass: "text-center"
  }, [_c("el-button", {
    staticClass: "primary-outline-btn",
    attrs: {
      loading: _vm.loading,
      plain: "",
      type: "info",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Back ")]), _c("el-button", {
    staticClass: "primary-btn",
    attrs: {
      loading: _vm.loading,
      type: "primary",
      size: "large"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.handleOtp.apply(null, arguments);
      }
    }
  }, [_vm._v(" Finish ")])], 1)], 1)], 1) : _vm._e(), _vm.value ? _c("div", {
    staticClass: "grid-content bg-purple login-container"
  }, [_c("el-form", {
    ref: "otpForm",
    staticClass: "login-form text-left",
    attrs: {
      model: _vm.otpForm,
      rules: _vm.passwordRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("h3", [_vm._v("Create New Password")]), _c("el-form-item", {
    attrs: {
      prop: "password"
    }
  }, [_c("el-input", {
    key: _vm.passwordType,
    ref: "password",
    attrs: {
      type: _vm.passwordType,
      placeholder: _vm.$t("login.password"),
      name: "password",
      autocomplete: "on"
    },
    model: {
      value: _vm.otpForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.otpForm, "password", $$v);
      },
      expression: "otpForm.password"
    }
  }), _c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "password"
    }
  })], 1), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      name: _vm.passwordType === "password" ? "eye-off" : "eye-on"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "confirmPassword"
    }
  }, [_c("el-input", {
    key: _vm.passwordType,
    ref: "confirmPassword",
    attrs: {
      type: _vm.passwordType,
      placeholder: _vm.$t("login.password"),
      name: "confirmPassword",
      autocomplete: "on"
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.ifPasswordEqals.apply(null, arguments);
      }
    },
    model: {
      value: _vm.otpForm.confirmPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.otpForm, "confirmPassword", $$v);
      },
      expression: "otpForm.confirmPassword"
    }
  }), _c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "password"
    }
  })], 1), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      name: _vm.passwordType === "password" ? "eye-off" : "eye-on"
    }
  })], 1)], 1), _c("el-button", {
    staticClass: "primary-btn",
    attrs: {
      type: "primary",
      size: "large"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.resetPassword.apply(null, arguments);
      }
    }
  }, [_vm._v(" Reset password ")])], 1)], 1) : _vm._e()])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;