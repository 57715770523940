"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("transition", {
    attrs: {
      name: _vm.transitionName
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "back-to-ceiling",
    style: _vm.customStyle,
    on: {
      click: _vm.backToTop
    }
  }, [_c("svg-icon", {
    staticClass: "backTopIcon",
    attrs: {
      name: "back-top"
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;