"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "left-section"
  }, [_c("a", {
    attrs: {
      href: "https://www.tradebridge.co.in",
      target: "_blank"
    }
  }, [_c("button", {
    staticClass: "back-button"
  }, [_c("svg", {
    staticClass: "feather feather-arrow-left-circle back-icon",
    attrs: {
      xmlns: "http://www.w3.org/2000/svg",
      width: "32",
      height: "32",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c("circle", {
    attrs: {
      cx: "12",
      cy: "12",
      r: "10"
    }
  }), _c("polyline", {
    attrs: {
      points: "12 8 8 12 12 16"
    }
  }), _c("line", {
    attrs: {
      x1: "16",
      y1: "12",
      x2: "8",
      y2: "12"
    }
  })])])]), _c("h1", [_vm._v("Categories")])]), _vm._m(0), _c("div", {
    staticClass: "right-section"
  }, [_c("router-link", {
    attrs: {
      to: "/login"
    }
  }, [_c("button", {
    staticClass: "login-button"
  }, [_vm._v("Login")])]), _c("router-link", {
    attrs: {
      to: "/create-account"
    }
  }, [_c("button", {
    staticClass: "signup-button"
  }, [_vm._v("Sign Up")])])], 1)]), _c("div", {
    staticClass: "dashboard-executive-container"
  }, [_c("div", {
    staticClass: "panel-group"
  }, _vm._l(_vm.list, function (element) {
    return _c("div", {
      key: element.id,
      staticClass: "panel-card"
    }, [_c("router-link", {
      attrs: {
        to: "/web-category-products/list/" + element.id
      }
    }, [_c("div", {
      staticClass: "home-categories-cards",
      style: {
        backgroundColor: element.bgcolor
      }
    }, [_c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "feature-section-v2-list flex-column text-center home-categories-section"
    }, [_c("picture", {
      staticClass: "cat-image",
      style: {
        backgroundColor: element.bgcolor
      }
    }, [_c("img", {
      staticClass: "img-fluid mx-auto rounded-circle",
      attrs: {
        src: element.image
      }
    })]), element.name ? _c("p", [_c("b", [_vm._v(_vm._s(element.name))])]) : _vm._e()])])])])], 1);
  }), 0)]), _c("div", [_c("footer-v-1")], 1)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "center-section"
  }, [_c("a", {
    attrs: {
      href: "https://tradebridge.co.in/"
    }
  }, [_c("img", {
    staticClass: "logo pc",
    attrs: {
      src: require("../../assets/img/logo-03.png"),
      alt: "Logo"
    }
  }), _c("img", {
    staticClass: "logo mobile",
    attrs: {
      src: require("../../assets/img/logo-small.jpg"),
      alt: "Logo"
    }
  })]), _c("h1", {
    staticClass: "mobile"
  }, [_vm._v("Categories")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;