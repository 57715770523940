import { render, staticRenderFns } from "./BuyerOrderInfoTab.vue?vue&type=template&id=913a4830&scoped=true&"
import script from "./BuyerOrderInfoTab.vue?vue&type=script&lang=ts&"
export * from "./BuyerOrderInfoTab.vue?vue&type=script&lang=ts&"
import style0 from "./BuyerOrderInfoTab.vue?vue&type=style&index=0&id=913a4830&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "913a4830",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Innovura2\\tbsellerpanel\\tradebridge-seller\\tradebridge-seller\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('913a4830')) {
      api.createRecord('913a4830', component.options)
    } else {
      api.reload('913a4830', component.options)
    }
    module.hot.accept("./BuyerOrderInfoTab.vue?vue&type=template&id=913a4830&scoped=true&", function () {
      api.rerender('913a4830', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/buyer-order/components/BuyerOrderInfoTab.vue"
export default component.exports