"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'timer': {
    width: 16,
    height: 16,
    viewBox: '0 0 352.214 352.214',
    data: '<path pid="0" d="M306.78 134.119a20.673 20.673 0 008.555-5.791l.369-.418c7.539-8.551 6.717-21.641-1.834-29.181l-45.647-40.251a20.653 20.653 0 00-13.669-5.168 20.687 20.687 0 00-15.509 6.997l-.376.425a20.618 20.618 0 00-4.095 7.084 146.906 146.906 0 00-33.566-9.927V42h6.393c11.363 0 20.607-9.245 20.607-20.607v-.785C228.008 9.245 218.764 0 207.4 0h-62.785c-11.363 0-20.607 9.245-20.607 20.607v.785c0 11.363 9.244 20.607 20.607 20.607h7.393v15.756C81.705 69.301 27.894 130.48 27.894 204c0 81.726 66.488 148.214 148.213 148.214 81.726 0 148.214-66.488 148.214-148.214-.001-25.254-6.356-49.049-17.541-69.881zM176.106 316.214c-61.874 0-112.213-50.339-112.213-112.214S114.232 91.786 176.106 91.786c61.875 0 112.214 50.339 112.214 112.214s-50.339 112.214-112.214 112.214z"/><path pid="1" d="M191.008 177.583V133c0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15v44.697c-8.778 5.186-14.683 14.742-14.683 25.656 0 16.422 13.359 29.783 29.781 29.783 16.422 0 29.782-13.36 29.782-29.783.001-10.995-5.993-20.611-14.88-25.77z"/>'
  }
});