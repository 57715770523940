"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'TotalSaleINR': {
    width: 250,
    height: 250,
    viewBox: '0 0 250 250',
    data: '<defs><clipPath id="svgicon_TotalSaleINR_a"><path pid="0" d="M0 0h250v250H0z"/></clipPath></defs><g data-name="Total Sale INR" clip-path="url(#svgicon_TotalSaleINR_a)"><text data-name="₹" transform="translate(37 213)" _fill="#b5aeae" font-size="245" font-family="Montserrat-Medium, Montserrat" font-weight="500"><tspan x="0" y="0">₹</tspan></text></g>'
  }
});