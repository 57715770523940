"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.push.js");
var _moment = _interopRequireDefault(require("moment"));
var _admin = require("./store/modules/admin");
var netcore = {
  register: function register(data) {
    window.smartech('contact', '32', {
      'pk^mobile': '91' + data.mobileNumber,
      email: data.emailAddress,
      FIRSTNAME: data.fullName,
      ORGANISATION: data.organization.name,
      PAN: data.organization.panNumber,
      GST_NO: data.organization.gstNumber,
      SIGNUPDATE: (0, _moment.default)(data.createdTimestamp).format('DD-MM-YYYY')
    }, function () {
      window.smartech('identify', '91' + data.mobileNumber);
      window.smartech('dispatch', 'register', {
        Source: 'Web',
        firstname: data.fullName,
        mobile: '91' + data.mobileNumber,
        email: data.emailAddress,
        orgaisation: data.organization.name,
        pan: data.organization.panNumber,
        gst_no: data.organization.gstNumber,
        signupdate: (0, _moment.default)(data.createdTimestamp).format('DD-MM-YYYY')
      });
    });
  },
  login: function login(data) {
    window.smartech('contact', '34', {
      'pk^mobile': '91' + _admin.AdminModule.mobileNumber
    }, function () {
      window.smartech('identify', '91' + _admin.AdminModule.mobileNumber);
      window.smartech('dispatch', 'login', {
        Source: 'Web',
        firstname: data.fullName,
        mobile: '91' + _admin.AdminModule.mobileNumber,
        email: data.emailAddress
      });
    });
  },
  productView: function productView(data, produrl) {
    window.smartech('identify', '91' + _admin.AdminModule.mobileNumber);
    window.smartech('dispatch', 'Product View', {
      prid: data.id,
      name: data.name,
      // "brand": "Bata",
      variant: data.productsVariation[0].name,
      product_type: data.productType,
      category: data.category[0].name,
      price: parseFloat(data.minimumPrice),
      prqt: parseFloat(data.multiplier),
      is_in_stock: data.manageStock,
      image: data.productsImage[0].image + '-lg',
      produrl: produrl
    });
  },
  addToCart: function addToCart(data, product, produrl) {
    window.smartech('identify', '91' + _admin.AdminModule.mobileNumber);
    window.smartech('dispatch', 'Add to Cart', {
      prid: data.productId,
      name: product.name,
      // "brand": "Bata",
      variant: product.productsVariation.name,
      product_type: product.productType,
      category: product.category[0].name,
      price: parseFloat(data.unitPrice),
      prqt: parseFloat(data.quantity),
      is_in_stock: product.manageStock,
      image: product.productsImage[0].image + '-lg',
      produrl: produrl
    });
  },
  bidRequest: function bidRequest(data, pageUrl) {
    window.smartech('contact', '34', {
      'pk^mobile': '91' + data.user.mobileNumber,
      email: data.user.emailAddress
    }, function () {
      window.smartech('identify', '91' + data.user.mobileNumber);
      window.smartech('dispatch', 'bid_request', {
        product_name: data.product.name,
        bid_quantity: parseFloat(data.bidQuantity),
        bid_rate: parseFloat(data.bidPrice),
        page_url: pageUrl
      });
    });
  },
  checkout: function checkout(order, produrl) {
    window.smartech('identify', '91' + order.user.mobileNumber);
    var items = [];
    order.ordersProduct.forEach(function (ordersProduct) {
      items.push({
        prid: ordersProduct.product.id,
        name: ordersProduct.product.name,
        // "brand": "Bata",
        variant: ordersProduct.product.name,
        product_type: ordersProduct.product.productType,
        category: ordersProduct.product.category[0].name,
        price: parseFloat(ordersProduct.subTotal),
        prqt: parseFloat(ordersProduct.quantity),
        image: ordersProduct.product.productsImage[0].image + '-lg'
        // "produrl": produrl,
      });
    });

    window.smartech('dispatch', 'Checkout', {
      totalamount: parseFloat(order.grandTotal),
      currency: 'INR',
      discount: parseFloat(order.discountAmount),
      // "usertype": "New",
      total_items: parseFloat(order.totalProducts),
      items: items
    });
  },
  productPurchase: function productPurchase(order) {
    window.smartech('identify', '91' + order.user.mobileNumber);
    var items = [];
    order.ordersProduct.forEach(function (ordersProduct) {
      items.push({
        prid: ordersProduct.product.id,
        name: ordersProduct.product.name,
        // "brand": "Bata",
        variant: ordersProduct.product.name,
        product_type: ordersProduct.product.productType,
        category: ordersProduct.product.category[0].name,
        price: parseFloat(ordersProduct.subTotal),
        prqt: parseFloat(ordersProduct.quantity),
        image: ordersProduct.product.productsImage[0].image + '-lg'
        // "produrl": ordersProduct.product.productsImage[0].image,
      });
    });

    window.smartech('dispatch', 'Product Purchase', {
      orderid: order.id,
      total_amount: parseFloat(order.grandTotal),
      currency: 'INR',
      shippingfee: parseFloat(order.shippingCharges),
      discount: parseFloat(order.discountAmount),
      // "usertype": "New",
      total_items: parseFloat(order.totalProducts),
      items: items
    });
  },
  productSearch: function productSearch(keyword, url) {
    window.smartech('identify', '91' + _admin.AdminModule.mobileNumber);
    window.smartech('dispatch', 'Product Search', {
      searchterm: keyword,
      page_url: url
      // "search_category": data.category.name
    });
  },

  sellerRequest: function sellerRequest(data) {
    window.smartech('contact', '35', {
      'pk^mobile': '91' + data.mobileNumber,
      email: data.emailAddress,
      FIRSTNAME: data.fullName,
      ORGANISATION: data.organization.name,
      PAN: data.organization.panNumber,
      GST_NO: data.organization.gstNumber
    }, function () {
      window.smartech('identify', '91' + data.mobileNumber);
      window.smartech('dispatch', 'seller_request', {
        firstname: data.fullName,
        mobile: '91' + data.mobileNumber,
        email: data.emailAddress,
        orgaisation: data.organization.name,
        pan: data.organization.panNumber,
        gst_no: data.organization.gstNumber,
        signupdate: (0, _moment.default)(data.updatedTimestamp).format('DD-MM-YYYY')
      });
    });
  }
};
var _default = netcore;
exports.default = _default;