"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutesBuyer = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _bids = _interopRequireDefault(require("./modules/bids"));
var _buyerBids = _interopRequireDefault(require("./modules/buyer-bids"));
var _buyerComplains = _interopRequireDefault(require("./modules/buyer-complains"));
var _buyerFavourites = _interopRequireDefault(require("./modules/buyer-favourites"));
var _buyerOrders = _interopRequireDefault(require("./modules/buyer-orders"));
var _buyerProducts = _interopRequireDefault(require("./modules/buyer-products"));
var _buyerWallet = _interopRequireDefault(require("./modules/buyer-wallet"));
var _complain = _interopRequireDefault(require("./modules/complain"));
var _order = _interopRequireDefault(require("./modules/order"));
var _privacyPolicy = _interopRequireDefault(require("./modules/privacy-policy"));
var _product = _interopRequireDefault(require("./modules/product"));
var _subscription = _interopRequireDefault(require("./modules/subscription"));
var _tnc = _interopRequireDefault(require("./modules/tnc"));
/* Layout */

/* Router modules */

_vue.default.use(_vueRouter.default);
/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/
/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['superadmin','admin','executive']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/
/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
var constantRoutes = [{
  path: "/redirect",
  component: _index.default,
  meta: {
    hidden: true
  },
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "redirect" */"@/views/redirect/index.vue"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */"@/views/login/index.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/recover-password",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */"@/views/login/password-recovery.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/webcategories",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */"@/views/login/admincategories.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/allwebcategories",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */"@/views/login/alladmincategories.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/web-category-products/list/:categoryId",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */"@/views/login/adminCategoryProducts.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/web-product/edit/:id(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */"@/views/login/AdminProductDetails.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/create-account",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */"@/views/login/register.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/reset-password/:hash(.*)",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */"@/views/login/reset-password.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "auth-redirect" */"@/views/login/auth-redirect.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "404" */"@/views/error-page/404.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/check-subscription",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "check-subscription" */"@/views/common/CheckSubscription.vue"));
    });
  },
  meta: {
    hidden: true
  }
}, {
  path: "/",
  component: _index.default,
  redirect: "/home",
  children: [{
    path: "home",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "home" */"@/views/home/index.vue"));
      });
    },
    name: "Home",
    meta: {
      title: "home",
      icon: "building",
      affix: true
    }
  }]
}, {
  path: "/",
  component: _index.default,
  redirect: "/dashboard",
  children: [{
    path: "dashboard",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "dashboard" */"@/views/dashboard/index.vue"));
      });
    },
    name: "Dashboard",
    meta: {
      title: "dashboard",
      icon: "dash",
      affix: true
    }
  }]
}, {
  path: "/organization/edit/:id(\\d+)",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/organization/manage.vue"));
    });
  },
  name: "customerManagement.organization.edit",
  props: {
    isEdit: true
  },
  meta: {
    title: "customerManagement.organization.edit",
    // roles: ['superadmin'],
    noCache: true,
    activeMenu: "/organization/list",
    hidden: true
  }
}, {
  path: "/organization/add",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/organization/manage.vue"));
    });
  },
  name: "customerManagement.organization.add",
  props: {
    isEdit: false
  },
  meta: {
    title: "customerManagement.organization.add",
    // roles: ['superadmin'],
    noCache: true,
    activeMenu: "/organization/list",
    hidden: true
  }
}, {
  path: "/profile",
  component: _index.default,
  redirect: "/profile/index",
  meta: {
    hidden: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "profile" */"@/views/profile/index.vue"));
      });
    },
    name: "Profile",
    meta: {
      title: "profile",
      icon: "user",
      noCache: true
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
exports.constantRoutes = constantRoutes;
var asyncRoutes = [/** when your routing map is too long, you can split it into small modules **/
// adminRouter,
// settingRouter,
// masterRouter,
_product.default,
// sellerMyProductRouter,
// customerRouter,
_order.default, _bids.default, _buyerWallet.default, _complain.default,
// blogRouter,
_tnc.default, _privacyPolicy.default,
// locationsRouter,
// notificationsRouter,
// usersRouter,
{
  path: "*",
  redirect: "/dashboard",
  meta: {
    hidden: true
  }
}];
exports.asyncRoutes = asyncRoutes;
var asyncRoutesBuyer = [_buyerBids.default, _buyerOrders.default, _buyerProducts.default, _buyerComplains.default, _buyerFavourites.default, _buyerWallet.default, _subscription.default,
// blogRouter,
_tnc.default, _privacyPolicy.default,
// buyerAddressRouter,
// usersRouter,
{
  path: "*",
  redirect: "/dashboard",
  meta: {
    hidden: true
  }
}];
exports.asyncRoutesBuyer = asyncRoutesBuyer;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    scrollBehavior: function scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
  });
};
var router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;