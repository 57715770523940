"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.message ? _c("div", {
    staticClass: "header-message",
    class: {
      "cursor-pointer": _vm.onClickEvent
    },
    on: {
      click: _vm.onClick
    }
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;