"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'Name': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_Name_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g clip-path="url(#svgicon_Name_a)"><path pid="1" data-name="Path 12386" d="M-8.467-8.467h116.934v116.934H-8.467z" _fill="none"/><path pid="2" data-name="Path 12387" d="M50 50a19.489 19.489 0 10-19.489-19.489A19.484 19.484 0 0050 50zm0 9.744c-13.009 0-38.978 6.529-38.978 19.489v9.744h77.956v-9.744c0-12.96-25.969-19.489-38.978-19.489z" _fill="#8d8d8d"/></g>'
  }
});