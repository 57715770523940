"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "source"
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "middle"
    }
  }, [_c("el-col", {
    staticClass: "align-self-strech",
    attrs: {
      span: 24,
      md: 13
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/tradebridge.fd772ad3.png"),
      width: "100%"
    }
  })]), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24,
      md: 7,
      offset: 2,
      pull: 2
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/sidelogo.png"),
      width: "320px"
    }
  }), !_vm.value ? _c("div", {
    staticClass: "grid-content bg-purple login-container"
  }, [_c("el-form", {
    ref: "registeForm",
    staticClass: "login-form text-left",
    attrs: {
      model: _vm.registeForm,
      rules: _vm.registerRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("h3", [_vm._v("Create your account")]), _c("el-form-item", {
    attrs: {
      prop: "fullName"
    }
  }, [_c("el-input", {
    ref: "fullName",
    attrs: {
      placeholder: "FullName",
      name: "fullName",
      type: "text"
    },
    model: {
      value: _vm.registeForm.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.registeForm, "fullName", $$v);
      },
      expression: "registeForm.fullName"
    }
  }), _c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "user"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "emailAddress"
    }
  }, [_c("el-input", {
    ref: "emailAddress",
    attrs: {
      placeholder: _vm.$t("login.email"),
      name: "emailAddress",
      type: "text"
    },
    on: {
      blur: function blur($event) {
        return _vm.checkEmailDuplicate($event);
      }
    },
    model: {
      value: _vm.registeForm.emailAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.registeForm, "emailAddress", $$v);
      },
      expression: "registeForm.emailAddress"
    }
  }), _c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "email"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "password"
    }
  }, [_c("el-input", {
    key: _vm.passwordType,
    ref: "password",
    attrs: {
      type: _vm.passwordType,
      placeholder: _vm.$t("login.password"),
      name: "password"
    },
    model: {
      value: _vm.registeForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.registeForm, "password", $$v);
      },
      expression: "registeForm.password"
    }
  }), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      name: _vm.passwordType === "password" ? "eye-off" : "eye-on"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "confirmPassword"
    }
  }, [_c("el-input", {
    key: _vm.passwordType,
    ref: "confirmPassword",
    attrs: {
      type: _vm.passwordType,
      placeholder: "Confirm Password",
      name: "confirmPassword"
    },
    on: {
      blur: _vm.checkPassAndConfirmPass
    },
    model: {
      value: _vm.registeForm.confirmPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.registeForm, "confirmPassword", $$v);
      },
      expression: "registeForm.confirmPassword"
    }
  }), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      name: _vm.passwordType === "password" ? "eye-off" : "eye-on"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "organizationName"
    }
  }, [_c("el-input", {
    ref: "organizationName",
    attrs: {
      placeholder: "Organization Name",
      name: "organizationName",
      type: "text"
    },
    model: {
      value: _vm.registeForm.organizationName,
      callback: function callback($$v) {
        _vm.$set(_vm.registeForm, "organizationName", $$v);
      },
      expression: "registeForm.organizationName"
    }
  }), _c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "building"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "referralCode"
    }
  }, [_c("el-input", {
    ref: "referralCode",
    attrs: {
      placeholder: "Referral Code(Optional)",
      name: "referralCode",
      type: "text"
    },
    model: {
      value: _vm.registeForm.referralCode,
      callback: function callback($$v) {
        _vm.$set(_vm.registeForm, "referralCode", $$v);
      },
      expression: "registeForm.referralCode"
    }
  }), _c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "money"
    }
  })], 1)], 1), _c("div", {
    staticClass: "text-center"
  }, [_c("el-button", {
    staticClass: "primary-outline-btn",
    attrs: {
      loading: _vm.loading,
      plain: "",
      type: "info",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" Back ")]), _c("el-button", {
    staticClass: "primary-btn",
    attrs: {
      loading: _vm.loading,
      type: "primary",
      size: "large"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.handleValue.apply(null, arguments);
      }
    }
  }, [_vm._v(" Next ")])], 1)], 1)], 1) : _vm._e(), _vm.value ? _c("div", {
    staticClass: "grid-content bg-purple login-container"
  }, [_c("el-form", {
    ref: "otpForm",
    staticClass: "login-form text-left",
    attrs: {
      model: _vm.otpForm,
      rules: _vm.otpRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("h3", [_vm._v("Login to your Dashboard")]), _c("el-form-item", {
    staticClass: "inline-flex",
    attrs: {
      prop: "mobileNumber"
    }
  }, [_c("el-input", {
    ref: "mobileNumber",
    attrs: {
      placeholder: "Mobile Number",
      name: "mobileNumber",
      type: "number"
    },
    model: {
      value: _vm.otpForm.mobileNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.otpForm, "mobileNumber", $$v);
      },
      expression: "otpForm.mobileNumber"
    }
  }), _vm.otpForm.mobileNumber !== "" && _vm.otpForm.mobileNumber.length === 10 ? _c("el-button", {
    attrs: {
      type: "warning"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.getOtp();
      }
    }
  }, [_vm._v(" Get OTP ")]) : _vm._e()], 1), _vm.successmessage ? _c("p", [_vm._v(" " + _vm._s(_vm.successmessage) + " ")]) : _vm._e(), _c("el-form-item", {
    attrs: {
      prop: "otp"
    }
  }, [_c("el-input", {
    ref: "otp",
    attrs: {
      placeholder: "Enter OTP",
      name: "otp",
      type: "number"
    },
    model: {
      value: _vm.otpForm.otp,
      callback: function callback($$v) {
        _vm.$set(_vm.otpForm, "otp", $$v);
      },
      expression: "otpForm.otp"
    }
  })], 1), _c("el-button", {
    staticClass: "primary-btn",
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.handleOtp.apply(null, arguments);
      }
    }
  }, [_vm._v(" Next ")])], 1)], 1) : _vm._e()])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;