"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'blogs': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_blogs_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="Blogs &amp; Reports – 1" clip-path="url(#svgicon_blogs_a)"><path pid="1" data-name="Icon awesome-blogger-b" d="M83.094 45.64a4.253 4.253 0 00-1.844-2.729 17.812 17.812 0 00-3.688-.4c-2.965-.133-3.29-.192-4.234-.738-1.49-.87-1.888-1.815-1.9-4.352-.015-4.868-2.036-9.4-6.034-13.469a22.51 22.51 0 00-9.663-5.975c-.87-.266-2.818-.354-9.338-.428-10.238-.118-12.51.089-15.992 1.475a20.965 20.965 0 00-12.704 14.842c-.31 1.3-.385 3.38-.458 15.33-.088 14.97.015 17.17.945 20.137a20.741 20.741 0 0015.4 13.911c2.183.4 29.106.487 31.865.118a19.757 19.757 0 0012.083-6.181 20.2 20.2 0 005.193-9.161c.723-2.6.664-21.066.369-22.379zm-47.517-9.382c1.151-1.165 1.475-1.21 8.674-1.21 6.476 0 6.7.015 7.642.5a3.248 3.248 0 011.977 3.231 3.486 3.486 0 01-1.815 3.187c-.679.428-1.077.457-7.42.487a72.118 72.118 0 01-7.494-.177 3.758 3.758 0 01-1.564-6.019zm28.3 29.475l-2.2.354-11.433.133c-10.046.118-12.879-.059-13.41-.3a4.1 4.1 0 01-2.2-2.862 4.165 4.165 0 011.21-3.3c1.047-.944 1.5-.974 14.354-.988 13.218-.015 13.144-.015 14.4 1.151a3.643 3.643 0 01-.723 5.812z" _fill="#565656"/></g>'
  }
});