"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'upload': {
    width: 16,
    height: 16,
    viewBox: '0 0 300 300',
    data: '<path pid="0" d="M149.997.003C67.156.003 0 67.161 0 150s67.156 149.997 149.997 149.997C232.834 299.997 300 232.837 300 150 300 67.158 232.834.003 149.997.003zm-39.03 81.48l31.712-31.709a10.576 10.576 0 017.506-3.105h.156a10.59 10.59 0 017.511 3.105l31.707 31.709c4.15 4.147 4.15 10.872 0 15.017a10.582 10.582 0 01-7.508 3.112 10.571 10.571 0 01-7.508-3.112l-14.016-14.013V139.383c0 5.867-4.757 10.621-10.623 10.621-5.867 0-10.618-4.754-10.618-10.621V83.207l-13.297 13.295a10.59 10.59 0 01-7.508 3.112 10.583 10.583 0 01-7.511-3.112c-4.151-4.147-4.151-10.875-.003-15.019zm120.609 127.835h-.003c0 14.335-14.057 25.565-32.005 25.565h-99.132c-17.945 0-32.005-11.23-32.005-25.565V140.31c0-14.337 14.057-25.568 32.005-25.568h13.385a26.47 26.47 0 004.658.431 26.37 26.37 0 004.63-.431h.612v20.749h-23.285c-7.265 0-11.256 3.621-11.256 4.819v69.008c0 1.198 3.992 4.816 11.256 4.816h99.135c7.265 0 11.256-3.621 11.256-4.816V140.31c0-1.198-3.992-4.819-11.256-4.819h-23.485v-20.749h1.437c1.481.257 2.988.431 4.526.431 1.579 0 3.133-.158 4.658-.431h12.864c17.948 0 32.005 11.233 32.005 25.568v69.008z"/>'
  }
});