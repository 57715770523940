// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.el-submenu.is-active > .el-submenu__title {\n  color: #f4f4f5 !important;\n}\n.el-submenu__title i {\n  color: black;\n  font-size: 1.2em;\n  right: 10px;\n}\n.full-mode .el-menu-item.is-active {\n  color: #fff !important;\n  background-color: #3C9900 !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title.is-active .el-submenu__title,\n.full-mode .el-submenu.is-active .el-submenu__title {\n  color: #fff !important;\n  background-color: #3C9900 !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title .el-menu-item,\n.full-mode .el-submenu .el-menu-item {\n  min-width: 250px !important;\n  background-color: #F2F2F2 !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title .el-menu-item:hover,\n.full-mode .el-submenu .el-menu-item:hover {\n  background-color: #DFDFDF !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title .el-menu-item.is-active,\n.full-mode .el-submenu .el-menu-item.is-active {\n  color: #fff !important;\n  background-color: #DFDFDF !important;\n}\n.full-mode .el-submenu .el-menu-item.is-active, .full-mode .nest-menu .el-submenu > .el-submenu__title .el-menu-item.is-active {\n  background-color: #3c9900 !important;\n}\n.simple-mode.first-level .submenu-title-noDropdown {\n  padding: 0 !important;\n  position: relative;\n  margin-top: 8px;\n}\n.simple-mode.first-level .submenu-title-noDropdown .el-tooltip {\n  padding: 0 !important;\n}\n.simple-mode.first-level .el-submenu {\n  overflow: hidden;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title {\n  padding: 0px !important;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title .el-submenu__icon-arrow {\n  display: none;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title > span {\n  visibility: hidden;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#F2F2F2",
	"menuText": "black",
	"menuActiveText": "#409eff"
};
module.exports = exports;
