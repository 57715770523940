"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dropdown", {
    attrs: {
      id: "size-select",
      trigger: "click"
    },
    on: {
      command: _vm.handleSetSize
    }
  }, [_c("div", [_c("svg-icon", {
    staticClass: "size-icon",
    attrs: {
      name: "size"
    }
  })], 1), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.sizeOptions, function (item) {
    return _c("el-dropdown-item", {
      key: item.value,
      attrs: {
        disabled: _vm.size === item.value,
        command: item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;