"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'wish': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_wish_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="wish list – 1" clip-path="url(#svgicon_wish_a)"><path pid="1" data-name="Icon awesome-heart" d="M81.187 20.768c-8.284-7.06-20.6-5.79-28.208 2.056l-2.978 3.066-2.978-3.066c-7.588-7.845-19.922-9.115-28.208-2.056a21.706 21.706 0 00-1.5 31.428l29.251 30.2a4.739 4.739 0 006.848 0l29.251-30.2a21.693 21.693 0 00-1.478-31.428z" _fill="#565656"/></g>'
  }
});