"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadProductImages = exports.updateProductVariations = exports.updateProductInventories = exports.updateProductImages = exports.updateProductCategories = exports.updateProductAttributes = exports.updateProduct = exports.productCount = exports.graphProductData = exports.graphAdminProductData = exports.getYouMayAlsoLikeData = exports.getProductsByList = exports.getProducts = exports.getProductSellerBy = exports.getProductById = exports.deleteProductVariation = exports.deleteProductImages = exports.deleteProduct = exports.defaultBuyerProductData = exports.defaultBuyerAddProductData = exports.createProduct = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultBuyerProductData = {
  id: 0,
  enabled: true,
  name: null,
  sku: '',
  productType: 'simple',
  priority: null,
  measurementUnitId: null,
  multiplier: 1,
  featured: false,
  isfreightAllowed: true,
  suppliedBy: '',
  tags: null,
  shortDescription: null,
  description: null,
  isTaxable: true,
  taxId: null,
  hsnCode: null,
  manageStock: false,
  stockStatus: 'In Stock',
  tax: {
    id: undefined
  },
  measurementUnit: {
    id: undefined
  },
  unitOfMeasurementId: null,
  category: [],
  attribute: [],
  productsImage: [],
  productsVariation: []
};
exports.defaultBuyerProductData = defaultBuyerProductData;
var defaultBuyerAddProductData = {
  id: 0,
  userId: undefined,
  sellersProductsVariationId: 0,
  quantity: 0,
  unitPrice: 0,
  productId: 0,
  productsVariationId: 0,
  invoiceCount: 1,
  filterType: 'low price'
};
exports.defaultBuyerAddProductData = defaultBuyerAddProductData;
var getProducts = function getProducts(params) {
  return (0, _request.default)({
    url: '/products',
    method: 'get',
    params: params
  });
};
exports.getProducts = getProducts;
var getProductSellerBy = function getProductSellerBy(data) {
  return (0, _request.default)({
    url: '/sellers-products-variations/variations',
    method: 'post',
    data: data
  });
};
exports.getProductSellerBy = getProductSellerBy;
var getProductsByList = function getProductsByList(data) {
  return (0, _request.default)({
    url: '/products/list',
    method: 'post',
    data: data
  });
};
exports.getProductsByList = getProductsByList;
var getProductById = function getProductById(id) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: 'get'
  });
};
exports.getProductById = getProductById;
var getYouMayAlsoLikeData = function getYouMayAlsoLikeData(id) {
  return (0, _request.default)({
    url: "/products/related/".concat(id),
    method: 'get'
  });
};
exports.getYouMayAlsoLikeData = getYouMayAlsoLikeData;
var updateProduct = function updateProduct(id, data) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateProduct = updateProduct;
var deleteProduct = function deleteProduct(id) {
  return (0, _request.default)({
    url: "/products/".concat(id),
    method: 'delete'
  });
};
exports.deleteProduct = deleteProduct;
var createProduct = function createProduct(data) {
  return (0, _request.default)({
    url: '/products/',
    method: 'post',
    data: data
  });
};
exports.createProduct = createProduct;
var productCount = function productCount(params) {
  return (0, _request.default)({
    url: '/products/count',
    method: 'get',
    params: params
  });
};
exports.productCount = productCount;
var graphProductData = function graphProductData(params) {
  return (0, _request.default)({
    url: '/products-prices',
    method: 'get',
    params: params
  });
};
exports.graphProductData = graphProductData;
var graphAdminProductData = function graphAdminProductData(params) {
  return (0, _request.default)({
    url: '/products-prices/adminList',
    method: 'get',
    params: params
  });
};
exports.graphAdminProductData = graphAdminProductData;
var updateProductCategories = function updateProductCategories(id, data) {
  return (0, _request.default)({
    url: "/products/categories/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateProductCategories = updateProductCategories;
var updateProductAttributes = function updateProductAttributes(id, data) {
  return (0, _request.default)({
    url: "/products/attributes/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateProductAttributes = updateProductAttributes;
var updateProductVariations = function updateProductVariations(id, data) {
  return (0, _request.default)({
    url: '/products-variations/manage',
    method: 'post',
    data: data
  });
};
exports.updateProductVariations = updateProductVariations;
var deleteProductVariation = function deleteProductVariation(id) {
  return (0, _request.default)({
    url: "/products-variations/".concat(id),
    method: 'delete'
  });
};
exports.deleteProductVariation = deleteProductVariation;
var updateProductImages = function updateProductImages(id, data) {
  return (0, _request.default)({
    url: "/products/images/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateProductImages = updateProductImages;
var deleteProductImages = function deleteProductImages(id) {
  return (0, _request.default)({
    url: "/products-images/".concat(id),
    method: 'delete'
  });
};
exports.deleteProductImages = deleteProductImages;
var updateProductInventories = function updateProductInventories(id, data) {
  return (0, _request.default)({
    url: "/products/inventories/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateProductInventories = updateProductInventories;
var uploadProductImages = function uploadProductImages(data) {
  return (0, _request.default)({
    url: '/products/images',
    method: 'post',
    data: data
  });
};
exports.uploadProductImages = uploadProductImages;