"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'complaint': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_complaint_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="Complaint – 2" clip-path="url(#svgicon_complaint_a)" _fill="#565656"><path pid="1" data-name="Path 1441" d="M78.911 44.481a1.458 1.458 0 00-1.591.316l-9.011 9.008a4.36 4.36 0 01-2.235 1.2l-7.3 1.46a4.377 4.377 0 01-5.151-5.151l1.287-6.44H31.648a1.46 1.46 0 110-2.919h24.5c.051-.056.08-.127.134-.181l5.658-5.657H31.648a1.46 1.46 0 110-2.919h32.11a1.422 1.422 0 01.819.284l9.471-9.47a8.433 8.433 0 012.873-1.87 1.461 1.461 0 00.262-2.574 5.828 5.828 0 00-3.21-.965H21.431a5.845 5.845 0 00-5.838 5.836V81.36a1.46 1.46 0 00.9 1.348 1.442 1.442 0 00.559.111 1.459 1.459 0 001.032-.428l14.168-14.167h41.722a5.845 5.845 0 005.838-5.838V45.83a1.461 1.461 0 00-.901-1.349zm-29.749 9.148H31.648a1.46 1.46 0 110-2.919h17.514a1.46 1.46 0 010 2.919z"/><path pid="2" data-name="Path 1442" d="M80.062 24.438a5.621 5.621 0 00-3.951 1.636L58.346 43.838a1.453 1.453 0 00-.4.745l-1.46 7.3a1.46 1.46 0 001.431 1.746 1.491 1.491 0 00.285-.028l7.293-1.463a1.45 1.45 0 00.747-.4L84.01 33.973a5.587 5.587 0 00-3.948-9.535zm-2.315 11.676l-3.774-3.776 2.064-2.064 3.775 3.775z"/></g>'
  }
});