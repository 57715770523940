"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBid = exports.getBidsBySeller = exports.getBidsByProduct = exports.getBids = exports.getBidById = exports.fileTransferHeaders = exports.deleteBid = exports.defaultBidData = exports.createBid = exports.changeBidStatus = exports.bidsCount = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultBidData = {
  id: 0,
  enabled: true,
  sellerId: undefined,
  userId: undefined,
  bidId: undefined,
  sellersProductsVariationId: undefined,
  status: '',
  quotedPrice: undefined,
  rejectReason: '',
  seller: {
    id: null
  },
  user: {
    id: null
  },
  bid: {
    id: null,
    product: {
      id: null
    }
  },
  sellersProductsVariation: {
    id: null
  }
};
exports.defaultBidData = defaultBidData;
var getBids = function getBids(params) {
  return (0, _request.default)({
    url: '/sellers-bids',
    method: 'get',
    params: params
  });
};
exports.getBids = getBids;
var changeBidStatus = function changeBidStatus(id, data) {
  return (0, _request.default)({
    url: "/sellers-bids/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.changeBidStatus = changeBidStatus;
var getBidsBySeller = function getBidsBySeller(user) {
  return (0, _request.default)({
    url: '/sellers-bids',
    method: 'get'
  });
};
exports.getBidsBySeller = getBidsBySeller;
var getBidsByProduct = function getBidsByProduct(product) {
  return (0, _request.default)({
    url: '/sellers-bids',
    method: 'get'
  });
};
exports.getBidsByProduct = getBidsByProduct;
var getBidById = function getBidById(id) {
  return (0, _request.default)({
    url: "/sellers-bids/".concat(id),
    method: 'get'
  });
};
exports.getBidById = getBidById;
var updateBid = function updateBid(id, data) {
  return (0, _request.default)({
    url: "/sellers-bids/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateBid = updateBid;
var deleteBid = function deleteBid(id) {
  return (0, _request.default)({
    url: "/sellers-bids/".concat(id),
    method: 'delete'
  });
};
exports.deleteBid = deleteBid;
var createBid = function createBid(data) {
  return (0, _request.default)({
    url: '/sellers-bids/',
    method: 'post',
    data: data
  });
};
exports.createBid = createBid;
var bidsCount = function bidsCount(params) {
  return (0, _request.default)({
    url: '/sellers-bids/count',
    method: 'get',
    params: params
  });
};
exports.bidsCount = bidsCount;