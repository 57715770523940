"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "source"
  }, [_c("el-row", {
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "middle"
    }
  }, [_c("el-col", {
    attrs: {
      span: 24,
      md: 13
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/tradebridge.fd772ad3.png"),
      width: "100%"
    }
  })]), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24,
      md: 7,
      offset: 2,
      pull: 2
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/sidelogo.png"),
      width: "320px"
    }
  }), _c("div", {
    staticClass: "grid-content bg-purple login-container"
  }, [_c("el-form", {
    ref: "loginForm",
    staticClass: "login-form text-left",
    attrs: {
      model: _vm.loginForm,
      rules: _vm.loginRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("h3", [_vm._v("Login to your Dashboard")]), _c("el-form-item", {
    attrs: {
      prop: "emailAddress"
    }
  }, [_c("el-input", {
    ref: "emailAddress",
    attrs: {
      placeholder: _vm.$t("login.email"),
      name: "emailAddress",
      type: "text",
      autocomplete: "on"
    },
    model: {
      value: _vm.loginForm.emailAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "emailAddress", $$v);
      },
      expression: "loginForm.emailAddress"
    }
  }), _c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "user"
    }
  })], 1)], 1), _c("el-form-item", {
    staticClass: "input-two-icons",
    attrs: {
      prop: "password"
    }
  }, [_c("el-input", {
    key: _vm.passwordType,
    ref: "password",
    attrs: {
      type: _vm.passwordType,
      placeholder: _vm.$t("login.password"),
      name: "password",
      autocomplete: "on"
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.loginForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "password", $$v);
      },
      expression: "loginForm.password"
    }
  }), _c("span", {
    staticClass: "svg-container",
    staticStyle: {
      padding: "6px 13px 6px 3px"
    },
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      name: _vm.passwordType === "password" ? "eye-off" : "eye-on"
    }
  })], 1)], 1), _c("div", {
    staticClass: "remember-forgot"
  }, [_c("el-checkbox", {
    attrs: {
      label: "Remember me",
      name: "type"
    }
  }), _c("router-link", {
    attrs: {
      to: "/recover-password"
    }
  }, [_c("el-link", {
    attrs: {
      type: "info"
    }
  }, [_vm._v(" Forgot Password? ")])], 1)], 1), _c("div", {
    staticClass: "text-center"
  }, [_c("el-button", {
    staticClass: "primary-btn",
    attrs: {
      loading: _vm.loading,
      size: "large",
      type: "primary"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.handleLogin.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("login.logIn")) + " ")])], 1)], 1)], 1), _c("div", {
    staticClass: "text-center"
  }, [_c("p", {
    staticClass: "margin"
  }, [_vm._v(" New to Tradebridge ")]), _c("router-link", {
    attrs: {
      to: "/create-account"
    }
  }, [_c("el-button", {
    staticClass: "primary-outline-btn",
    attrs: {
      plain: "",
      type: "info",
      size: " medium"
    }
  }, [_vm._v(" Create an account ")])], 1), _c("br")], 1)])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;