"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'dash': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_dash_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="Dashboard – 1" clip-path="url(#svgicon_dash_a)"><g data-name="Group 26"><g data-name="Group 25"><path pid="1" data-name="Path 1381" d="M63.694 82.072H36.307a2.131 2.131 0 000 4.262h27.387a2.131 2.131 0 000-4.262z" _fill="#565656"/></g></g><g data-name="Group 28"><g data-name="Group 27"><path pid="2" data-name="Path 1382" d="M79.67 52.099a2.131 2.131 0 010-4.262h6.632a36.057 36.057 0 00-9.125-22.031l-4.69 4.69a2.131 2.131 0 11-3.013-3.014l4.69-4.689a36.058 36.058 0 00-22.033-9.126v6.632a2.131 2.131 0 01-4.262 0v-6.632a36.055 36.055 0 00-22.031 9.126l4.69 4.689a2.131 2.131 0 11-3.013 3.013l-4.69-4.689a36.053 36.053 0 00-9.126 22.032h6.632a2.131 2.131 0 110 4.262h-6.634a36.008 36.008 0 007.211 19.689 2.131 2.131 0 001.7.851h54.779a2.131 2.131 0 001.7-.851A36.01 36.01 0 0086.298 52.1zM63.358 39.625l-5.685 5.685a8.984 8.984 0 11-3.013-3.013l5.685-5.685a2.131 2.131 0 113.013 3.013z" _fill="#565656"/></g></g></g>'
  }
});