import { render, staticRenderFns } from "./ProductGeneralTab.vue?vue&type=template&id=5bd88c51&scoped=true&"
import script from "./ProductGeneralTab.vue?vue&type=script&lang=ts&"
export * from "./ProductGeneralTab.vue?vue&type=script&lang=ts&"
import style0 from "./ProductGeneralTab.vue?vue&type=style&index=0&id=5bd88c51&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd88c51",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Innovura2\\tbsellerpanel\\tradebridge-seller\\tradebridge-seller\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5bd88c51')) {
      api.createRecord('5bd88c51', component.options)
    } else {
      api.reload('5bd88c51', component.options)
    }
    module.hot.accept("./ProductGeneralTab.vue?vue&type=template&id=5bd88c51&scoped=true&", function () {
      api.rerender('5bd88c51', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/seller-my-products/components/ProductGeneralTab.vue"
export default component.exports