"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'twitter': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_twitter_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="1" clip-path="url(#svgicon_twitter_a)"><path pid="1" data-name="Subtraction 1" d="M50.003 90.597a40.6 40.6 0 01-28.71-69.304 40.6 40.6 0 0157.41 57.41 40.332 40.332 0 01-28.7 11.894zm-24.4-22.468a26.952 26.952 0 0014.745 4.354c17.171 0 27.432-13.952 27.432-27.441 0-.438-.01-.857-.029-1.245a19.544 19.544 0 004.805-5 19.558 19.558 0 01-5.533 1.523 9.639 9.639 0 004.238-5.334 19.059 19.059 0 01-6.118 2.338 9.631 9.631 0 00-16.667 6.588 9.451 9.451 0 00.248 2.2A27.388 27.388 0 0128.861 36.04a9.651 9.651 0 003 12.876 9.5 9.5 0 01-4.379-1.2v.12a9.664 9.664 0 007.731 9.453 9.678 9.678 0 01-2.537.336 9.03 9.03 0 01-1.809-.178 9.628 9.628 0 009 6.7 19.186 19.186 0 01-11.97 4.13 19.412 19.412 0 01-2.294-.148z" _fill="#3c9900"/></g>'
  }
});