"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setWebsiteToken = exports.setToken = exports.setSize = exports.setSidebarStatus = exports.setLanguage = exports.removeWebsiteToken = exports.removeToken = exports.getToken = exports.getSize = exports.getSidebarStatus = exports.getLanguage = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// App
var sidebarStatusKey = 'sidebar_status';
var getSidebarStatus = function getSidebarStatus() {
  return _jsCookie.default.get(sidebarStatusKey);
};
exports.getSidebarStatus = getSidebarStatus;
var setSidebarStatus = function setSidebarStatus(sidebarStatus) {
  return _jsCookie.default.set(sidebarStatusKey, sidebarStatus);
};
exports.setSidebarStatus = setSidebarStatus;
var languageKey = 'language';
var getLanguage = function getLanguage() {
  return _jsCookie.default.get(languageKey);
};
exports.getLanguage = getLanguage;
var setLanguage = function setLanguage(language) {
  return _jsCookie.default.set(languageKey, language);
};
exports.setLanguage = setLanguage;
var sizeKey = 'size';
var getSize = function getSize() {
  return _jsCookie.default.get(sizeKey);
};
exports.getSize = getSize;
var setSize = function setSize(size) {
  return _jsCookie.default.set(sizeKey, size);
};
// User
exports.setSize = setSize;
var tokenKey = 'vue_typescript_admin_access_token';
var getToken = function getToken() {
  return _jsCookie.default.get(tokenKey);
};
exports.getToken = getToken;
var setToken = function setToken(token) {
  return _jsCookie.default.set(tokenKey, token);
};
exports.setToken = setToken;
var removeToken = function removeToken() {
  return _jsCookie.default.remove(tokenKey);
};
exports.removeToken = removeToken;
var setWebsiteToken = function setWebsiteToken(token) {
  return _jsCookie.default.set(tokenKey + '_', {
    path: '/',
    secure: true,
    expires: 90,
    domain: 'www.tradebridge.co.in'
  });
};
exports.setWebsiteToken = setWebsiteToken;
var removeWebsiteToken = function removeWebsiteToken() {
  return _jsCookie.default.remove(tokenKey + '_');
};
exports.removeWebsiteToken = removeWebsiteToken;