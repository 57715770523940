"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      name: "name",
      required: "",
      placeholder: "Name"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "PAN Number",
      prop: "panNumber"
    }
  }, [_c("el-input", {
    attrs: {
      name: "panNumber",
      required: "",
      placeholder: "PAN Number"
    },
    on: {
      blur: function blur($event) {
        return _vm.checkPANDuplicate($event);
      }
    },
    model: {
      value: _vm.formData.panNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "panNumber", $$v);
      },
      expression: "formData.panNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "GST Number",
      prop: "gstNumber"
    }
  }, [_c("el-input", {
    attrs: {
      name: "gstNumber",
      required: "",
      placeholder: "GST Number"
    },
    on: {
      blur: function blur($event) {
        return _vm.checkGSTDuplicate($event);
      }
    },
    model: {
      value: _vm.formData.gstNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "gstNumber", $$v);
      },
      expression: "formData.gstNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "FASSAI Number",
      prop: "fssaiNumber"
    }
  }, [_c("el-input", {
    attrs: {
      name: "fssaiNumber",
      required: "",
      type: "number",
      placeholder: "FASSAI Number"
    },
    on: {
      blur: function blur($event) {
        return _vm.checkFASSAIDuplicate($event);
      }
    },
    model: {
      value: _vm.formData.fssaiNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "fssaiNumber", $$v);
      },
      expression: "formData.fssaiNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "GST Certificate",
      prop: "gstDocument"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/users/upload",
      field: "avatar",
      accept: ".jpg, .jpeg, .png",
      "list-type": "picture",
      "file-list": _vm.gstDocument
    },
    on: {
      change: _vm.gstDocumentUploaded
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Personal / Company PAN Card",
      prop: "panDocument"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/users/upload",
      field: "avatar",
      accept: ".jpg, .jpeg, .png",
      "list-type": "picture",
      "file-list": _vm.panDocument
    },
    on: {
      change: _vm.panDocumentUploaded
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "FSSAI License Copy",
      prop: "fssaiDocument"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/users/upload",
      field: "avatar",
      accept: ".jpg, .jpeg, .png",
      "list-type": "file",
      "file-list": _vm.fssaiDocument
    },
    on: {
      change: _vm.fssaiDocumentUploaded
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;