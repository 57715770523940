"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.permission = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.error.cause.js");
var _admin = require("@/store/modules/admin");
var permission = {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var roles = _admin.AdminModule.roles;
    if (value && value instanceof Array && value.length > 0) {
      var permissionRoles = value;
      var hasPermission = roles.some(function (role) {
        return permissionRoles.includes(role);
      });
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error('need roles! Like v-permission="[\'superadmin\',\'admin\',\'executive\']"');
    }
  }
};
exports.permission = permission;