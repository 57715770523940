"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var buyerProductRouter = {
  path: "/buyer-product",
  component: _index.default,
  redirect: "noredirect",
  name: "productManagement.title",
  meta: {
    title: "productManagement.title",
    icon: "product"
  },
  children: [
  // {
  //   path: "/buyer-categories/list",
  //   component: () => import("@/views/buyer-product/startCategoryList.vue"),
  //   name: "productManagement.category.title",
  //   meta: {
  //     title: "productManagement.category.title",
  //     icon: "product",
  //     noCache: true,
  //   },
  // },
  // {
  //   path: '/buyer-categories/listnew',
  //   component: () => import('@/views/buyer-product/startCategoryList.vue'),
  //   name: 'productManagement.category.title',
  //   meta: {
  //     title: 'productManagement.category.title',
  //     icon: 'product',
  //     noCache: true
  //   }
  // },
  {
    path: "/buyer-wallet/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-wallet/list.vue"));
      });
    },
    name: "Wallet",
    meta: {
      title: "walletManagement.title",
      icon: "OutstandingPayment",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/buyer-all-categories/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-product/categoryList.vue"));
      });
    },
    name: "productManagement.category.title",
    meta: {
      title: "productManagement.category.title",
      icon: "product",
      noCache: true
    }
  }, {
    path: "/buyer-product/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-product/list.vue"));
      });
    },
    name: "productManagement.product.title",
    meta: {
      title: "productManagement.product.title",
      icon: "product",
      activeMenu: "/buyer-categories/list",
      hidden: true,
      noCache: true
    }
  }, {
    path: "/buyer-category-products/list/:categoryId(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-product/buyerCategoryProducts.vue"));
      });
    },
    name: "productManagement.product.title",
    meta: {
      title: "productManagement.product.title",
      icon: "product",
      activeMenu: "/buyer-categories/list",
      hidden: true,
      noCache: true
    }
  }, {
    path: "/buyer-product/list/:keyword(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-product/list.vue"));
      });
    },
    name: "productManagement.product.title",
    meta: {
      title: "productManagement.product.title",
      icon: "product",
      hidden: true,
      activeMenu: "/buyer-categories/list",
      noCache: true
    }
  }, {
    path: "/buyer-product/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-product/manage.vue"));
      });
    },
    name: "productManagement.product.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.product.manage",
      // roles: ['superadmin'],
      noCache: true,
      hidden: true
    }
  }, {
    path: "/buyer-product/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-product/manage.vue"));
      });
    },
    name: "productManagement.product.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.product.manage",
      // roles: ['superadmin'],
      activeMenu: "/buyer-categories/list",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/myRecentProduct/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-product/myRecentProductList.vue"));
      });
    },
    name: "productManagement.product.title",
    meta: {
      title: "productManagement.product.title",
      icon: "product",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/topFeaturedProduct/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-product/topFeturedProductList.vue"));
      });
    },
    name: "productManagement.product.title",
    meta: {
      title: "productManagement.product.title",
      icon: "product",
      noCache: true,
      hidden: true
    }
  }]
};
var _default = buyerProductRouter;
exports.default = _default;