"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'PartialPayment': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_PartialPayment_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="Partial Payment"><g data-name="Group 9660" _fill="#b5aeae" clip-path="url(#svgicon_PartialPayment_a)"><path pid="1" data-name="Path 12854" d="M11.764 45.523h35.947v4.442H20.773v4.494h26.924v17.945h-.857c-8.537 0-17.076-.085-25.612.031a9.112 9.112 0 01-9.355-7.6c-.008-.067-.071-.127-.109-.19z"/><path pid="2" data-name="Path 12855" d="M11.764 32.081h35.937v8.961H11.764z"/><path pid="3" data-name="Path 12856" d="M11.764 26.405a10.643 10.643 0 011.8-4.2 8.529 8.529 0 016.837-3.546c8.981-.05 17.962-.02 26.943-.02.1 0 .2.012.354.022v8.935H11.764z"/><path pid="4" data-name="Path 12857" d="M52.275 81.365v-17.9h26.956v-4.487H52.298v-4.449H88.23V72.147a8.923 8.923 0 01-9.223 9.214q-12.952.005-25.9 0z"/><path pid="5" data-name="Path 12858" d="M52.277 49.949v-8.866H88.17v8.866z"/><path pid="6" data-name="Path 12859" d="M52.286 27.603H78.953a8.941 8.941 0 019.219 7.821 10.428 10.428 0 01.065 1.085H52.286z"/></g></g>'
  }
});