"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var productRouter = {
  path: "/product",
  component: _index.default,
  // redirect: 'noredirect',
  name: "productManagement.title",
  meta: {
    title: "productManagement.title",
    icon: "product"
  },
  children: [{
    path: "/brand/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/brand/list.vue"));
      });
    },
    name: "Brands",
    meta: {
      title: "productManagement.brand.title",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/brand/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/brand/manage.vue"));
      });
    },
    name: "productManagement.brand.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.brand.manage",
      noCache: true,
      activeMenu: "/brand/list",
      hidden: true
    }
  }, {
    path: "/brand/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/brand/manage.vue"));
      });
    },
    name: "productManagement.brand.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.brand.manage",
      noCache: true,
      activeMenu: "/brand/list",
      hidden: true
    }
  }, {
    path: "/attribute/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/attribute/list.vue"));
      });
    },
    name: "productManagement.attribute.title",
    meta: {
      title: "productManagement.attribute.title",
      // roles: ['superadmin'],
      noCache: true,
      hidden: true,
      icon: "sack"
    }
  }, {
    path: "/attribute/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/attribute/manage.vue"));
      });
    },
    name: "productManagement.attribute.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.attribute.add",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/attribute/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/attribute/manage.vue"));
      });
    },
    name: "productManagement.attribute.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.attribute.edit",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/attribute-value/list/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/attribute-value/list.vue"));
      });
    },
    name: "Attribute Value",
    meta: {
      title: "productManagement.attributevalue.title",
      // roles: ['superadmin'],
      noCache: true,
      activeMenu: "/attribute/list",
      hidden: true
    }
  }, {
    path: "/attribute-value/add/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/attribute-value/manage.vue"));
      });
    },
    name: "Manage Attribute Value",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.attributevalue.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/attribute-value/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/attribute-value/manage.vue"));
      });
    },
    name: "productManagement.attributevalue.manage",
    props: {
      isEdit: true
    },
    meta: {
      title: "productManagement.attributevalue.manage",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/product/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/products/list.vue"));
      });
    },
    name: "productManagement.product.title",
    meta: {
      title: "productManagement.product.title",
      icon: "product",
      noCache: true
    }
  }, {
    path: "/myproducts/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/products/myProductList.vue"));
      });
    },
    name: "productManagement.product.title",
    meta: {
      title: "productManagement.product.title",
      icon: "product",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/myproduct/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/seller-my-products/list.vue"));
      });
    },
    name: "sellerMyProductManagement.product.title",
    meta: {
      title: "sellerMyProductManagement.product.title",
      icon: "product",
      hidden: true,
      noCache: true
    }
  }, {
    path: "/myproduct/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/seller-my-products/manage.vue"));
      });
    },
    name: "sellerMyProductManagement.product.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "sellerMyProductManagement.product.manage",
      // roles: ['superadmin'],
      noCache: true,
      activeMenu: "/myproduct/list",
      hidden: true
    }
  }, {
    path: "/topFeaturedList/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/products/topFeaturedList.vue"));
      });
    },
    name: "productManagement.product.title",
    meta: {
      title: "productManagement.product.title",
      icon: "product",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/product/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/products/manage.vue"));
      });
    },
    name: "productManagement.product.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.product.manage",
      // roles: ['superadmin'],
      noCache: true,
      activeMenu: "/product/list",
      hidden: true
    }
  }, {
    path: "/product/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/products/manage.vue"));
      });
    },
    name: "productManagement.product.manage",
    props: {
      isEdit: false
    },
    meta: {
      title: "productManagement.product.manage",
      // roles: ['superadmin'],
      activeMenu: "/product/list",
      noCache: true,
      hidden: true
    }
  }]
};
var _default = productRouter;
exports.default = _default;