"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTax = exports.getTaxes = exports.getTaxById = exports.fileTransferHeaders = exports.deleteTax = exports.defaultTaxData = exports.createTax = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultTaxData = {
  id: 0,
  enabled: true,
  name: '',
  countryId: undefined,
  rate: 0
};
exports.defaultTaxData = defaultTaxData;
var getTaxes = function getTaxes(params) {
  return (0, _request.default)({
    url: '/taxes',
    method: 'get',
    params: params
  });
};
exports.getTaxes = getTaxes;
var getTaxById = function getTaxById(id) {
  return (0, _request.default)({
    url: "/taxes/".concat(id),
    method: 'get'
  });
};
exports.getTaxById = getTaxById;
var updateTax = function updateTax(id, data) {
  return (0, _request.default)({
    url: "/taxes/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateTax = updateTax;
var deleteTax = function deleteTax(id) {
  return (0, _request.default)({
    url: "/taxes/".concat(id),
    method: 'delete'
  });
};
exports.deleteTax = deleteTax;
var createTax = function createTax(data) {
  return (0, _request.default)({
    url: '/taxes/',
    method: 'post',
    data: data
  });
};
exports.createTax = createTax;