"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-table", {
    attrs: {
      data: _vm.list,
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Id",
      align: "center",
      fixed: "",
      prop: "id"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Product",
      align: "center",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Price (₹)",
      align: "center",
      prop: "minimumPrice"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" ₹ " + _vm._s(_vm._f("formatPrice")(scope.row.minimumPrice)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Action",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("router-link", {
          attrs: {
            to: "/buyer-bid/add/" + scope.row.id
          }
        }, [_c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "sellBtn"
        }, [_vm._v(" Bid ")])], 1), _c("router-link", {
          attrs: {
            to: "/buyer-product/edit/" + scope.row.id
          }
        }, [_c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "sellBtn"
        }, [_vm._v(" Buy ")])], 1)];
      }
    }])
  })], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;