"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "countdown text-xsmall"
  }, [_c("svg-icon", {
    staticClass: "backTopIcon",
    attrs: {
      name: "timer"
    }
  }), _c("small", [_vm._v("Bid Expires ")]), _c("el-tag", {
    staticStyle: {
      color: "#5a5a5a"
    },
    attrs: {
      size: "mini",
      color: "#f3f3f3"
    }
  }, [_vm.days ? [_vm._v(" " + _vm._s(_vm._f("twoDigits")(_vm.days)) + "d : ")] : _vm._e(), _vm._v(" " + _vm._s(_vm._f("twoDigits")(_vm.hours)) + "h : " + _vm._s(_vm._f("twoDigits")(_vm.minutes)) + "m: " + _vm._s(_vm._f("twoDigits")(_vm.seconds)) + "s ")], 2), false ? [_vm.days ? _c("div", {
    staticClass: "countdown__block"
  }, [_c("div", {
    staticClass: "countdown__digit"
  }), _c("div", {
    staticClass: "countdown__text"
  }, [_vm._v(" d ")])]) : _vm._e(), _c("div", {
    staticClass: "countdown__block"
  }, [_c("div", {
    staticClass: "countdown__digit"
  }, [_vm._v(" " + _vm._s(_vm._f("twoDigits")(_vm.hours)) + " ")]), _c("div", {
    staticClass: "countdown__text"
  }, [_vm._v(" h ")])]), _c("div", {
    staticClass: "countdown__block"
  }, [_c("div", {
    staticClass: "countdown__digit"
  }, [_vm._v(" " + _vm._s(_vm._f("twoDigits")(_vm.minutes)) + " ")]), _c("div", {
    staticClass: "countdown__text"
  }, [_vm._v(" m ")])]), _c("div", {
    staticClass: "countdown__block"
  }, [_c("div", {
    staticClass: "countdown__digit"
  }, [_vm._v(" " + _vm._s(_vm._f("twoDigits")(_vm.seconds)) + " ")]), _c("div", {
    staticClass: "countdown__text"
  }, [_vm._v(" s ")])])] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;