"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("router-view"), _c("el-dialog", {
    attrs: {
      visible: _vm.updateVisibility,
      width: "30%",
      "show-close": false,
      "close-on-click-modal": false,
      title: "New Update Available",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.updateVisibility = $event;
      }
    }
  }, [_c("span", {
    staticClass: "break-word"
  }, [_vm._v(" Please refresh the browser to get latest updates. Your current version is " + _vm._s(_vm.currentVersion) + " whereas the latest available version is " + _vm._s(_vm.availableVersion) + " ")]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.refresh
    }
  }, [_vm._v(" Update Now ")]), !_vm.forceUpdate ? _c("el-button", {
    on: {
      click: function click($event) {
        _vm.updateVisibility = false;
      }
    }
  }, [_vm._v(" Skip ")]) : _vm._e()], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;