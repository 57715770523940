"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("div", [_c("strong", {
    staticStyle: {
      "padding-right": "15px"
    }
  }, [_c("small", [_vm._v(" " + _vm._s("Switch Dashboard"))])]), false ? _c("el-select", {
    attrs: {
      placeholder: "Select"
    },
    model: {
      value: _vm.selectedRole,
      callback: function callback($$v) {
        _vm.selectedRole = $$v;
      },
      expression: "selectedRole"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Buyer",
      value: "Buyer"
    }
  }), _c("el-option", {
    attrs: {
      label: "Seller",
      value: "Seller"
    }
  })], 1) : _vm._e(), _c("el-radio-group", {
    on: {
      change: _vm.changeRole
    },
    model: {
      value: _vm.selectedRole,
      callback: function callback($$v) {
        _vm.selectedRole = $$v;
      },
      expression: "selectedRole"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Buyer"
    }
  }), _c("el-radio-button", {
    attrs: {
      label: "Seller"
    }
  })], 1), _c("el-dialog", {
    staticClass: "become_seller_dialog",
    attrs: {
      title: "Become a Supplier",
      visible: _vm.dialog
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialog = $event;
      }
    }
  }, [_c("div", {}, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Interested Products to Sell",
      prop: "interestedProducts"
    }
  }, [_c("el-input", {
    attrs: {
      name: "interestedProducts",
      placeholder: "Interested Products"
    },
    model: {
      value: _vm.formData.interestedProducts,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "interestedProducts", $$v);
      },
      expression: "formData.interestedProducts"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Monthly Capacity (in KG)",
      prop: "monthlyCapacity"
    }
  }, [_c("el-input", {
    attrs: {
      name: "monthlyCapacity",
      type: "number",
      placeholder: "Monthly Capacity"
    },
    model: {
      value: _vm.formData.monthlyCapacity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "monthlyCapacity", $$v);
      },
      expression: "formData.monthlyCapacity"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Location",
      prop: "location"
    }
  }, [_c("el-input", {
    attrs: {
      name: "location",
      placeholder: "Location"
    },
    model: {
      value: _vm.formData.location,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "location", $$v);
      },
      expression: "formData.location"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Gst Number",
      prop: "gstNumber"
    }
  }, [_c("el-input", {
    attrs: {
      name: "gstNumber",
      placeholder: "Gst Number"
    },
    on: {
      blur: function blur($event) {
        return _vm.checkGSTDuplicate($event);
      }
    },
    model: {
      value: _vm.formData.gstNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "gstNumber", $$v);
      },
      expression: "formData.gstNumber"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Pan Number",
      prop: "panNumber"
    }
  }, [_c("el-input", {
    attrs: {
      name: "panNumber",
      placeholder: "Pan Number"
    },
    on: {
      blur: function blur($event) {
        return _vm.checkPANDuplicate($event);
      }
    },
    model: {
      value: _vm.formData.panNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "panNumber", $$v);
      },
      expression: "formData.panNumber"
    }
  })], 1), _vm._l(_vm.documentList, function (item) {
    return _c("div", {
      key: item.id
    }, [_c("el-form-item", {
      attrs: {
        label: item.key,
        prop: item.key
      }
    }, [_c("file-upload", {
      staticStyle: {
        "margin-left": "29%"
      },
      attrs: {
        "file-list": [item.key],
        url: "/sellers-documents/upload"
      },
      on: {
        change: function change($event) {
          return _vm.fileUploaded(item.key, $event, item.require);
        }
      }
    })], 1)], 1);
  }), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 2)], 1)])]), _vm.selectedRole === "Buyer" ? _c("router-link", {
    attrs: {
      to: "/buyer-orders-products/list"
    }
  }, [_c("el-badge", {
    staticClass: "cart-badge",
    attrs: {
      value: _vm.cartCount
    }
  }, [_c("svg-icon", {
    attrs: {
      name: "shopping",
      width: "20",
      height: "30"
    }
  })], 1)], 1) : _vm._e()], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;