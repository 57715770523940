import { render, staticRenderFns } from "./OrderProductTab.vue?vue&type=template&id=4cd5fa76&scoped=true&"
import script from "./OrderProductTab.vue?vue&type=script&lang=ts&"
export * from "./OrderProductTab.vue?vue&type=script&lang=ts&"
import style0 from "./OrderProductTab.vue?vue&type=style&index=0&id=4cd5fa76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd5fa76",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Innovura2\\tbsellerpanel\\tradebridge-seller\\tradebridge-seller\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4cd5fa76')) {
      api.createRecord('4cd5fa76', component.options)
    } else {
      api.reload('4cd5fa76', component.options)
    }
    module.hot.accept("./OrderProductTab.vue?vue&type=template&id=4cd5fa76&scoped=true&", function () {
      api.rerender('4cd5fa76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/components/OrderProductTab.vue"
export default component.exports