"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSetting = exports.getSettings = exports.getSettingById = exports.fileTransferHeaders = exports.fetchSettings = exports.deleteSetting = exports.defaultSettingData = exports.createSetting = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultSettingData = {
  id: 0,
  bankInformation: '',
  bidExpiryHours: 0,
  automatedCreditNoteGenerationDays: 0,
  expiryDays: 0,
  bookingAmountThreshold: 0,
  transactionPercent: 0,
  bookingPercent: 0,
  cancellationCharges: 0,
  minimumOrderAmount: 0
};
exports.defaultSettingData = defaultSettingData;
var getSettings = function getSettings() {
  return (0, _request.default)({
    url: '/settings/fetch',
    method: 'get'
  });
};
exports.getSettings = getSettings;
var fetchSettings = function fetchSettings() {
  return (0, _request.default)({
    url: '/settings/fetch',
    method: 'get'
  });
};
exports.fetchSettings = fetchSettings;
var getSettingById = function getSettingById(id) {
  return (0, _request.default)({
    url: "/settings/".concat(id),
    method: 'get'
  });
};
exports.getSettingById = getSettingById;
var updateSetting = function updateSetting(id, data) {
  return (0, _request.default)({
    url: "/settings/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateSetting = updateSetting;
var deleteSetting = function deleteSetting(id) {
  return (0, _request.default)({
    url: "/settings/".concat(id),
    method: 'delete'
  });
};
exports.deleteSetting = deleteSetting;
var createSetting = function createSetting(data) {
  return (0, _request.default)({
    url: '/settings/',
    method: 'post',
    data: data
  });
};
exports.createSetting = createSetting;