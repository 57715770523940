"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'DispatchedOrder': {
    width: 250,
    height: 250,
    viewBox: '0 0 250 250',
    data: '<defs><clipPath id="svgicon_DispatchedOrder_a"><path pid="0" d="M0 0h250v250H0z"/></clipPath></defs><g _fill="#b5aeae" data-name="Dispatched Order" clip-path="url(#svgicon_DispatchedOrder_a)"><path pid="1" data-name="Path 1444" d="M102.583 27.288h25.91v30.661h-25.91z"/><path pid="2" data-name="Path 1445" d="M159.425 89.425a74.692 74.692 0 0143.47 13.847V33.013a5.79 5.79 0 00-5.853-5.725h-56.841v36.386a5.79 5.79 0 01-5.853 5.725H96.731a5.79 5.79 0 01-5.853-5.725V27.288H34.035a5.79 5.79 0 00-5.849 5.725v159.44a5.79 5.79 0 005.853 5.725h61.46c-28.85-47.121 7.459-109.3 63.93-108.753z"/><path pid="3" data-name="Path 1446" d="M218.578 142.692l-55.013 53.809a5.946 5.946 0 01-8.278 0L123.94 165.84a5.639 5.639 0 010-8.1l17.733-17.345a5.947 5.947 0 018.278 0l9.475 9.269 37.4-36.584c-40.231-30.039-99.98-1.291-99.681 48.709 0 33.642 27.883 60.915 62.279 60.915 41.832.567 72.732-41.456 59.154-80.012z"/><path pid="4" data-name="Path 1447" d="M163.564 161.811a5.947 5.947 0 01-8.278 0l-9.476-9.269-9.455 9.248 23.069 22.565 51.658-50.527a3.758 3.758 0 000-5.4l-3.935-3.844a3.964 3.964 0 00-5.518 0z"/></g>'
  }
});