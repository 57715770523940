"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.to-fixed.js");
require("core-js/modules/es.array.concat.js");
var FULL_DASH_ARRAY = 283;
var WARNING_THRESHOLD = 10;
var ALERT_THRESHOLD = 5;
var COLOR_CODES = {
  info: {
    color: 'green'
  },
  warning: {
    color: 'orange',
    threshold: WARNING_THRESHOLD
  },
  alert: {
    color: 'red',
    threshold: ALERT_THRESHOLD
  }
};
var TIME_LIMIT = 900;
var _default = {
  data: function data() {
    return {
      timePassed: 0,
      timerInterval: null
    };
  },
  computed: {
    circleDasharray: function circleDasharray() {
      return "".concat((this.timeFraction * FULL_DASH_ARRAY).toFixed(0), " 283");
    },
    formattedTimeLeft: function formattedTimeLeft() {
      var timeLeft = this.timeLeft;
      var minutes = Math.floor(timeLeft / 60);
      var seconds = timeLeft % 60;
      if (seconds < 10) {
        seconds = "0".concat(seconds);
      }
      return "".concat(minutes, ":").concat(seconds);
    },
    timeLeft: function timeLeft() {
      return TIME_LIMIT - this.timePassed;
    },
    timeFraction: function timeFraction() {
      var rawTimeFraction = this.timeLeft / TIME_LIMIT;
      return rawTimeFraction - 1 / TIME_LIMIT * (1 - rawTimeFraction);
    },
    remainingPathColor: function remainingPathColor() {
      var alert = COLOR_CODES.alert,
        warning = COLOR_CODES.warning,
        info = COLOR_CODES.info;
      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    }
  },
  watch: {
    timeLeft: function timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    }
  },
  mounted: function mounted() {
    this.startTimer();
  },
  methods: {
    onTimesUp: function onTimesUp() {
      clearInterval(this.timerInterval);
    },
    startTimer: function startTimer() {
      var _this = this;
      this.timerInterval = setInterval(function () {
        return _this.timePassed += 1;
      }, 1000);
    }
  }
};
exports.default = _default;