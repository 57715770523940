"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'wechat': {
    width: 128,
    height: 110,
    viewBox: '0 0 128 110',
    data: '<path pid="0" d="M86.6 33.3c1.5 0 3 .1 4.4.3C87 14.4 67.6.1 45.3.1 20.4.1.1 18 .1 40.7c0 13 6.7 23.8 18 32.2l-4.5 14.3L29.5 79c5.6 1.2 10.2 2.4 15.8 2.4 1.4 0 2.9 0 4.2-.2a38 38 0 0137-47.8zM62.3 20.4c3.4 0 5.7 2.4 5.7 6 0 3.5-2.3 6-5.7 6-3.4 0-6.8-2.5-6.8-6 0-3.6 3.4-6 6.8-6zm-31.7 12c-3.4 0-6.8-2.5-6.8-6 0-3.6 3.4-6 6.8-6s5.7 2.4 5.7 6c0 3.5-2.3 6-5.7 6z"/><path pid="1" d="M128 70.5c0-19-18.2-34.6-38.5-34.6C68 35.9 51 51.4 51 70.5s17 34.6 38.5 34.6c4.5 0 9-1.2 13.6-2.4l12.4 7.2-3.4-12c9-7 15.8-16.6 15.8-27.4zm-51-6c-2.2 0-4.5-2.3-4.5-4.7 0-2.4 2.3-4.8 4.5-4.8 3.4 0 5.7 2.4 5.7 4.8s-2.3 4.8-5.7 4.8zm25 0c-2.3 0-4.6-2.3-4.6-4.7 0-2.4 2.3-4.8 4.5-4.8 3.4 0 5.7 2.4 5.7 4.8s-2.3 4.8-5.7 4.8z"/>'
  }
});