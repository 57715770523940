"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clipboard = void 0;
require("core-js/modules/es.error.cause.js");
var _clipboard = _interopRequireDefault(require("clipboard"));
// Inspired by https://github.com/Inndy/vue-clipboard2

if (!_clipboard.default) {
  throw new Error('you should npm install `clipboard` --save at first ');
}
var successCallback;
var errorCallback;
var clipboardInstance;
var clipboard = {
  bind: function bind(el, binding) {
    if (binding.arg === 'success') {
      successCallback = binding.value;
    } else if (binding.arg === 'error') {
      errorCallback = binding.value;
    } else {
      clipboardInstance = new _clipboard.default(el, {
        text: function text() {
          return binding.value;
        },
        action: function action() {
          return binding.arg === 'cut' ? 'cut' : 'copy';
        }
      });
      clipboardInstance.on('success', function (e) {
        var callback = successCallback;
        callback && callback(e);
      });
      clipboardInstance.on('error', function (e) {
        var callback = errorCallback;
        callback && callback(e);
      });
    }
  },
  update: function update(el, binding) {
    if (binding.arg === 'success') {
      successCallback = binding.value;
    } else if (binding.arg === 'error') {
      errorCallback = binding.value;
    } else {
      clipboardInstance = new _clipboard.default(el, {
        text: function text() {
          return binding.value;
        },
        action: function action() {
          return binding.arg === 'cut' ? 'cut' : 'copy';
        }
      });
    }
  },
  unbind: function unbind(_, binding) {
    if (binding.arg === 'success') {
      successCallback = null;
    } else if (binding.arg === 'error') {
      errorCallback = null;
    } else {
      if (clipboardInstance) {
        clipboardInstance.destroy();
      }
      clipboardInstance = null;
    }
  }
};
exports.clipboard = clipboard;