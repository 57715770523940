"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm._numberToWords(),
      placement: "top",
      disabled: !_vm.words || _vm.formatPrice
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    }
  }, [_c("svg-icon", {
    class: _vm.size,
    attrs: {
      name: "inr"
    }
  }), _vm.formatPrice ? [_vm._v(" " + _vm._s(_vm._f("formatPrice")(_vm.number)) + " ")] : [_vm._v(" " + _vm._s(_vm.getFormattedNumber()) + " ")]], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;