"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'tnc': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_tnc_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="Terms and Conditions – 1" clip-path="url(#svgicon_tnc_a)"><g data-name="Group 3242"><path pid="1" data-name="Path 1440" d="M79.703 68.373v-55.64H20.594v75.033h39.715V68.373zM40.769 26.167h18.758v4.4H40.769zm-8.6 10.942h35.954v4.4H32.171zm0 9.379h35.954v4.4H32.171zm4.616 26.281h-4.614v-4.4h4.616zm13.361 0h-8.962v-4.4h8.964zM32.171 60.264v-4.4h35.954v4.4z" _fill="#565656"/></g><path pid="2" data-name="Path 12149" d="M62.975 86.302V70.915h14.652z" _fill="#565656"/></g>'
  }
});