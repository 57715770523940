"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.string.link.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.concat.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "left-section"
  }, [_c("a", {
    attrs: {
      href: "https://www.tradebridge.co.in",
      target: "_blank"
    }
  }, [_c("button", {
    staticClass: "back-button"
  }, [_c("svg", {
    staticClass: "feather feather-arrow-left-circle back-icon",
    attrs: {
      xmlns: "http://www.w3.org/2000/svg",
      width: "32",
      height: "32",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c("circle", {
    attrs: {
      cx: "12",
      cy: "12",
      r: "10"
    }
  }), _c("polyline", {
    attrs: {
      points: "12 8 8 12 12 16"
    }
  }), _c("line", {
    attrs: {
      x1: "16",
      y1: "12",
      x2: "8",
      y2: "12"
    }
  })])])]), _c("h1", [_vm._v("Categories")])]), _vm._m(0), _c("div", {
    staticClass: "right-section"
  }, [_c("router-link", {
    attrs: {
      to: "/login"
    }
  }, [_c("button", {
    staticClass: "login-button"
  }, [_vm._v("Login")])]), _c("router-link", {
    attrs: {
      to: "/create-account"
    }
  }, [_c("button", {
    staticClass: "signup-button"
  }, [_vm._v("Sign Up")])])], 1)]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticClass: "dashboard-executive-container"
  }, [_c("el-carousel", {
    attrs: {
      height: "100%"
    }
  }, _vm._l(_vm.bannerList, function (item) {
    return _c("el-carousel-item", {
      key: item.id
    }, [item.type !== "Link" ? _c("router-link", {
      staticClass: "carousel-inside",
      attrs: {
        to: item.type === "Category" ? "/buyer-category-products/list/".concat(item.categoryId) : item.type === "Product" ? "/buyer-product/edit/".concat(item.productId) : ""
      }
    }, [item.desktopImage ? _c("img", {
      staticClass: "bannerimg",
      attrs: {
        src: item.desktopImage,
        alt: "Mobile Image"
      }
    }) : _vm._e()]) : _c("a", {
      staticClass: "carousel-inside",
      attrs: {
        href: item.link,
        target: "_blank"
      }
    }, [item.desktopImage ? _c("img", {
      staticClass: "bannerimg",
      attrs: {
        src: item.desktopImage,
        alt: "Mobile Image"
      }
    }) : _vm._e()])], 1);
  }), 1), _c("el-row", {
    staticStyle: {
      "padding-top": "20px"
    }
  }, [_c("strong", [_vm._v("Explore by Categories")])])], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticClass: "dashboard-executive-containerNew"
  }, [_c("div", {
    staticClass: "panel-group"
  }, [_vm._l(_vm.list.slice(0, 11), function (element) {
    return _c("div", {
      key: element.id,
      staticClass: "panel-card"
    }, [_c("router-link", {
      attrs: {
        to: "/web-category-products/list/" + element.id
      }
    }, [_c("div", {
      staticClass: "home-categories-cards",
      style: {
        backgroundColor: element.bgcolor
      }
    }, [_c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "feature-section-v2-list flex-column text-center home-categories-section"
    }, [_c("picture", {
      staticClass: "cat-image",
      style: {
        backgroundColor: element.bgcolor
      }
    }, [_c("img", {
      staticClass: "img-fluid mx-auto rounded-circle",
      attrs: {
        src: element.image
      }
    })]), element.name ? _c("p", [_c("b", [_vm._v(_vm._s(element.name))])]) : _vm._e()])])])])], 1);
  }), _c("div", {
    staticClass: "panel-card"
  }, [_c("router-link", {
    attrs: {
      to: "/allwebcategories"
    }
  }, [_c("div", {
    staticClass: "home-categories-cards"
  }, [_c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "feature-section-v2-list flex-column text-center home-categories-section"
  }, [_c("picture", {
    staticClass: "cat-image"
  }, [_c("svg-icon", {
    staticClass: "img-fluid mx-auto rounded-circle",
    staticStyle: {
      fill: "#3c9900"
    },
    attrs: {
      name: "right-arrow",
      width: "80",
      height: "170"
    }
  })], 1), _c("p", [_c("b", [_vm._v("View All")])])])])])])], 1)], 2), _c("el-row", [_c("strong", [_vm._v("Explore by Products")])]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    staticClass: "product-flex"
  }, _vm._l(_vm.listTopProduct, function (element) {
    return _c("div", {
      key: element.id,
      staticClass: "product-item",
      class: {
        "out-of-stock": element.sellersProductsVariation[0].stockQuantity === "0.00"
      }
    }, [_c("router-link", {
      staticClass: "productItem-inside",
      attrs: {
        to: "/buyer-product/edit/" + element.id
      }
    }, [_c("div", {
      staticClass: "home-categories-cards",
      style: {
        backgroundColor: element.bgcolor
      }
    }, [_c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "feature-section-v2-list flex-column text-center home-categories-section",
      staticStyle: {
        "padding-top": "0px",
        "padding-bottom": "0px"
      }
    }, [_c("picture", {
      staticClass: "cat-image"
    }, [_c("img", {
      staticClass: "img-fluid mx-auto rounded-circle",
      attrs: {
        src: element.productsImage[0].image.concat("-lg")
      }
    })]), element.name ? _c("p", [_c("b", [_vm._v(_vm._s(_vm._f("truncate")(element.name, 20, "...")))]), _c("small", {
      staticStyle: {
        display: "block"
      }
    }, [_vm._v(_vm._s(_vm._f("truncate")(element.shortDescription, 35, "...")))])]) : _vm._e(), +element.minimumPrice ? _c("small", [_c("b", [_vm._v("₹ " + _vm._s(_vm._f("formatPrice")(element.minimumPrice)))]), _vm._v(" Per " + _vm._s(element.measurementUnit.code) + " ")]) : _vm._e(), _c("p", [element.state ? _c("small", [_vm._v(" Seller State: "), _c("b", [_vm._v(_vm._s(element.state.name))])]) : _vm._e()]), element.sellersProductsVariation[0].stockQuantity === "0.00" ? _c("div", {
      staticClass: "out-of-stock-text"
    }, [_vm._v(" Out of Stock ")]) : _vm._e(), _c("div", {
      staticClass: "productItem-buttons",
      staticStyle: {
        display: "flex",
        gap: "10px",
        "justify-content": "center"
      }
    }, [_c("router-link", {
      attrs: {
        to: "/buyer-product/edit/" + element.id
      }
    }, [_c("el-button", {
      directives: [{
        name: "waves",
        rawName: "v-waves"
      }],
      staticClass: "sellBtn",
      attrs: {
        size: "mini",
        type: "primary"
      }
    }, [_vm._v(" Add to cart ")])], 1), _c("router-link", {
      attrs: {
        to: "/buyer-bid/add/" + element.id
      }
    }, [_c("el-button", {
      directives: [{
        name: "waves",
        rawName: "v-waves"
      }],
      staticClass: "sellBtn",
      attrs: {
        size: "mini"
      }
    }, [_vm._v(" Bid for Product ")])], 1)], 1)])])])])], 1);
  }), 0)], 1), _c("footer-v-1")], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "center-section"
  }, [_c("a", {
    attrs: {
      href: "https://tradebridge.co.in/"
    }
  }, [_c("img", {
    staticClass: "logo pc",
    attrs: {
      src: require("../../assets/img/logo-03.png"),
      alt: "Logo"
    }
  }), _c("img", {
    staticClass: "logo mobile",
    attrs: {
      src: require("../../assets/img/logo-small.jpg"),
      alt: "Logo"
    }
  })]), _c("h1", {
    staticClass: "mobile"
  }, [_vm._v("Categories")])]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;