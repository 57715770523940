"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userLocation = exports.updateAdmin = exports.register = exports.logout = exports.login = exports.getUserSubscriptionPlans = exports.getUserActivePlans = exports.getPlans = exports.getAdmins = exports.getAdminInfo = exports.getAdminById = exports.getAdminByEmail = exports.deleteAdmin = exports.defaultAdminData = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var defaultAdminData = {
  id: 0,
  enabled: true,
  fullName: '',
  emailAddress: '',
  mobileNumber: '',
  password: undefined,
  avatar: '',
  adminsRoleId: 1,
  adminsRole: {
    id: null,
    name: ''
  }
};
exports.defaultAdminData = defaultAdminData;
var getAdmins = function getAdmins(params) {
  return (0, _request.default)({
    url: '/admins',
    method: 'get',
    params: params
  });
};
exports.getAdmins = getAdmins;
var getAdminById = function getAdminById(id) {
  return (0, _request.default)({
    url: "/users/".concat(id),
    method: 'get'
  });
};
exports.getAdminById = getAdminById;
var getAdminInfo = function getAdminInfo(data) {
  return (0, _request.default)({
    url: '/users/me',
    method: 'post',
    data: data
  });
};
exports.getAdminInfo = getAdminInfo;
var getAdminByEmail = function getAdminByEmail(adminemail) {
  return (0, _request.default)({
    url: "/admins/".concat(adminemail),
    method: 'get'
  });
};
exports.getAdminByEmail = getAdminByEmail;
var updateAdmin = function updateAdmin(id, data) {
  return (0, _request.default)({
    url: "/admins/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateAdmin = updateAdmin;
var deleteAdmin = function deleteAdmin(adminname) {
  return (0, _request.default)({
    url: "/admins/".concat(adminname),
    method: 'delete'
  });
};
exports.deleteAdmin = deleteAdmin;
var login = function login(data) {
  return (0, _request.default)({
    url: '/users/login',
    method: 'post',
    data: data
  });
};
// export const login = (data: any) =>
//   request({
//     url: '/users/seller-login',
//     method: 'post',
//     data
//   })
exports.login = login;
var logout = function logout(data) {
  return (0, _request.default)({
    url: '/users/logout',
    method: 'post',
    data: data
  });
};
exports.logout = logout;
var register = function register(data) {
  return (0, _request.default)({
    url: '/admins/',
    method: 'post',
    data: data
  });
};
exports.register = register;
var userLocation = function userLocation(data) {
  return (0, _request.default)({
    url: '/users/location',
    method: 'post',
    data: data
  });
};
exports.userLocation = userLocation;
var getUserActivePlans = function getUserActivePlans() {
  return (0, _request.default)({
    url: '/subscriptions/active-plan',
    method: 'get'
  });
};
exports.getUserActivePlans = getUserActivePlans;
var getUserSubscriptionPlans = function getUserSubscriptionPlans(params) {
  return (0, _request.default)({
    url: '/subscriptions',
    method: 'get',
    params: params
  });
};
exports.getUserSubscriptionPlans = getUserSubscriptionPlans;
var getPlans = function getPlans(params) {
  return (0, _request.default)({
    url: '/plans',
    method: 'get',
    params: params
  });
};
exports.getPlans = getPlans;