"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.errorLogs.length > 0 ? _c("div", [_c("el-badge", {
    staticStyle: {
      "line-height": "25px",
      "margin-top": "-5px"
    },
    attrs: {
      "is-dot": true
    },
    nativeOn: {
      click: function click($event) {
        _vm.dialogTableVisible = true;
      }
    }
  }, [_c("el-button", {
    staticStyle: {
      padding: "8px 10px"
    },
    attrs: {
      size: "small",
      type: "danger"
    }
  }, [_c("svg-icon", {
    attrs: {
      name: "bug"
    }
  })], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogTableVisible,
      width: "80%",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogTableVisible = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("span", {
    staticStyle: {
      "padding-right": "10px"
    }
  }, [_vm._v("Error Log")]), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      icon: "el-icon-delete"
    },
    on: {
      click: _vm.clearAll
    }
  }, [_vm._v(" Clear All ")])], 1), _c("el-table", {
    attrs: {
      data: _vm.errorLogs,
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Message"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", [_c("span", {
          staticClass: "message-title"
        }, [_vm._v("Msg:")]), _c("el-tag", {
          attrs: {
            type: "danger"
          }
        }, [_vm._v(" " + _vm._s(row.err.message) + " ")])], 1), _c("br"), _c("div", [_c("span", {
          staticClass: "message-title",
          staticStyle: {
            "padding-right": "10px"
          }
        }, [_vm._v("Info: ")]), _c("el-tag", {
          attrs: {
            type: "warning"
          }
        }, [_vm._v(" " + _vm._s(row.vm.$vnode.tag) + " error in " + _vm._s(row.info) + " ")])], 1), _c("br"), _c("div", [_c("span", {
          staticClass: "message-title",
          staticStyle: {
            "padding-right": "16px"
          }
        }, [_vm._v("Url: ")]), _c("el-tag", {
          attrs: {
            type: "success"
          }
        }, [_vm._v(" " + _vm._s(row.url) + " ")])], 1)];
      }
    }], null, false, 3134886942)
  }), _c("el-table-column", {
    attrs: {
      label: "Stack"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.err.stack) + " ")];
      }
    }], null, false, 2525805560)
  })], 1)], 1)], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;