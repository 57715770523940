"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocale = exports.default = exports.SetLanguageDirection = void 0;
require("core-js/modules/es.object.keys.js");
var _objectSpread2 = _interopRequireDefault(require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _cookies = require("@/utils/cookies");
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _en2 = _interopRequireDefault(require("./en"));
// element-ui built-in lang

// User defined lang

_vue.default.use(_vueI18n.default);
var messages = {
  en: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _en2.default), _en.default)
};
var getLocale = function getLocale() {
  var cookieLanguage = (0, _cookies.getLanguage)();
  if (cookieLanguage) {
    SetLanguageDirection(cookieLanguage);
    return cookieLanguage;
  }
  var language = navigator.language.toLowerCase();
  var locales = Object.keys(messages);
  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  // Default language is english
  return 'en';
};
exports.getLocale = getLocale;
var SetLanguageDirection = function SetLanguageDirection() {
  var language = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var body = document.querySelector('body');
  if (body) {
    if (language === 'ar') {
      if (!body.classList.contains('rtl')) {
        body.classList.add('rtl');
      }
    } else {
      body.classList.remove('rtl');
    }
  }
};
exports.SetLanguageDirection = SetLanguageDirection;
var i18n = new _vueI18n.default({
  locale: getLocale(),
  messages: messages
});
var _default = i18n;
exports.default = _default;