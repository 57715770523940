"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Product Info")])]), _vm._l(_vm.data.ordersProduct, function (p) {
    return _c("div", {
      key: p,
      staticClass: "text item",
      staticStyle: {
        padding: "5px"
      }
    }, [_c("p", [_c("b", [_vm._v("Product Name : ")]), _vm._v(_vm._s(p.product.name))]), _c("p", [_c("b", [_vm._v(" Price : ")]), _c("inr", {
      attrs: {
        number: p.unitPrice
      }
    })], 1), _c("p", [_c("b", [_vm._v(" Sale Price : ")]), _c("inr", {
      attrs: {
        number: p.salePrice
      }
    })], 1), _c("p", [_c("b", [_vm._v("Taxed Amount : ")]), _c("inr", {
      attrs: {
        number: p.taxedAmount
      }
    })], 1), _c("p", [_c("b", [_vm._v(" Rate : ")]), _vm._v(" " + _vm._s(p.taxRate) + "%")])]);
  })], 2)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;