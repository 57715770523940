"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'order': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_order_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="Open – 1" clip-path="url(#svgicon_order_a)"><g data-name="Group 9171" transform="translate(16.242 16.968)" _fill="#565656"><ellipse pid="1" data-name="Ellipse 248" cx="8.035" cy="7.859" rx="8.035" ry="7.859" transform="translate(18.121 50.766)"/><path pid="2" data-name="Path 1399" d="M49.605 50.768a7.861 7.861 0 108.035 7.86 7.949 7.949 0 00-8.035-7.86z"/><path pid="3" data-name="Path 1400" d="M63.299 42.746h-41.9l-.82-4.651h34.438a2.46 2.46 0 002.3-1.524l8.2-20.21a2.389 2.389 0 00-1.387-3.121 2.506 2.506 0 00-.909-.168H16.317L14.349 2.001a2.446 2.446 0 00-2.462-2H2.46A2.433 2.433 0 000 2.407a2.433 2.433 0 002.46 2.406h7.379l7.048 40.74a2.446 2.446 0 002.46 2h43.952a2.407 2.407 0 100-4.812z"/></g></g>'
  }
});