"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.list.length === 0 && _vm.listQuery.page === -1 ? _c("el-card", {
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "center"
    }
  }, [_c("img", {
    staticClass: "img-fluid",
    attrs: {
      src: require("@/assets/img/complaint.png"),
      width: "400px"
    }
  })]) : _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }]
  }, [_c("el-card", {
    staticClass: "card-panel-col"
  }, [_c("el-card", {
    attrs: {
      shadow: "false"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 4,
      lg: 4
    }
  }, [_c("b", [_vm._v("Ticket ID")])]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_c("b", [_vm._v("OrderId")])]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_c("b", [_vm._v("Raised On")])]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 6,
      lg: 6
    }
  }, [_c("b", [_vm._v("Subject")])])], 1)], 1), _vm._l(_vm.list, function (element) {
    return _c("el-card", {
      key: element.id,
      attrs: {
        shadow: "false"
      }
    }, [_c("el-row", [_c("el-collapse", {
      staticStyle: {
        border: "0"
      }
    }, [_c("el-col", {
      attrs: {
        xs: 24,
        sm: 4,
        lg: 4
      }
    }, [_vm._v(" " + _vm._s(element.id) + " ")]), element.ordersSeller ? _c("el-col", {
      attrs: {
        xs: 24,
        sm: 6,
        lg: 6
      }
    }, [_vm._v(" " + _vm._s(element.ordersSeller.orderId) + " ")]) : _vm._e(), _c("el-col", {
      attrs: {
        xs: 24,
        sm: 6,
        lg: 6
      }
    }, [_vm._v(" " + _vm._s(_vm._f("moment")(element.createdTimestamp, "MMMM Do YYYY, hh:mm a")) + " ")]), _c("el-col", {
      attrs: {
        xs: 24,
        sm: 6,
        lg: 6
      }
    }, [_vm._v(" " + _vm._s(element.subject) + " ")]), _c("el-collapse-item", {
      staticStyle: {
        margin: "0"
      }
    }, [_c("el-card", {
      staticClass: "box-card",
      staticStyle: {
        margin: "0"
      },
      attrs: {
        "body-style": "background:#F7F6F6"
      }
    }, [_c("el-row", [_c("el-col", {
      attrs: {
        xs: 24,
        sm: 4,
        lg: 16
      }
    }, [_c("b", [_vm._v("Subject:")]), _vm._v(" " + _vm._s(element.subject) + " ")])], 1), _c("el-row", [_c("el-col", {
      attrs: {
        xs: 24,
        sm: 4,
        lg: 20
      }
    }, [_c("b", [_vm._v("Buyer Message:")]), _c("div", {
      domProps: {
        innerHTML: _vm._s(element.comments)
      }
    })])], 1), _c("el-row", [_c("el-col", {
      attrs: {
        xs: 24,
        sm: 4,
        lg: 20
      }
    }, [element.status === "Closed" ? [_c("b", [_vm._v("Seller Message: ")]), _c("div", {
      domProps: {
        innerHTML: _vm._s(element.closureComments)
      }
    })] : _vm._e()], 2)], 1), _c("el-row", {
      attrs: {
        type: "flex",
        justify: "space-between",
        align: "left"
      }
    }, [element.attachment1 ? _c("el-col", {
      attrs: {
        span: 18,
        xs: 24,
        sm: 4,
        lg: 4
      }
    }, [_c("p", [_c("b", [_vm._v("Buyer Attachments:")])]), _vm._l([1, 2, 3], function (number, index) {
      return [element["attachment" + number] ? _c("a", {
        key: index,
        staticClass: "downloads",
        attrs: {
          href: element["attachment" + number],
          target: "_blank"
        }
      }, [_c("img", {
        attrs: {
          src: "/img/social/attachment.svg",
          width: "30"
        }
      }), _vm._v(" Attachment ")]) : _vm._e()];
    })], 2) : _vm._e()], 1), _c("el-row", [element.status === "Closed" ? _c("el-col", {
      attrs: {
        xs: 24,
        sm: 4,
        lg: 4
      }
    }, [element.closureAttachment ? _c("p", [_c("b", [_vm._v("Seller Attachments:")])]) : _vm._e(), element.closureAttachment ? _c("a", {
      staticClass: "downloads",
      attrs: {
        href: element.closureAttachment,
        target: "_blank"
      }
    }, [_c("img", {
      attrs: {
        src: "/img/social/attachment.svg",
        width: "30"
      }
    }), _vm._v("Attachment ")]) : _vm._e()]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);
  })], 2), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;