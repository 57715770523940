"use strict";

require("./404");
require("./addFav");
require("./agent");
require("./almond");
require("./auction");
require("./back-top");
require("./banner");
require("./battery");
require("./bids");
require("./blogs");
require("./bug");
require("./building");
require("./chart");
require("./clap");
require("./clipboard");
require("./complaint");
require("./component");
require("./credit");
require("./dash");
require("./dashboard");
require("./delete");
require("./DispatchedOrder");
require("./documentation");
require("./drag");
require("./edit");
require("./education");
require("./email");
require("./example");
require("./excel");
require("./exit-fullscreen");
require("./eye-off");
require("./eye-on");
require("./facebook-f-brands");
require("./form");
require("./fullscreen");
require("./globe");
require("./Group");
require("./guide-2");
require("./guide");
require("./hamburger");
require("./icon");
require("./inr");
require("./instagram-brands");
require("./instagram");
require("./international");
require("./language");
require("./like");
require("./link");
require("./linkedin");
require("./list");
require("./location");
require("./lock");
require("./message");
require("./money");
require("./Name");
require("./nested");
require("./OpenOrder");
require("./order");
require("./OrderCancelled");
require("./orderConfirmation");
require("./OutforDelivery");
require("./OutstandingPayment");
require("./PartialPayment");
require("./password");
require("./pdf");
require("./people");
require("./peoples");
require("./phone");
require("./product");
require("./qq");
require("./Referral");
require("./removeFav");
require("./reply");
require("./right-arrow");
require("./riyal");
require("./sack");
require("./scale");
require("./search");
require("./shopping");
require("./size");
require("./skill");
require("./star");
require("./subscription");
require("./support");
require("./tab");
require("./table");
require("./tax");
require("./theme");
require("./timer");
require("./tnc");
require("./TotalOrder");
require("./TotalSaleINR");
require("./TotalSaleKg");
require("./tree-table");
require("./tree");
require("./twitter-brands");
require("./twitter");
require("./upload");
require("./user");
require("./wechat");
require("./whatsapp");
require("./wish");
require("./wistlist");
require("./youtube");
require("./zip");