"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dropdown", {
    staticClass: "international",
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.handleSetLanguage
    }
  }, [_c("div", [_c("svg-icon", {
    staticClass: "international-icon",
    attrs: {
      name: "language"
    }
  })], 1), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      disabled: _vm.language === "en",
      command: "en"
    }
  }, [_vm._v(" English ")])], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;