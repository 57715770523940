"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.string.link.js");
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "dashboard-executive-container"
  }, [_vm.bannerList.length !== 0 ? _c("el-carousel", {
    attrs: {
      height: "100%"
    }
  }, _vm._l(_vm.bannerList, function (item) {
    return _c("el-carousel-item", {
      key: item.id
    }, [item.type !== "Link" ? _c("router-link", {
      staticClass: "carousel-inside",
      attrs: {
        to: item.type === "Category" ? "/buyer-category-products/list/".concat(item.categoryId) : item.type === "Product" ? "/buyer-product/edit/".concat(item.productId) : ""
      }
    }, [item.desktopImage ? _c("img", {
      attrs: {
        src: item.desktopImage,
        alt: "Mobile Image"
      }
    }) : _vm._e()]) : _c("a", {
      staticClass: "carousel-inside",
      attrs: {
        href: item.link,
        target: "_blank"
      }
    }, [item.desktopImage ? _c("img", {
      attrs: {
        src: item.desktopImage,
        alt: "Mobile Image"
      }
    }) : _vm._e()])], 1);
  }), 1) : _vm._e(), _c("el-row", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("strong", [_vm._v("Shop by Categories")])]), _c("div", {
    staticClass: "panel-group"
  }, _vm._l(_vm.list, function (element) {
    return _c("div", {
      key: element.id
    }, [_c("router-link", {
      attrs: {
        to: "/buyer-category-products/list/" + element.id
      }
    }, [_c("div", {
      staticClass: "home-categories-cards",
      style: {
        backgroundColor: element.bgcolor
      }
    }, [_c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "feature-section-v2-list flex-column text-center home-categories-section"
    }, [_c("picture", {
      staticClass: "cat-image"
    }, [_c("img", {
      staticClass: "img-fluid mx-auto rounded-circle",
      attrs: {
        src: element.image
      }
    })]), element.name ? _c("p", [_c("b", [_vm._v(_vm._s(element.name))])]) : _vm._e()])])])])], 1);
  }), 0)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;