"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("div", {
    staticClass: "app-container"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("User Info")])]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v(" Full Name:")]), _vm._v(" " + _vm._s(_vm.data.user.fullName) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v("Email Address:")]), _vm._v(" " + _vm._s(_vm.data.user.emailAddress) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v(" Mobile Number:")]), _vm._v(" " + _vm._s(_vm.data.contact.mobileNumber) + " ")])])], 1), _c("div", {
    staticClass: "app-container"
  }, [_c("el-card", {
    staticClass: "box-card",
    staticStyle: {
      float: "left",
      width: "50%",
      height: "50%"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Billing Address")])]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v(" Name:")]), _vm._v(" " + _vm._s(_vm.data.address.name) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v("Line 1 : ")]), _vm._v(" " + _vm._s(_vm.data.address.line1) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v("Line 2 : ")]), _vm._v(" " + _vm._s(_vm.data.address.line2) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v("Country : ")]), _vm._v(" " + _vm._s(_vm.data.address.country.name) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v("State : ")]), _vm._v(" " + _vm._s(_vm.data.address.state.name) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v("City : ")]), _vm._v(" " + _vm._s(_vm.data.address.city.name) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v("Pincode : ")]), _vm._v(" " + _vm._s(_vm.data.address.pincode.pincode) + " ")])]), _c("el-card", {
    staticClass: "box-card",
    staticStyle: {
      float: "right",
      width: "50%",
      height: "50%"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Shipping Address")])]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v(" Name : ")]), _vm._v(" " + _vm._s(_vm.data.shippingAddress.name) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v(" Line 1 : ")]), _vm._v(" " + _vm._s(_vm.data.shippingAddress.line1) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v(" Line 2 : ")]), _vm._v(" " + _vm._s(_vm.data.shippingAddress.line2) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v(" Country : ")]), _vm._v(" " + _vm._s(_vm.data.shippingAddress.country.name) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v(" State : ")]), _vm._v(" " + _vm._s(_vm.data.shippingAddress.state.name) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v(" City : ")]), _vm._v(" " + _vm._s(_vm.data.shippingAddress.city.name) + " ")]), _c("div", {
    staticClass: "text item"
  }, [_c("b", [_vm._v(" Pincode : ")]), _vm._v(" " + _vm._s(_vm.data.shippingAddress.pincode.pincode) + " ")])])], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;