var render, staticRenderFns
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Innovura2\\tbsellerpanel\\tradebridge-seller\\tradebridge-seller\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c755c40e')) {
      api.createRecord('c755c40e', component.options)
    } else {
      api.reload('c755c40e', component.options)
    }
    
  }
}
component.options.__file = "src/views/redirect/index.vue"
export default component.exports