"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'Referral': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_Referral_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="Referral" clip-path="url(#svgicon_Referral_a)"><path pid="1" data-name="Rectangle 850" _fill="none" d="M11.402 11.472h77.194v77.194H11.402z"/><path pid="2" data-name="Path 12393" d="M38.379 70.083a9.529 9.529 0 011.937 5.81 9.65 9.65 0 11-5.714-8.845l4.551-5.715a14.539 14.539 0 01-3.519-11.911l-6.553-2.2a9.665 9.665 0 111.549-5.229 4.767 4.767 0 01-.032.678l6.553 2.2a14.424 14.424 0 0110.395-7.488v-6.972a9.7 9.7 0 114.874 0v6.972a14.424 14.424 0 0110.394 7.489l6.553-2.2v-.678a9.674 9.674 0 111.549 5.262l-6.553 2.2a14.493 14.493 0 01-3.519 11.911l4.552 5.714a9.157 9.157 0 013.97-.872 9.684 9.684 0 11-9.684 9.684 9.529 9.529 0 011.937-5.81l-4.552-5.714a14.522 14.522 0 01-14.107 0z" _fill="#8d8d8d"/></g>'
  }
});