"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'instagram': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_instagram_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="3" clip-path="url(#svgicon_instagram_a)"><path pid="1" data-name="Subtraction 2" d="M50.91 90.777a40.607 40.607 0 01-15.8-78 40.607 40.607 0 0131.6 74.806 40.342 40.342 0 01-15.8 3.194zm-.711-61.007c-3.776 0-7.127.057-8.746.149-3.962.188-6.7 1.242-8.883 3.416s-3.226 4.9-3.425 8.884c-.2 3.515-.2 13.978 0 17.492.188 3.972 1.245 6.712 3.425 8.884s4.917 3.226 8.883 3.424c1.614.092 4.965.149 8.746.149s7.131-.057 8.746-.149c3.972-.188 6.712-1.245 8.884-3.424s3.225-4.909 3.424-8.884c.2-3.513.2-13.97 0-17.483-.187-3.971-1.243-6.711-3.424-8.883s-4.909-3.225-8.884-3.425c-1.622-.094-4.973-.151-8.749-.151zm3.755 38.6c-.8 0-1.563-.008-2.239-.015-.551-.006-1.072-.011-1.511-.011s-.941 0-1.481.01c-.664.006-1.412.014-2.189.014-2.838 0-6.841-.091-8.828-.875a7.164 7.164 0 01-4.03-4.03c-.949-2.394-.9-7.567-.862-10.989.006-.55.011-1.07.011-1.508s0-.939-.01-1.478c-.033-3.435-.083-8.626.861-11.02a7.166 7.166 0 014.03-4.03c1.982-.786 5.942-.877 8.748-.877.8 0 1.562.008 2.238.015.552.006 1.073.011 1.512.011s.939 0 1.478-.01c.66-.006 1.407-.013 2.184-.013 2.841 0 6.848.091 8.836.875a7.164 7.164 0 014.03 4.03c.949 2.394.9 7.565.862 10.986-.006.551-.011 1.072-.011 1.511s.005.96.011 1.511c.035 3.425.087 8.6-.862 10.986a7.161 7.161 0 01-4.03 4.03c-1.985.79-5.944.879-8.75.879zm-3.75-28.275a10.87 10.87 0 1010.87 10.87 10.883 10.883 0 00-10.872-10.867zm11.315-2.98a2.535 2.535 0 102.535 2.535 2.538 2.538 0 00-2.538-2.532zm-11.317 20.92a7.067 7.067 0 117.067-7.068 7.076 7.076 0 01-7.067 7.068z" _fill="#3c9900"/></g>'
  }
});