"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCategory = exports.getMustTry = exports.getDashboardBanners = exports.getDashboard1Banners = exports.getCategoryById = exports.getCategoriesTree = exports.getCategories = exports.getBuyerProductDetailsBanners = exports.getBuyerCategoryProductsBanners = exports.fileTransferHeaders = exports.deleteCategory = exports.defaultCategoryData = exports.createCategory = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultCategoryData = {
  id: 0,
  enabled: true,
  name: "",
  icon: "",
  image: "",
  description: "",
  priority: 0,
  featured: true,
  parent: null,
  bgcolor: null,
  amountStartingFrom: null
};
exports.defaultCategoryData = defaultCategoryData;
var getCategories = function getCategories(params) {
  return (0, _request.default)({
    url: "/categories",
    method: "get",
    params: params
  });
};
exports.getCategories = getCategories;
var getCategoriesTree = function getCategoriesTree() {
  return (0, _request.default)({
    url: "/categories/tree",
    method: "get"
  });
};
exports.getCategoriesTree = getCategoriesTree;
var getCategoryById = function getCategoryById(id) {
  return (0, _request.default)({
    url: "/categories/".concat(id),
    method: "get"
  });
};
exports.getCategoryById = getCategoryById;
var updateCategory = function updateCategory(id, data) {
  return (0, _request.default)({
    url: "/categories/".concat(id),
    method: "patch",
    data: data
  });
};
exports.updateCategory = updateCategory;
var deleteCategory = function deleteCategory(id) {
  return (0, _request.default)({
    url: "/categories/".concat(id),
    method: "delete"
  });
};
exports.deleteCategory = deleteCategory;
var createCategory = function createCategory(data) {
  return (0, _request.default)({
    url: "/categories",
    method: "post",
    data: data
  });
};
exports.createCategory = createCategory;
var getDashboardBanners = function getDashboardBanners() {
  return (0, _request.default)({
    url: "/banners/webList?type=dashboard",
    method: "get"
  });
};
exports.getDashboardBanners = getDashboardBanners;
var getDashboard1Banners = function getDashboard1Banners() {
  return (0, _request.default)({
    url: "/banners/webList?type=dashboard1",
    method: "get"
  });
};
exports.getDashboard1Banners = getDashboard1Banners;
var getMustTry = function getMustTry() {
  return (0, _request.default)({
    url: "/users/getMustTry",
    method: "get"
  });
};
exports.getMustTry = getMustTry;
var getBuyerCategoryProductsBanners = function getBuyerCategoryProductsBanners() {
  return (0, _request.default)({
    url: "/banners/webList?type=buyerCategoryProducts",
    method: "get"
  });
};
exports.getBuyerCategoryProductsBanners = getBuyerCategoryProductsBanners;
var getBuyerProductDetailsBanners = function getBuyerProductDetailsBanners(categoryId) {
  return (0, _request.default)({
    url: "/banners/webList?type=buyerProductDetails&categoryId=".concat(categoryId),
    method: "get"
  });
};
exports.getBuyerProductDetailsBanners = getBuyerProductDetailsBanners;