"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'OutforDelivery': {
    width: 250,
    height: 250,
    viewBox: '0 0 250 250',
    data: '<defs><clipPath id="svgicon_OutforDelivery_a"><path pid="0" d="M0 0h250v250H0z"/></clipPath></defs><g data-name="Out for Delivery" clip-path="url(#svgicon_OutforDelivery_a)"><path pid="1" data-name="truck (1)" d="M219.6 121.264c-.678-.139-1.318-.27-1.887-.415-6.451-1.641-9.4-2.54-11.573-6.652l-8.246-16.33c-2.65-5-9.494-9.076-15.255-9.076h-14.681V69.935a6.218 6.218 0 00-6.279-6.141l-102.255.1a5.871 5.871 0 00-5.91 6.092v5.574H27.32a9.6 9.6 0 100 19.192h26.194v9.2h-17.15a9.6 9.6 0 100 19.192h17.15v9.2h-8.105a9.6 9.6 0 100 19.192h8.106v9.62a6.218 6.218 0 006.278 6.141h10.636a23.593 23.593 0 0046.527 0h43.047c.224 0 .453-.015.681-.029a23.593 23.593 0 0046.53.029h15.278a10.8 10.8 0 0010.907-10.668v-18.1c.001-14.433-8.304-16.138-13.799-17.265zm-35.65 34.332a8.362 8.362 0 11-8.547 8.36 8.466 8.466 0 018.547-8.36zm-15.992-35.632V97.697h11.219c4.312 0 9.631 3.165 11.606 6.891l7.338 14.532q.233.441.485.843h-30.648zm-65.717 43.991a8.55 8.55 0 11-8.55-8.36 8.466 8.466 0 018.55 8.36z" _fill="#b5aeae"/></g>'
  }
});