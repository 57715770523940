"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-breadcrumb", {
    staticClass: "app-breadcrumb",
    attrs: {
      separator: "/"
    }
  }, [_vm.roles[0] === "Buyer" ? _c("div", {
    staticStyle: {
      "line-height": "normal"
    }
  }, [_c("span", [_c("b", [_vm._v("Buyer Dashboard")])]), _c("br"), _c("span", [_vm._v("Hello " + _vm._s(_vm.name))])]) : _c("div", {
    staticStyle: {
      "line-height": "normal"
    }
  }, [_c("span", [_c("b", [_vm._v("Vendor Dashboard")]), _c("br"), _c("span", [_vm._v("Hello " + _vm._s(_vm.name))])])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;