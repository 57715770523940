"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "block"
  }, [_c("el-timeline", _vm._l(_vm.timeline, function (item, index) {
    return _c("el-timeline-item", {
      key: index,
      attrs: {
        timestamp: item.timestamp,
        placement: "top"
      }
    }, [_c("el-card", [_c("h4", [_vm._v(_vm._s(item.title))]), _c("p", [_vm._v(_vm._s(item.content))])])], 1);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;