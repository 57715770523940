"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var buyerOrderRouter = {
  path: "/buyer-order",
  component: _index.default,
  redirect: "noredirect",
  name: "orderManagement.title",
  meta: {
    title: "orderManagement.title",
    icon: "order"
  },
  children: [{
    path: "/buyer-order/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-order/list.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "order",
      noCache: true
    }
  },
  // {
  //   path: "/buyer-previous-order/list",
  //   component: () => import("@/views/buyer-prevoius-orders/list.vue"),
  //   name: "Orders",
  //   meta: {
  //     title: "orderManagement.prevoius.title",
  //     icon: "order",
  //     noCache: true,
  //   },
  // },
  {
    path: "/buyer-order/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-order/manage.vue"));
      });
    },
    name: "orderManagement.orders.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.orders.edit",
      // roles: ['superadmin'],
      noCache: true,
      activeMenu: "/order/list",
      hidden: true
    }
  },
  // {
  //   path: '/order/:id(.*)',
  //   component: () => import('@/views/order/components/OrderInfoDetails.vue'),
  //   name: 'orderManagement.orders.info',
  //   props: {
  //     isEdit: true
  //   },
  //   meta: {
  //     title: 'orderManagement.orders.info',
  //     noCache: true,
  //     activeMenu: '/order/list',
  //     hidden: true
  //   }
  // },
  {
    path: "/buyer-orders-products/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-cart/list.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      // activeMenu: '/buyer-categories/list',
      noCache: true,
      hidden: true
    }
  }, {
    path: "/buyer-order-summary/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-cart/orderSummary.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      // activeMenu: '/buyer-categories/list',
      noCache: true,
      hidden: true
    }
  }, {
    path: "/payment-method",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-address/paymentMethods.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      // activeMenu: '/buyer-categories/list',
      noCache: true,
      hidden: true
    }
  }, {
    path: "/buyer-coupon-apply/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-coupon/list.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      // activeMenu: '/buyer-categories/list',
      noCache: true,
      hidden: true
    }
  }, {
    path: "/buyer-pan-details",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-cart/fillPanDetails.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      noCache: true,
      hidden: true
    }
  }, {
    path: "/buyer-address/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-address/list.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      // activeMenu: '/buyer-categories/list',
      noCache: true,
      hidden: true
    }
  }, {
    path: "/buyer-address/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-address/manage.vue"));
      });
    },
    name: "customerManagement.address.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "customerManagement.address.add",
      noCache: true,
      // activeMenu: '/buyer-categories/list',
      hidden: true
    }
  }, {
    path: "/buyer-address/edit/:id(\\d+)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-address/manage.vue"));
      });
    },
    name: "customerManagement.address.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "customerManagement.address.edit",
      noCache: true,
      // activeMenu: '/buyer-categories/list',
      hidden: true
    }
  }, {
    path: "/buyer-shipping-address/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-address/shippingAddress.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      // activeMenu: '/buyer-categories/list',
      noCache: true,
      hidden: true
    }
  }, {
    path: "/buyer-shipping-methods/list",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-address/shippingMethods.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      // activeMenu: '/buyer-categories/list',
      noCache: true,
      hidden: true
    }
  }, {
    path: "/buyer-order/add",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-order/manage.vue"));
      });
    },
    name: "orderManagement.orders.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "orderManagement.orders.add",
      roles: ["superadmin"],
      noCache: true,
      activeMenu: "/order/list",
      hidden: true
    }
  }, {
    path: "/transaction/list/",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transaction/list.vue"));
      });
    },
    name: "Transaction",
    meta: {
      title: "orderManagement.transaction.title",
      roles: ["superadmin"],
      noCache: true,
      icon: "inr",
      hidden: true
    }
  }, {
    path: "/transaction/list/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transaction/list.vue"));
      });
    },
    name: "Transaction",
    meta: {
      title: "orderManagement.transaction.title",
      roles: ["superadmin"],
      noCache: true,
      hidden: true
    }
  }, {
    path: "/transaction/add/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transaction/manage.vue"));
      });
    },
    name: "orderManagement.transaction.add",
    props: {
      isEdit: false
    },
    meta: {
      title: "orderManagement.transaction.add",
      noCache: true,
      activeMenu: "/transaction/list/:id(.*)",
      hidden: true
    }
  }, {
    path: "/transaction/edit/:id(.*)",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transaction/manage.vue"));
      });
    },
    name: "orderManagement.transaction.edit",
    props: {
      isEdit: true
    },
    meta: {
      title: "orderManagement.transaction.edit",
      noCache: true,
      activeMenu: "/transaction/list/:id(.*)",
      hidden: true
    }
  }, {
    path: "/thank-you",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-cart/thankYou.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      // activeMenu: '/buyer-categories/list',
      noCache: true,
      hidden: true
    }
  }, {
    path: "/thank-you-review",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/buyer-cart/thankYou2.vue"));
      });
    },
    name: "Orders",
    meta: {
      title: "orderManagement.orders.title",
      icon: "shopping",
      // activeMenu: '/buyer-categories/list',
      noCache: true,
      hidden: true
    }
  }]
};
var _default = buyerOrderRouter;
exports.default = _default;