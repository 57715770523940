"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateFavourites = exports.getFavouritesList = exports.getFavourites = exports.fileTransferHeaders = exports.deleteFavourite = exports.defaultBuyerFavouriteData = exports.createFavourite = exports.addFavourite = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultBuyerFavouriteData = {
  id: 0,
  userId: undefined,
  productId: undefined,
  user: {
    id: null
  },
  product: {
    id: null
  }
};
exports.defaultBuyerFavouriteData = defaultBuyerFavouriteData;
var getFavourites = function getFavourites(params) {
  return (0, _request.default)({
    url: '/favourites',
    method: 'get',
    params: params
  });
};
exports.getFavourites = getFavourites;
var updateFavourites = function updateFavourites(id, data) {
  return (0, _request.default)({
    url: "/favourites/".concat(id),
    method: 'patch',
    data: data
  });
};
// export const getFavouritesList = (id: number) =>
//   request({
//     url: `/favourites/${id}`,
//     method: 'get'
//   })
exports.updateFavourites = updateFavourites;
var getFavouritesList = function getFavouritesList() {
  return (0, _request.default)({
    url: '/favourites/list',
    method: 'post'
  });
};
exports.getFavouritesList = getFavouritesList;
var deleteFavourite = function deleteFavourite(id) {
  return (0, _request.default)({
    url: "/favourites/".concat(id),
    method: 'delete'
  });
};
exports.deleteFavourite = deleteFavourite;
var createFavourite = function createFavourite(data) {
  return (0, _request.default)({
    url: '/favourites/',
    method: 'post',
    data: data
  });
};
exports.createFavourite = createFavourite;
var addFavourite = function addFavourite(data) {
  return (0, _request.default)({
    url: '/favourites/like',
    method: 'post',
    data: data
  });
};
exports.addFavourite = addFavourite;