"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'credit': {
    width: 266.667,
    height: 266.667,
    viewBox: '0 0 200 200',
    data: '<path pid="0" d="M114.5 27c-1.1.5-13.9 12.8-28.5 27.4L59.5 81h62l13.3-13.3C142 60.4 148 54 148 53.5c0-1.8-24.4-25.4-27.3-26.5-3.3-1.2-3.5-1.2-6.2 0zM145 70.5L134.5 81h13.1l7.2-7.3 7.2-7.3-3.3-3.2-3.3-3.2L145 70.5zM161.3 80.2c-5.8 6-6.1 6.4-4.6 8.5.8 1.2 2.1 4.4 2.8 7l1.2 4.8 6.7-6.7c5.9-6 6.6-7.1 6.6-10.6 0-4-3-9.2-5.4-9.2-.6 0-3.9 2.8-7.3 6.2zM28.5 92.4c-1.3 1.3-2.6 3.9-3 5.7-.3 1.9-.5 18.9-.3 37.7l.3 34.4 2.8 2.4 2.8 2.4h113.8l2.8-2.4 2.8-2.4.3-34.4c.2-18.8 0-35.8-.3-37.7-.4-1.8-1.7-4.4-3-5.7l-2.4-2.4H30.9l-2.4 2.4zM70 122.5V136H39v-27h31v13.5zm-13 30v4.5H39v-9h18v4.5zm26 0v4.5H66v-9h17v4.5zm27 0v4.5H93v-9h17v4.5zm27 0v4.5h-18v-9h18v4.5z"/><path pid="1" d="M49 122.5v4.5h12v-9H49v4.5z"/>'
  }
});