"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.concat.js");
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "left-section"
  }, [_c("router-link", {
    attrs: {
      to: "/webcategories"
    }
  }, [_c("button", {
    staticClass: "back-button"
  }, [_c("svg", {
    staticClass: "feather feather-arrow-left-circle back-icon",
    attrs: {
      xmlns: "http://www.w3.org/2000/svg",
      width: "32",
      height: "32",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c("circle", {
    attrs: {
      cx: "12",
      cy: "12",
      r: "10"
    }
  }), _c("polyline", {
    attrs: {
      points: "12 8 8 12 12 16"
    }
  }), _c("line", {
    attrs: {
      x1: "16",
      y1: "12",
      x2: "8",
      y2: "12"
    }
  })])])]), _c("h1", [_vm._v(_vm._s(_vm.list.length > 0 ? _vm.list[0].category[0].name : "Categories"))])], 1), _c("div", {
    staticClass: "center-section"
  }, [_vm._m(0), _c("h1", {
    staticClass: "mobile"
  }, [_vm._v(" " + _vm._s(_vm.list.length > 0 ? _vm.list[0].category[0].name : "Categories") + " ")])]), _c("div", {
    staticClass: "right-section"
  }, [_c("router-link", {
    attrs: {
      to: "/login"
    }
  }, [_c("button", {
    staticClass: "login-button"
  }, [_vm._v("Login")])]), _c("router-link", {
    attrs: {
      to: "/create-account"
    }
  }, [_c("button", {
    staticClass: "signup-button"
  }, [_vm._v("Sign Up")])])], 1)]), _c("div", {
    staticClass: "search-bar"
  }, [_c("el-input", {
    staticClass: "input-with-select",
    attrs: {
      placeholder: "Search Products Here"
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getSearchResults(_vm.keyword);
      }
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "append",
      icon: "el-icon-search"
    },
    on: {
      click: function click($event) {
        return _vm.getSearchResults(_vm.keyword);
      }
    },
    slot: "append"
  })], 1)], 1), _c("div", {
    staticClass: "product-grid"
  }, _vm._l(_vm.list, function (product) {
    var _product$state;
    return _c("div", {
      key: product.id,
      staticClass: "product-item"
    }, [_c("div", {
      staticClass: "product-image"
    }, [_c("router-link", {
      attrs: {
        to: "/web-product/edit/" + product.id
      }
    }, [product.productsImage[0] ? _c("img", {
      staticClass: "image",
      attrs: {
        src: product.productsImage[0].image.concat("-lg")
      }
    }) : _vm._e()])], 1), _c("div", {
      staticClass: "product-details"
    }, [_c("router-link", {
      staticClass: "product-name",
      attrs: {
        to: "/web-product/edit/" + product.id
      }
    }, [_vm._v(" " + _vm._s(product.name) + " ")]), _c("div", {
      staticClass: "product-price"
    }, [_vm._v(" ₹ " + _vm._s(_vm._f("formatPrice")(product.minimumPrice)) + " ")]), _c("div", {
      staticClass: "product-seller"
    }, [_vm._v("Seller: " + _vm._s((_product$state = product.state) === null || _product$state === void 0 ? void 0 : _product$state.name))])], 1)]);
  }), 0), _c("footer-v-1"), _c("el-tooltip", {
    attrs: {
      content: "Scroll to top"
    }
  }, [_c("BackToTop")], 1)], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("a", {
    attrs: {
      href: "https://tradebridge.co.in/"
    }
  }, [_c("img", {
    staticClass: "logo pc",
    attrs: {
      src: require("../../assets/img/logo-03.png"),
      alt: "Logo"
    }
  }), _c("img", {
    staticClass: "logo mobile",
    attrs: {
      src: require("../../assets/img/logo-small.jpg"),
      alt: "Logo"
    }
  })]);
}];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;