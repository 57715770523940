"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'bids': {
    width: 100,
    height: 100,
    viewBox: '0 0 100 100',
    data: '<defs><clipPath id="svgicon_bids_a"><path pid="0" d="M0 0h100v100H0z"/></clipPath></defs><g data-name="Live Bids – 2" clip-path="url(#svgicon_bids_a)"><g _fill="#565656" transform="translate(19.638 18.635)" data-name="29-auction"><path pid="1" data-name="Path 1403" d="M0 57.549h36.273v5.182H0z"/><path pid="2" data-name="Path 1404" d="M31.092 52.325a2.591 2.591 0 00-2.591-2.591H7.773a2.591 2.591 0 00-2.591 2.591v2.633h25.91z"/><path pid="3" data-name="Path 1405" d="M35.254 31.142a18.076 18.076 0 00-3 4.323l21.321 21.326a2.591 2.591 0 003.664 0l3.664-3.664a2.591 2.591 0 000-3.664L39.577 28.142a17.659 17.659 0 00-4.323 3z"/><path pid="4" data-name="Path 1406" d="M22.43 18.316a22.467 22.467 0 01-6.7 4.294l13.393 13.392a22.835 22.835 0 014.3-6.694 22.486 22.486 0 016.7-4.295L26.729 11.624a22.853 22.853 0 01-4.299 6.692z"/><rect pid="5" data-name="Rectangle 833" width="10.364" height="25.91" rx="4" transform="rotate(-45 21.89 -27.83)"/><rect pid="6" data-name="Rectangle 834" width="10.364" height="25.91" rx="4" transform="rotate(-45 37.43 9.699)"/></g></g>'
  }
});