"use strict";

var _interopRequireDefault = require("C:/Innovura2/tbsellerpanel/tradebridge-seller/tradebridge-seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBrand = exports.getBrands = exports.getBrandById = exports.fileTransferHeaders = exports.deleteBrand = exports.defaultBrandData = exports.createBrand = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var fileTransferHeaders = {};
exports.fileTransferHeaders = fileTransferHeaders;
var defaultBrandData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  description: ''
};
exports.defaultBrandData = defaultBrandData;
var getBrands = function getBrands(params) {
  return (0, _request.default)({
    url: '/brands',
    method: 'get',
    params: params
  });
};
exports.getBrands = getBrands;
var getBrandById = function getBrandById(id) {
  return (0, _request.default)({
    url: "/brands/".concat(id),
    method: 'get'
  });
};
exports.getBrandById = getBrandById;
var updateBrand = function updateBrand(id, data) {
  return (0, _request.default)({
    url: "/brands/".concat(id),
    method: 'patch',
    data: data
  });
};
exports.updateBrand = updateBrand;
var deleteBrand = function deleteBrand(id) {
  return (0, _request.default)({
    url: "/brands/".concat(id),
    method: 'delete'
  });
};
exports.deleteBrand = deleteBrand;
var createBrand = function createBrand(data) {
  return (0, _request.default)({
    url: '/brands/',
    method: 'post',
    data: data
  });
};
exports.createBrand = createBrand;