"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-row", {
    staticClass: "flex-column",
    attrs: {
      gutter: 20,
      type: "flex",
      align: "center"
    }
  }, [_c("el-col", {
    attrs: {
      span: 18,
      xs: 24
    }
  }, [_c("p", [_c("small", [_c("b", [_vm._v("Ticket Id:#" + _vm._s(_vm.formData.id) + " ")])])]), _c("el-form-item", {
    attrs: {
      prop: "closureComments"
    }
  }, [_c("editor", {
    attrs: {
      "api-key": "b3w2lncq9mhqbft4zaemvx1andivk7bodsffkzneoaol8tpq",
      init: {
        height: 200,
        plugins: ["lists link image paste help wordcount table code"],
        toolbar: "undo redo | formatselect | bold italic | table | code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help"
      }
    },
    model: {
      value: _vm.formData.closureComments,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "closureComments", $$v);
      },
      expression: "formData.closureComments"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "closureAttachment"
    }
  }, [_c("file-upload", {
    attrs: {
      url: "/orders-tickets/upload",
      drag: true,
      "file-list": _vm.closureAttachment
    },
    on: {
      change: _vm.imageUploaded
    }
  })], 1)], 1), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary",
      size: "large",
      icon: "el-icon-position"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s("Reply") + " ")])], 1)], 1)], 1)], 1)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;