"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-card", {
    attrs: {
      type: "flex",
      justify: "space-between",
      align: "center"
    }
  }, [_c("p", [_c("strong", [_vm._v("Thank You for your order")])]), _vm.list ? [_c("p", [_vm._v("Order ID: " + _vm._s(_vm.list.orderReference))]), _c("p", [_vm._v("Amount Paid: "), _c("inr", {
    attrs: {
      number: _vm.list.amountFromCard
    }
  })], 1), _vm.list.amountFromCard !== _vm.list.grandTotal ? _c("p", [_vm._v(" Grand Total: "), _c("inr", {
    attrs: {
      number: _vm.list.grandTotal
    }
  })], 1) : _vm._e()] : _vm._e(), _c("img", {
    staticClass: "img-fluid",
    attrs: {
      src: require("@/assets/img/orderSuccess.png"),
      width: "400px"
    }
  }), _c("br"), _c("br"), _c("router-link", {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      to: "/dashboard"
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    }
  }, [_vm._v(" Go to Dashboard ")])], 1), _c("router-link", {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      to: "/buyer-order/list"
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "primary"
    }
  }, [_vm._v(" Go to Orders ")])], 1)], 2)], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;