import { render, staticRenderFns } from "./list.vue?vue&type=template&id=48e2e728&scoped=true&"
import script from "./list.vue?vue&type=script&lang=ts&"
export * from "./list.vue?vue&type=script&lang=ts&"
import style0 from "./list.vue?vue&type=style&index=0&id=48e2e728&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e2e728",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Innovura2\\tbsellerpanel\\tradebridge-seller\\tradebridge-seller\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48e2e728')) {
      api.createRecord('48e2e728', component.options)
    } else {
      api.reload('48e2e728', component.options)
    }
    module.hot.accept("./list.vue?vue&type=template&id=48e2e728&scoped=true&", function () {
      api.rerender('48e2e728', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/seller-my-products/list.vue"
export default component.exports